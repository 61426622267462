import { Divider, Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../../hooks/useLocalization";
import CheckboxField from "../../../common/CheckboxField";
import SwitchField from "../../../common/SwitchField";
import GroupSummaryPlace from "./GroupSummaryPlace";
import { selectTabularSettingsState, useAppDispatch } from "../../../../store/store.ts";
import { useSelector } from "react-redux";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";

export default function GeneralSettings() {
  const settingsAreaValues = useSelector(selectTabularSettingsState);

  const { tabular: locale } = useLocalization();
  const hideBlankRows = settingsAreaValues.hideBlankRows === true;
  const dispatch = useAppDispatch();

  return (
    <Stack pt={1} spacing={1.5}>
      <Stack spacing={1.5}>
        <Typography variant="subtitle1">General</Typography>
        <Stack>
          <SwitchField
            label="Show Grouped Columns"
            checked={settingsAreaValues.showGroupedColumns === true}
            onChange={(value) =>
              dispatch(fieldsStateActions.updateSettings({ settings: { showGroupedColumns: value } }))
            }
          />
          <SwitchField
            label="Hide Blank Rows"
            checked={hideBlankRows}
            onChange={(hideBlankRows) => {
              const resetHideZeroRows = hideBlankRows === false ? false : settingsAreaValues.hideZeroRows;
              dispatch(
                fieldsStateActions.updateSettings({
                  settings: {
                    hideBlankRows,
                    hideZeroRows: resetHideZeroRows,
                  },
                })
              );
            }}
            tooltipText={locale.hide_blank_rows_explanation}
            containerSx={{ py: 0.75 }}
          />
          {hideBlankRows && (
            <CheckboxField
              checked={settingsAreaValues.hideZeroRows === true}
              onChange={(value) => dispatch(fieldsStateActions.updateSettings({ settings: { hideZeroRows: value } }))}
              label="Treat Zeros as Empty"
              formControlLabelSx={{ ml: 1.25 }}
            />
          )}
        </Stack>
      </Stack>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography variant="subtitle1">Totals</Typography>
      <SwitchField
        label="Show Grand Total"
        checked={settingsAreaValues.showGrandTotal === true}
        onChange={(showGrandTotal) => dispatch(fieldsStateActions.updateSettings({ settings: { showGrandTotal } }))}
      />
      <GroupSummaryPlace
        value={settingsAreaValues.groupSummaryPlace}
        onChange={(groupSummaryPlace) =>
          dispatch(fieldsStateActions.updateSettings({ settings: { groupSummaryPlace } }))
        }
      />
    </Stack>
  );
}

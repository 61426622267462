import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ConditionConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { RunChartReportRequest } from "../../../../api/biApi.types";
import { selectCurrentReport, selectSessionId } from "../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../store/devToolsSlice";
import { ConditionField } from "../../Types";
import { selectConditions, selectValues } from "../../../../store/store";

export function useRequestConfigViewer() {
  const conditionsAreaValues = useSelector(selectConditions);
  const valuesAreaValues = useSelector(selectValues);

  const report = useSelector(selectCurrentReport);
  const sessionId = useSelector(selectSessionId);
  const isQueryLoggingActive = useSelector(selectLogQueries);

  const requestConfig = useMemo(() => {
    const request: RunChartReportRequest = {
      reportId: report?.reportId || "",
      sessionId,
      withDrilldown: true,
      conditions: getConditions(conditionsAreaValues, valuesAreaValues),
      useQueryLogging: isQueryLoggingActive === true,
    };
    return request;
  }, [report?.reportId, sessionId, conditionsAreaValues, valuesAreaValues, isQueryLoggingActive]);

  return { requestConfig };
}

function getConditions(allConditions: ConditionField[], values: ReportField[]) {
  const customConditions = values
    .filter((f) => f.config?.useCustomConditions && f.config?.customConditions)
    .map((f) => f.config?.customConditions)
    .flat()
    .filter((c): c is ConditionConfiguration => c !== undefined);

  const conditions = [...allConditions.map((c) => c.config), ...customConditions].filter((c) => c.parameter === true);

  return conditions;
}

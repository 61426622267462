import { Divider, Grid2 } from "@mui/material";
import StyleGroup from "../StyleGroup";
import SerieItem from "./SerieItem";
import { useSelector } from "react-redux";
import { selectChartSettingsState, selectValues } from "../../../../../store/store";

export default function SeriesStyles() {
  const settingsAreaValues = useSelector(selectChartSettingsState);
  const valuesAreaValues = useSelector(selectValues);

  return (
    <StyleGroup caption="Series">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        {settingsAreaValues.series?.map((serie, index) => {
          const lastSerie = settingsAreaValues.series?.length === index + 1;
          const serieItem = (
            <SerieItem key={serie.name} settings={settingsAreaValues} values={valuesAreaValues} serie={serie} />
          );
          if (!lastSerie) {
            return [serieItem, <Divider key={`Divider_${serie.name}`} sx={{ borderStyle: "dashed" }} />];
          }
          return serieItem;
        })}
      </Grid2>
    </StyleGroup>
  );
}

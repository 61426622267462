import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReportType } from "../../../shared/reporting/api/biClient.types";
import { FieldsState } from "./types";

const initialState: FieldsState = {
  reportType: null,
};

const fieldsStateSlice = createSlice({
  name: "fieldsState",
  initialState,
  reducers: {
    setReportType: (state, action: PayloadAction<ReportType>) => {
      state.reportType = action.payload;
    },
  },
});

export const { setReportType } = fieldsStateSlice.actions;

export default fieldsStateSlice.reducer;

import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { DimensionField, FieldConfiguration, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../../common/fields/format/MeasureFieldsContainer";
import TextFieldContainer from "./TextFieldsContainer";
import DimensionFormattingContainer from "../../../common/fields/format/DimensionFormattingContainer";
import { isDimensionBased, isMeasure } from "../../../utils/fieldsHelper";
import { canApplyFormat } from "../../../common/fields/format/dimensionFormattingHelper";
import { selectRows, selectColumns, selectValues, useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useSelector } from "react-redux";

export default function FormatContainer() {
  const rowsAreaValues = useSelector(selectRows);
  const columnsAreaValues = useSelector(selectColumns);
  const valuesAreaValues = useSelector(selectValues);
  const dispatch = useAppDispatch();

  const fields = useMemo(() => rowsAreaValues.concat(columnsAreaValues), [rowsAreaValues, columnsAreaValues]);
  const supportingFormattingDimensions = useMemo(
    () => valuesAreaValues.filter(isDimensionBased).filter(canApplyFormat),
    [valuesAreaValues]
  );
  const supportingFormattingMeasures = useMemo(() => valuesAreaValues.filter(isMeasure), [valuesAreaValues]);

  const handleUpdateConfig = useCallback(
    (field: DimensionField, changes: Partial<FieldConfiguration>) => {
      if (columnsAreaValues.indexOf(field) > -1) {
        dispatch(fieldsStateActions.updateColumnConfig({ field, changes }));
      } else if (rowsAreaValues.indexOf(field) > -1) {
        dispatch(fieldsStateActions.updateRowConfig({ field, changes }));
      }
    },
    [rowsAreaValues, columnsAreaValues, dispatch]
  );

  const handleDimensionChange = useCallback(
    (dimension: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = valuesAreaValues.find((f) => f.config.guid === dimension.config.guid);
      if (isDimensionBased(field)) {
        dispatch(fieldsStateActions.updateMeasureConfig({ field, changes }));
      }
    },
    [valuesAreaValues, dispatch]
  );

  const handleMeasureChange = useCallback(
    (measure: ReportField, changes: Partial<FieldConfiguration>) => {
      dispatch(fieldsStateActions.updateMeasureConfig({ field: measure, changes }));
    },
    [dispatch]
  );

  return (
    <ScrollableFlexContainer scrollContainerSx={{ px: "1.3rem" }}>
      <Stack gap={1}>
        <TextFieldContainer fields={fields} onUpdateConfig={handleUpdateConfig} />
        <DimensionFormattingContainer
          dimensions={supportingFormattingDimensions}
          onUpdateConfig={handleDimensionChange}
        />
        <MeasureFieldsContainer measures={supportingFormattingMeasures} onChange={handleMeasureChange} />
      </Stack>
    </ScrollableFlexContainer>
  );
}

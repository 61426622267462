import { combineReducers, configureStore } from "@reduxjs/toolkit";
import apiReportReducer from "./apiReportSlice";
import currentReportReducer from "./currentReportSlice";
import devPanelConfigurationReducer from "./devToolsSlice";
import drillDownConfigurationReducer from "./drilldownSlice";
import measureDatasetsReducer from "./measureDataSetsSlice";
import metaDataReducer from "./metaDataSlice";
import reportsReducer from "./reportsSlice";
import reportTemplatesReducer from "./reportTemplatesSlice";
import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "./RootState";
import fieldsStateReducer from "./fieldState/fieldsStateSlice";
import { tabularFieldsReducer } from "./fieldState/tabularFieldsSlice";
import { pivotFieldsReducer } from "./fieldState/pivotFieldsSlice";
import { chartFieldsReducer } from "./fieldState/chartStateSlice.ts";
import clientSettingsReducer from "./clientSettingsSlice";
import { ChartFieldState, PivotFieldState, TabularFieldState } from "./fieldState/types.ts";
import { conditionsReducer } from "./fieldState/conditionsStateSlice.ts";
import { ReportType } from "../../shared/reporting/api/biClient.types.ts";

const combinedReducer = combineReducers({
  fieldsStateReducer,
  tabularFieldsReducer,
  pivotFieldsReducer,
  chartFieldsReducer,
});

export const selectAllFieldsState = (state: RootState) => {
  return state.fieldsState;
};

export const selectTabularFieldsState = (state: RootState): TabularFieldState => {
  return {
    ...state.fieldsState.tabularFieldsReducer,
  };
};

export const selectTabularSettingsState = (state: RootState) => {
  return state.fieldsState.tabularFieldsReducer.settings;
};

export const selectPivotSettingsState = (state: RootState) => {
  return state.fieldsState.pivotFieldsReducer.settings;
};

export const selectChartSettingsState = (state: RootState) => {
  return state.fieldsState.chartFieldsReducer.settings;
};

export const selectPivotFieldsState = (state: RootState): PivotFieldState => {
  return {
    ...state.fieldsState.pivotFieldsReducer,
  };
};

export const selectChartFieldsState = (state: RootState): ChartFieldState => {
  return {
    ...state.fieldsState.chartFieldsReducer,
  };
};

export const selectReportType = (state: RootState) => {
  return state.fieldsState.fieldsStateReducer.reportType;
};

export const selectTabularFields = (state: RootState) => {
  if (state.fieldsState.fieldsStateReducer.reportType !== ReportType.Tabular) {
    throw new Error("Not supported");
  }
  return state.fieldsState.tabularFieldsReducer.fields;
};

export const selectTabularGrouping = (state: RootState) => {
  if (state.fieldsState.fieldsStateReducer.reportType !== ReportType.Tabular) {
    throw new Error("Not supported");
  }
  return state.fieldsState.tabularFieldsReducer.grouping;
};

export const selectSorts = (state: RootState) => {
  if (state.fieldsState.fieldsStateReducer.reportType === ReportType.Pivot) {
    return state.fieldsState.pivotFieldsReducer.sorts;
  }

  if (state.fieldsState.fieldsStateReducer.reportType === ReportType.Tabular) {
    return state.fieldsState.tabularFieldsReducer.sorts;
  }

  return state.fieldsState.chartFieldsReducer.sorts;
};

export const selectConditions = (state: RootState) => {
  if (state.fieldsState.fieldsStateReducer.reportType === ReportType.Pivot) {
    return state.fieldsState.pivotFieldsReducer.conditions;
  }

  if (state.fieldsState.fieldsStateReducer.reportType === ReportType.Tabular) {
    return state.fieldsState.tabularFieldsReducer.conditions;
  }

  return state.fieldsState.chartFieldsReducer.conditions;
};

export const selectValues = (state: RootState) => {
  if (state.fieldsState.fieldsStateReducer.reportType === ReportType.Tabular) {
    throw new Error("Not supported");
  }

  if (state.fieldsState.fieldsStateReducer.reportType === ReportType.Pivot) {
    return state.fieldsState.pivotFieldsReducer.values;
  }

  return state.fieldsState.chartFieldsReducer.values;
};

export const selectRows = (state: RootState) => {
  if (state.fieldsState.fieldsStateReducer.reportType !== ReportType.Pivot) {
    throw new Error("Not supported");
  }
  return state.fieldsState.pivotFieldsReducer.rows;
};

export const selectColumns = (state: RootState) => {
  if (state.fieldsState.fieldsStateReducer.reportType !== ReportType.Pivot) {
    throw new Error("Not supported");
  }
  return state.fieldsState.pivotFieldsReducer.columns;
};

export const selectArguments = (state: RootState) => {
  if (
    state.fieldsState.fieldsStateReducer.reportType === ReportType.Tabular ||
    state.fieldsState.fieldsStateReducer.reportType === ReportType.Pivot
  ) {
    throw new Error("Not supported");
  }
  return state.fieldsState.chartFieldsReducer.arguments;
};

export const store = configureStore({
  reducer: {
    fieldsState: combinedReducer,
    currentReport: currentReportReducer,
    metaData: metaDataReducer,
    measureDatasets: measureDatasetsReducer,
    drillDownConfiguration: drillDownConfigurationReducer,
    devTools: devPanelConfigurationReducer,
    reportTemplates: reportTemplatesReducer,
    reports: reportsReducer,
    apiReport: apiReportReducer,
    clientSettings: clientSettingsReducer,
    conditions: conditionsReducer,
  },
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore = useStore.withTypes<AppStore>();

import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { ReportType } from "../../../../shared/reporting/api/biClient.types";
import { useEnhancedBiApiClientProvider } from "../../../contexts/ApiClientProviderContext";
import useDefineReportData from "../../../hooks/useDefineReportData";
import { useLocalization } from "../../../hooks/useLocalization";
import { selectCurrentReport } from "../../../store/currentReportSlice";
import ApplyingDialog from "../../common/dialogs/ApplyingDialog";
import DrillDownEntry from "../common/drillDown/DrillDownEntry";
import ReportNotFoundOrAccessForbidden from "../ReportNotFoundOrAccessForbidden";
import EditReportPage from "./EditReportPage";

const EditRoot = () => {
  const { id } = useParams();
  const { metadata: locale, report: reportLocale } = useLocalization();
  const report = useSelector(selectCurrentReport);
  const { getReportsUrl } = useEnhancedBiApiClientProvider();

  const { showReportNotFound, showSwitchingReport } = useDefineReportData();

  if (id === undefined) {
    return <Navigate to={""} />;
  }

  if (showReportNotFound === true || (report && report.reportType !== ReportType.Tabular)) {
    return <ReportNotFoundOrAccessForbidden getReportsUrl={getReportsUrl} />;
  }

  if (report === undefined) {
    return <InlineLoader text={locale.loading_label} />;
  }

  return (
    <Stack sx={{ bgcolor: "white", height: "100%", width: "100%" }}>
      <EditReportPage report={report} />
      <DrillDownEntry />
      {showSwitchingReport && <ApplyingDialog open text={reportLocale.loading_report} />}
    </Stack>
  );
};

export default EditRoot;

import { Grid2 } from "@mui/material";
import { isLinearSettings } from "../../contexts/FieldsStateContext.types";
import StyleGroup from "../StyleGroup";
import ShowLabelItem from "./ShowLabelItem";
import Title from "./Title";
import { useSelector } from "react-redux";
import { selectChartSettingsState } from "../../../../../store/store";

export default function ArgumentAxisStyles() {
  const settingsAreaValues = useSelector(selectChartSettingsState);

  const supported = isLinearSettings(settingsAreaValues);

  if (!supported) {
    return <></>;
  }

  return (
    <StyleGroup caption="Argument Axis">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        <ShowLabelItem settings={settingsAreaValues} />
        <Title settings={settingsAreaValues} />
      </Grid2>
    </StyleGroup>
  );
}

import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ChartStyleSettings, SeriesSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { isShowSerieLabel } from "./ShowLabelItem";
import { useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

interface Props {
  settings: ChartStyleSettings;
  serie: SeriesSettings;
}

export default function ShowLabelConnectorItem({ settings, serie }: Props) {
  const showConnector = React.useMemo(() => isShowSerieLabelConnector(serie.name, settings), [settings, serie]);
  const disabled = React.useMemo(() => !isShowSerieLabel(serie.name, settings), [serie, settings]);
  const dispatch = useAppDispatch();

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: `${disabled ? "text.disabled" : "text.secondary"}` }}>Show Label Connector</Typography>
      <HorizontalFill />
      <Switch
        checked={showConnector}
        disabled={disabled}
        onChange={(_, value) =>
          dispatch(fieldsStateActions.updateSeriesSettings({ name: serie.name, changes: { showConnector: value } }))
        }
      />
    </Stack>
  );
}

export function isShowSerieLabelConnector(name: string | undefined, settings: ChartStyleSettings) {
  return settings.series?.find((s) => s.name === name)?.showConnector !== false;
}

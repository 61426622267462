import { IconButton, Stack, SvgIcon, SvgIconProps, Tooltip, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  BasicLineSeriesSettings,
  ChartStyleSettings,
  LineType,
  SeriesSettings,
} from "../../../../../../shared/reporting/api/biClient.types";
import { getDefaultLineType, isLineBasedSerie } from "../../contexts/FieldsStateContext.types";
import { useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

interface Props {
  settings: ChartStyleSettings;
  serie: SeriesSettings;
}

export default function SerieLineTypeItem({ settings, serie }: Props) {
  const supported = isLineBasedSerie(settings, serie);
  const lineType = getLineType(serie);
  const dispatch = useAppDispatch();

  const handleLineTypeChanged = React.useCallback(
    (value: LineType) =>
      dispatch(fieldsStateActions.updateSeriesSettings({ name: serie.name, changes: { lineType: value } })),
    [dispatch, serie]
  );

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Line Type</Typography>
      <HorizontalFill />
      <Line selectedType={lineType} onSelected={handleLineTypeChanged} />
      <Spline selectedType={lineType} onSelected={handleLineTypeChanged} />
      <Stepline selectedType={lineType} onSelected={handleLineTypeChanged} />
    </Stack>
  );
}

export function getLineType(serie: SeriesSettings | undefined) {
  const linearSerie = serie as BasicLineSeriesSettings;
  return linearSerie?.lineType || getDefaultLineType();
}

interface SerieProps {
  selectedType: LineType;
  onSelected: (type: LineType) => void;
}

function Line({ selectedType, onSelected }: SerieProps) {
  return (
    <Tooltip title="Line">
      <IconButton onClick={() => onSelected("Line")}>
        <LineIcon
          sx={(theme) => ({
            fill: "none",
            stroke: selectedType === "Line" ? theme.palette.primary.main : theme.palette.secondary.light,
          })}
        />
      </IconButton>
    </Tooltip>
  );
}

function Spline({ selectedType, onSelected }: SerieProps) {
  return (
    <Tooltip title="Spline">
      <IconButton onClick={() => onSelected("Spline")}>
        <SplineIcon
          sx={(theme) => ({
            fill: "none",
            stroke: selectedType === "Spline" ? theme.palette.primary.main : theme.palette.secondary.light,
          })}
        />
      </IconButton>
    </Tooltip>
  );
}

function Stepline({ selectedType, onSelected }: SerieProps) {
  return (
    <Tooltip title="Stepline">
      <IconButton onClick={() => onSelected("Stepline")}>
        <SteplineIcon
          sx={(theme) => ({
            fill: "none",
            stroke: selectedType === "Stepline" ? theme.palette.primary.main : theme.palette.secondary.light,
          })}
        />
      </IconButton>
    </Tooltip>
  );
}

function LineIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M2.5 15.0007L6.66667 9.58398L12.0833 14.584L17.5 5.83398" strokeWidth="2" />
    </SvgIcon>
  );
}

function SplineIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        d="M2.5 14.1667C2.5 14.1667 4.01912 9.08599 6.66667 8.75C9.52254 8.38757 9.21011 13.5711 12.0833 13.75C16.0944 13.9997 17.5 5 17.5 5"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

function SteplineIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M2.5 15.834V10.834H10V5.83398H17.5" strokeWidth="2" />
    </SvgIcon>
  );
}

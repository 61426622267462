import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  AreaItemType,
  DimensionDescriptor,
  DimensionField,
  FieldConfiguration,
  ItemDataType,
  MeasureDescriptor,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import DropFieldContainer from "../../../common/fields/DropFieldContainer";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../../common/fields/types/dropField.types";
import { createColumnField } from "../../../common/utilities/createFields";
import { extractMeta, isValueField } from "../../../common/utilities/dropFieldContainerHelper";
import { ShowFieldOptionsSettings } from "../../../Types";
import { isAggregation } from "../../../utils/fieldsHelper";
import { formatDimensionFieldCaption } from "../../../utils/formatDimensionFieldCaptions";
import ColumnFieldOptionPopup from "./ColumnFieldOptionPopup";
import { selectColumns, selectRows, useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

import { useSelector } from "react-redux";

export const MaxColsNumber = 5;

export const ColumnsDropFieldsContainer = () => {
  const columnsAreaValues = useSelector(selectColumns);
  const rowsAreaValues = useSelector(selectRows);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [field, setField] = useState<DimensionField>();

  const columnsRef = useRef<ReportField[]>([]);
  columnsRef.current = columnsAreaValues;

  const rowsRef = useRef<ReportField[]>([]);
  rowsRef.current = rowsAreaValues;

  useEffect(() => {
    const column = columnsAreaValues.find((v) => v.meta.name === field?.meta.name);
    setField(column);
  }, [columnsAreaValues, field]);

  const showOptions = useCallback((settings: ShowFieldOptionsSettings<DimensionField>) => {
    setField(settings.field);
    setAnchorEl(settings.ref);
  }, []);
  const isDimensionAlreadyAdded = useCallback((meta: DimensionDescriptor | MeasureDescriptor) => {
    return (
      rowsRef.current.some((v) => v.meta.name === meta.name) ||
      columnsRef.current.some((v) => v.meta.name === meta.name)
    );
  }, []);

  const canDropItem = useCallback(
    (item: FieldWrapper<ReportField> | DraggableMetaType) => {
      const meta = extractMeta(item);
      if (!meta) return false;
      if (columnsRef.current.length >= MaxColsNumber) {
        return false;
      }
      // TODO looks like a wrong enum comparison?
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (item.type === AreaItemType.ROWS && !columnsRef.current.some((v) => v.meta.name === meta.name)) {
        return true;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (item.type === AreaItemType.VALUES) {
        const isTheFieldIsAggregation = isValueField(item) && isAggregation(item.field);
        if (isTheFieldIsAggregation && !isDimensionAlreadyAdded(meta)) {
          return true;
        }
        return false;
      }
      if (meta.type === ItemDataType.Date) {
        const anyDateFieldAdded =
          !!columnsRef.current?.find((f) => f.meta.type === ItemDataType.Date) ||
          !!rowsRef.current?.find((f) => f.meta.type === ItemDataType.Date);

        return anyDateFieldAdded === false;
      }

      return !isDimensionAlreadyAdded(meta);
    },
    [isDimensionAlreadyAdded]
  );

  const updateItemConfig = useCallback(
    (field: DimensionField, changes: Partial<FieldConfiguration>) => {
      dispatch(fieldsStateActions.updateColumnConfig({ field, changes }));
    },
    [dispatch]
  );

  const saveChanges = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      if (field === undefined) return;
      updateItemConfig(field, changes);
    },
    [updateItemConfig, field]
  );

  const handleSaveChanges = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      saveChanges(changes);
      if (causeClosing) {
        setAnchorEl(null);
      }
    },
    [saveChanges]
  );

  const handleColumnFieldCustomLabelChange = (name: string | undefined) => {
    if (field) {
      updateItemConfig(field, { customLabel: name });
    }
  };

  const addItem = useCallback(
    (item: DimensionField, index: number) => {
      dispatch(fieldsStateActions.addColumn({ field: item, index }));
    },
    [dispatch]
  );

  const removeItem = useCallback(
    (item: DimensionField) => {
      dispatch(fieldsStateActions.removeColumn(item));
    },
    [dispatch]
  );

  const moveItem = useCallback(
    (item: DimensionField, newIndex: number) => {
      dispatch(fieldsStateActions.moveColumn({ field: item, newIndex }));
    },
    [dispatch]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Columns
      </Typography>
      <DropFieldContainer
        areaFieldType={AreaItemType.COLUMNS}
        acceptedDropTypes={[AreaItemType.DIMENSION, AreaItemType.ROWS, AreaItemType.CONDITIONS, AreaItemType.VALUES]}
        fields={columnsAreaValues}
        getKeyValue={getKeyValue}
        canDropItem={canDropItem}
        formatCaption={formatDimensionFieldCaption}
        onAddItem={addItem}
        onRemoveItem={removeItem}
        onMoveItem={moveItem}
        showOptions={showOptions}
        createListItem={createColumnField}
      />
      {anchorEl && field && (
        <ColumnFieldOptionPopup
          anchorEl={anchorEl}
          field={field}
          onUpdateConfiguration={handleSaveChanges}
          cancel={() => setAnchorEl(null)}
          onRemove={() => {
            setAnchorEl(null);
            removeItem(field);
          }}
          onUpdateCustomLabel={handleColumnFieldCustomLabelChange}
        />
      )}
    </Box>
  );
};

export default ColumnsDropFieldsContainer;

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  return extractMeta(field)?.name ?? "";
}

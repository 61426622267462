import React from "react";
import { useSelector } from "react-redux";
import { BuildTabularReportRequest, ColumnSorting } from "../../../../api/biApi.types";
import { selectCacheSessionId, selectCurrentReport, selectSessionId } from "../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../store/devToolsSlice";
import { isDimensionBased } from "../../utils/fieldsHelper";
import { createTabularDataRequest } from "../utilities/createTabularDataRequest";
import { getSorting } from "../utilities/gridStateHelper";
import {
  selectConditions,
  selectSorts,
  selectTabularFields,
  selectTabularGrouping,
  selectTabularSettingsState,
} from "../../../../store/store.ts";

const MaximumExportRecordsCount = 10000;

export default function useRequestConfigBuilder() {
  const groupAreaValues = useSelector(selectTabularGrouping);
  const fieldsAreaValues = useSelector(selectTabularFields);
  const sortingAreaValues = useSelector(selectSorts);
  const conditionsAreaValues = useSelector(selectConditions);
  const settingsAreaValues = useSelector(selectTabularSettingsState);

  const report = useSelector(selectCurrentReport);
  const sessionId = useSelector(selectSessionId);
  const cacheSessionId = useSelector(selectCacheSessionId);
  const isQueryLoggingActive = useSelector(selectLogQueries);

  const sorting = React.useMemo(
    () => getSorting(sortingAreaValues, fieldsAreaValues),
    [sortingAreaValues, fieldsAreaValues]
  );

  const requestConfig = React.useMemo(() => {
    const sorts = sorting
      .map((s) => {
        const field = fieldsAreaValues.find((v) => v.config.guid === s.columnName);
        if (!isDimensionBased(field)) return undefined;
        return { dimensionName: field.meta.name, sortAsc: s.direction === "asc" } as ColumnSorting;
      })
      .filter((cs): cs is ColumnSorting => !!cs);
    const request: BuildTabularReportRequest = {
      ...createTabularDataRequest(conditionsAreaValues, fieldsAreaValues, sorts, groupAreaValues, settingsAreaValues),
      sessionId,
      cacheSessionId,
      withDrilldown: true,
      reportId: report?.reportId ?? "",
      calcTotalCount: false,
      calcGroupTotals: true,
      take: MaximumExportRecordsCount,
      skip: 0,
      useQueryLogging: isQueryLoggingActive === true,
    };
    return request;
  }, [
    sorting,
    conditionsAreaValues,
    fieldsAreaValues,
    groupAreaValues,
    settingsAreaValues,
    sessionId,
    cacheSessionId,
    report?.reportId,
    isQueryLoggingActive,
  ]);

  return { requestConfig };
}

import { useCallback, useMemo } from "react";
import PreviewActions from "../../common/PreviewActions";
import { isValueFieldHasParameters } from "../../common/utilities/valueFieldUtils";
import ViewerParameters from "../../common/ViewerParameters";
import useGridStateViewer from "../../tabular/hooks/useGridStateViewer";
import PreviewComponent from "../../tabular/preview/PreviewComponent";
import { isMeasure } from "../../utils/fieldsHelper";
import ViewContainer from "./ViewContainer";
import { useSelector } from "react-redux";
import { selectConditions, selectTabularFields, useAppDispatch } from "../../../../store/store";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";

const ReportViewer = () => {
  const state = useGridStateViewer();
  const fieldsAreaValues = useSelector(selectTabularFields);
  const conditionsAreaValues = useSelector(selectConditions);
  const dispatch = useAppDispatch();

  const fields = useMemo(() => conditionsAreaValues.filter((v) => v.config.parameter === true), [conditionsAreaValues]);

  const measuresToShow = useMemo(() => {
    return fieldsAreaValues.filter(isMeasure).filter(isValueFieldHasParameters);
  }, [fieldsAreaValues]);

  const updateItemConfig = useCallback(
    (field: ReportField, changes: Partial<FieldConfiguration>) => {
      dispatch(fieldsStateActions.updateFieldConfig({ field, changes }));
    },
    [dispatch]
  );

  const showParameters = useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  return (
    <ViewContainer
      parameters={
        showParameters ? (
          <ViewerParameters measuresWithConditions={measuresToShow} updateMeasure={updateItemConfig} />
        ) : (
          <></>
        )
      }
      preview={<PreviewComponent state={state} />}
      actionPanel={<PreviewActions />}
      isLoading={state.loading}
    />
  );
};

export default ReportViewer;

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Box, Button, Tooltip, tooltipClasses } from "@mui/material";
import { useState } from "react";
import ExportMenu from "../../ExportMenu";
import { useExportStateContext } from "../contexts/ExportStateContext";

export const ExportAction = () => {
  const exportState = useExportStateContext();

  const [openExportMenu, setOpenExportMenu] = useState(false);
  const [btnRef, setBtnRef] = useState<HTMLElement | null>(null);

  return (
    <>
      <Tooltip
        title={exportState.warning || ""}
        arrow
        slotProps={{
          popper: {
            sx: (theme) => ({
              [`& .${tooltipClasses.arrow}`]: {
                color: theme.palette.common.black,
              },
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.black,
              },
            }),
          },
        }}
      >
        <Box>
          <Button
            ref={(r) => setBtnRef(r)}
            variant="outlined"
            color="secondary"
            startIcon={<ExitToAppIcon />}
            loading={exportState.exporting}
            onClick={() => setOpenExportMenu(true)}
            disabled={!exportState.exportAllowed}
            sx={{ "& .MuiButton-label": { whiteSpace: "nowrap" } }}
          >
            Export to
          </Button>
        </Box>
      </Tooltip>

      <ExportMenu anchorEl={btnRef} open={openExportMenu} onClose={() => setOpenExportMenu(false)}>
        {exportState.actions.getRenderMenu?.call(null, () => setOpenExportMenu(false))}
      </ExportMenu>
    </>
  );
};

export default ExportAction;

import { useAppDispatch } from "../store/store";
import { useSelector } from "react-redux";
import { PropsWithChildren, useEffect } from "react";
import { selectMetaDataLoadingFailed, selectMetaDataLoaded } from "../store/metaDataSlice";
import { loadMetaData } from "../store/thunks/metaDataThunks";
import InlineLoader from "../../shared/components/inlineLoader/InlineLoader";
import { useLocalization } from "../hooks/useLocalization";
import { selectClientSettingsLoaded, selectClientSettingsLoadingFailed } from "../store/clientSettingsSlice";
import { loadClientSettings } from "../store/thunks/clientSettingsThunks";
import ImportantDataLoadingFailed from "./common/ImportantDataLoadingFailed";

interface Props {
  clientCode: string | undefined;
}
export const AppInitializer = ({ children, clientCode }: PropsWithChildren<Props>) => {
  const { metadata: metadataLocale, common: commonLocale } = useLocalization();
  const metaDataLoaded = useSelector(selectMetaDataLoaded);
  const metaDataLoadingFailed = useSelector(selectMetaDataLoadingFailed);
  const dispatch = useAppDispatch();
  const settingsLoadingFailed = useSelector(selectClientSettingsLoadingFailed);
  const settingsLoaded = useSelector(selectClientSettingsLoaded);

  useEffect(() => {
    if (!clientCode) {
      return;
    }
    dispatch(loadClientSettings());
  }, [clientCode, dispatch]);

  useEffect(() => {
    if (!metaDataLoaded && clientCode !== undefined) {
      dispatch(loadMetaData());
    }
  }, [metaDataLoaded, clientCode, dispatch]);

  if (settingsLoadingFailed || metaDataLoadingFailed) {
    return (
      <ImportantDataLoadingFailed
        title={commonLocale.report_data_loading_failed_title}
        disclaimer={commonLocale.report_data_loading_failed_text}
      />
    );
  }

  if (!metaDataLoaded || !settingsLoaded) {
    return <InlineLoader text={metadataLocale.loading_label} />;
  }

  return children;
};

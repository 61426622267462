import TabularContainer from "./TabularContainer";
import TabularViewer from "./TabularViewer";

export default function TabularViewerContainer() {
  return (
    <TabularContainer>
      <TabularViewer />
    </TabularContainer>
  );
}

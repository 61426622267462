import { Grid2 } from "@mui/material";
import { useMemo } from "react";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { selectRows } from "../../../../../store/store";
import { useSelector } from "react-redux";
import Settings from "./Settings";
import TextField from "./TextField";

export default function SettingsContainer() {
  const rowsAreaValues = useSelector(selectRows);

  const fieldDescriptors = useMemo(() => getFieldDescriptors(rowsAreaValues), [rowsAreaValues]);

  return (
    <ScrollableFlexContainer>
      <Grid2 container sx={{ gap: ".5rem", px: "1.3rem", width: "100%" }}>
        <Settings />
        <Grid2 container sx={{ gap: ".5rem", flex: 1, width: "100%" }}>
          {fieldDescriptors.map((descriptor) => (
            <TextField key={descriptor.field.meta.name} field={descriptor.field} />
          ))}
        </Grid2>
      </Grid2>
    </ScrollableFlexContainer>
  );
}

function getFieldDescriptors(values: ReportField[]) {
  const fieldDescriptors = values
    .map((field, index) => {
      const isLeaf = index === values.length - 1;
      return { field, isLeaf };
    })
    .filter((field) => !field.isLeaf);
  return fieldDescriptors;
}

import { useLocalization } from "../../../../../hooks/useLocalization";
import { getLinearSettings, isLinearSettings } from "../../contexts/FieldsStateContext.types";
import SwitchField from "../../../../common/SwitchField";
import { ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";

interface Props {
  settings: ChartStyleSettings;
}

export default function RotateItem({ settings }: Props) {
  const { chart: locale } = useLocalization();
  const dispatch = useAppDispatch();
  const supported = isLinearSettings(settings);
  const rotated = isRotated(settings);

  if (!supported) {
    return <></>;
  }

  return (
    <SwitchField
      label="Rotated"
      checked={rotated}
      onChange={(newValue) => dispatch(fieldsStateActions.updateSettings({ settings: { rotated: newValue } }))}
      tooltipText={locale.rotated_property_explanation}
      typographyProps={{ sx: { color: "text.secondary" } }}
    />
  );
}

export function isRotated(settings: ChartStyleSettings) {
  return getLinearSettings(settings)?.rotated === true;
}

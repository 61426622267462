import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
  DimensionDictionary,
  ItemDataType,
  MeasureDescriptor,
  MeasureUsage,
  MetaItemType,
  ReportType,
} from "../../shared/reporting/api/biClient.types";
import cloneDeep from "../../shared/utilities/cloneDeep";
import { AccountType, ChartOfAccounts, ChartOfAccountsItem, MeasuresStructure } from "../api/biApi.types";
import { updateDataSet } from "../api/dataSetApiContext";
import { DataSetMetaDataState, MetaDataState } from "./MetaDataState";
import { RootState } from "./RootState";

const dataSetMetaDataEmpty: DataSetMetaDataState = {
  dataSetId: "undefined",
  dimensionsStructure: {
    dimensions: [],
    groups: [],
  },
  measuresStructure: {
    names: [],
    groups: [],
  },
  dimensions: [],
  measures: [],
  allMeasures: [],
  functions: [],
  measureGroups: [],
};

const initialState: MetaDataState = {
  dataSets: [],
  loaded: false,
  dataSetId: undefined,
};

const addToGroup = (measuresStructure: MeasuresStructure, measure: MeasureDescriptor) => {
  const group = measuresStructure.groups.find((g) => g.caption === measure.group);
  if (group !== undefined) {
    group.names.push(measure.name);
  }
};

const removeFromGroup = (measuresStructure: MeasuresStructure, measure: MeasureDescriptor, groupName: string) => {
  const group = measuresStructure.groups.find((g) => g.caption === groupName);
  if (group !== undefined) {
    const index = group.names.indexOf(measure.name);
    if (index > -1) {
      group.names.splice(index, 1);
    }
  }
};

export const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<MetaDataState>>) => {
      Object.assign(state, action.payload);
      filterMeasures(state);
    },
    addMeasure: (state, action: PayloadAction<MeasureDescriptor>) => {
      const dataSet = state.dataSets.find((d) => d.dataSetId === state.dataSetId);
      if (dataSet === undefined) {
        return;
      }

      const measure = action.payload;
      measure.itemType = MetaItemType.MEASURE;
      dataSet.allMeasures.push(measure);
      dataSet.measures.push(measure);
      addToGroup(dataSet.measuresStructure, measure);
    },
    updateMeasure: (state, action: PayloadAction<MeasureDescriptor>) => {
      const dataSet = state.dataSets.find((d) => d.dataSetId === state.dataSetId);
      if (dataSet === undefined) {
        return;
      }
      const stateMeasure = dataSet.measures.find((m) => m.name === action.payload.name);
      if (stateMeasure !== undefined) {
        const currentGroup = stateMeasure.group;
        const isGroupChanged = currentGroup !== action.payload.group;
        const updatedMeasure = Object.assign(stateMeasure, action.payload);

        if (isGroupChanged) {
          removeFromGroup(dataSet.measuresStructure, stateMeasure, currentGroup);
          addToGroup(dataSet.measuresStructure, updatedMeasure);
        }
      }
    },
    updateReportType: (state, action: PayloadAction<ReportType>) => {
      state.reportType = action.payload;
      filterMeasures(state);
    },
    deleteMeasure: (state, action: PayloadAction<MeasureDescriptor>) => {
      const dataSet = state.dataSets.find((d) => d.dataSetId === state.dataSetId);
      if (dataSet === undefined) {
        return;
      }
      const measure = action.payload;
      const index = dataSet.allMeasures.findIndex((m) => m.name === measure.name);
      if (index < 0) return;
      dataSet.allMeasures.splice(index, 1);
      removeFromGroup(dataSet.measuresStructure, measure, measure.group);
      filterMeasures(state);
    },
    updateDictionaries: (state, action: PayloadAction<{ [key: string]: DimensionDictionary }[]>) => {
      const dataSet = state.dataSets.find((d) => d.dataSetId === state.dataSetId);
      if (dataSet === undefined) {
        return;
      }
      Object.entries(action.payload).forEach(([key, value]) => {
        const dimension = dataSet.dimensions.find((d) => d.name === key);
        if (dimension !== undefined) {
          dimension.dictionary = cloneDeep(Object.values(value).map((v) => v));
        }
      });
    },
    setChartOfAccounts: (state, action: PayloadAction<ChartOfAccounts>) => {
      state.chartOfAccounts = action.payload;
    },
    /**
     * @deprecated Don't use this action, use changeDataSet thunk instead
     */
    changeDataSet: (state, action: PayloadAction<string>) => {
      updateDataSetState(state, action.payload);
    },
  },
});

function filterMeasures(state: MetaDataState) {
  for (const dataSet of state.dataSets) {
    if (state.reportType === ReportType.Pivot) {
      dataSet.measures = filterMeasuresByUsage(dataSet.allMeasures, [MeasureUsage.PivotOnly]);
    } else if (state.reportType === ReportType.Tabular) {
      dataSet.measures = filterMeasuresByUsage(dataSet.allMeasures, [MeasureUsage.TabularOnly]);
    } else {
      dataSet.measures = dataSet.allMeasures;
    }
  }
}
const updateDataSetState = (state: MetaDataState, dataSetId: string) => {
  state.dataSetId = dataSetId;
  // Update the data set in the API context to ensure it is used in the API calls
  updateDataSet(dataSetId || "");
};

function filterMeasuresByUsage(measures: MeasureDescriptor[], expectedUsages: MeasureUsage[]) {
  return measures.filter(
    (measure): measure is MeasureDescriptor =>
      measure.usage === undefined || measure.usage === MeasureUsage.General || expectedUsages.includes(measure.usage)
  );
}

export const metaDataActions = metaDataSlice.actions;

export const selectMetaDataLoaded = (state: RootState) => state.metaData.loaded;
export const selectMetaDataLoadingFailed = (state: RootState) => state.metaData.loadingFailed;
export const selectMetaData = (state: RootState) => state.metaData;
export const selectCurrentDataSet = (state: RootState) => state.metaData.dataSetId;
const selectCurrentMetaData = (state: RootState) =>
  state.metaData.dataSets.find((d) => d.dataSetId === state.metaData.dataSetId) ?? dataSetMetaDataEmpty;
export const selectDimensions = createSelector(selectCurrentMetaData, (state) => state.dimensions);
export const selectDimensionsStructure = createSelector(selectCurrentMetaData, (state) => state.dimensionsStructure);
export const selectMeasures = createSelector(selectCurrentMetaData, (state) => state.measures);
export const selectMeasuresStructure = createSelector(selectCurrentMetaData, (state) => state.measuresStructure);
export const selectFunctions = createSelector(selectCurrentMetaData, (state) => state.functions);
export const selectMeasureGroups = createSelector(selectCurrentMetaData, (state) => state.measureGroups);
export const selectChartOfAccountsState = (state: RootState) => state.metaData.chartOfAccounts;
export const selectDimensionDictionary = (name: string | undefined) =>
  createSelector(selectCurrentMetaData, (state) => state.dimensions.find((d) => d.name === name)?.dictionary);

export const selectDimensionsForCurrentReportType = createSelector(
  selectDimensions,
  (state: RootState) => state.metaData.reportType,
  (dimensions, reportType) => {
    return reportType === ReportType.Tabular
      ? dimensions
      : dimensions.filter((d) => d.type === ItemDataType.Date || d.type === ItemDataType.General);
  }
);

export const selectPostingChartOfAccounts = createSelector(selectChartOfAccountsState, (chartOfAccounts) => {
  if (chartOfAccounts) {
    return {
      gl: chartOfAccounts.gl?.filter(filterPosting) || [],
      memo: chartOfAccounts.memo?.filter(filterPosting) || [],
    } as ChartOfAccounts;
  }
  return undefined;
});

export const selectChartOfAccounts = createSelector(selectChartOfAccountsState, (chartOfAccounts) => {
  if (chartOfAccounts) {
    return {
      gl: chartOfAccounts.gl || [],
      memo: chartOfAccounts.memo || [],
    } as ChartOfAccounts;
  }
  return undefined;
});

function filterPosting(item: ChartOfAccountsItem) {
  return item.accountType === AccountType.Posting;
}

export default metaDataSlice.reducer;

import { ChartStyleSettings } from "../../../../../shared/reporting/api/biClient.types";
import { getGridMode } from "../style/chart/GridModeItem";

export function getArgumentAxisGrid(settings: ChartStyleSettings) {
  const gridMode = getGridMode(settings);
  return { visible: gridMode === "all" };
}

export function getValueAxisGrid(settings: ChartStyleSettings) {
  const gridMode = getGridMode(settings);
  return { visible: gridMode === "horizontal" || gridMode === "all" };
}

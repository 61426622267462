import React from "react";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { useExportStateContext } from "../../../common/contexts/ExportStateContext";
import { PreviewGridStateType } from "../../hooks/useGridStateBuilder";
import { useSelector } from "react-redux";
import { selectTabularFields } from "../../../../../store/store.ts";

export default function useUpdateExportState(state: PreviewGridStateType) {
  const { tabular: locale } = useLocalization();
  const exportState = useExportStateContext();
  const fieldsAreaValues = useSelector(selectTabularFields);

  React.useEffect(() => {
    if (state.loading) {
      exportState.actions.update({
        exportAllowed: false,
        warning: locale.data_is_calculated_warning,
      });
    } else if (state.configurationValidity.valid === false) {
      exportState.actions.update({
        exportAllowed: false,
        warning: locale.grid_is_not_configured_warning,
      });
    } else if (state.rows.length === 0) {
      exportState.actions.update({
        exportAllowed: false,
        warning: locale.no_data_to_export_warning,
      });
    } else {
      exportState.actions.update({
        exportAllowed: true,
        warning: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsAreaValues, state.loading, state.configurationValidity, state.rows]);
}

import { Grid2 } from "@mui/material";
import StyleGroup from "../StyleGroup";
import GridModeItem from "./GridModeItem";
import RotateItem from "./RotateItem";
import ThicknessItem from "./ThicknessItem";
import ToolTipItem from "./ToolTipItem";
import { useSelector } from "react-redux";
import { selectChartSettingsState } from "../../../../../store/store";

export default function ChartStyles() {
  const settingsAreaValues = useSelector(selectChartSettingsState);

  return (
    <StyleGroup caption="General">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        <GridModeItem settings={settingsAreaValues} />
        <RotateItem settings={settingsAreaValues} />
        <ThicknessItem settings={settingsAreaValues} />
        <ToolTipItem settings={settingsAreaValues} />
      </Grid2>
    </StyleGroup>
  );
}

import { Outlet } from "react-router";
import GlobalNotificationErrorSnackbar from "../../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../../shared/components/GlobalNotificationSnackbar";
import { NotificationContextProvider } from "../../shared/contexts/NotificationContext";
import sharedBiClient from "../../shared/reporting/api/biClient";
import { GroupsContextProvider } from "../../shared/reporting/components/groups/contexts/GroupsContext";
import { BiApiClientProvider } from "../../shared/reporting/contexts/BiApiClientProviderContext";
import biClient from "../api/biApi";
import { BiApiClient } from "../contexts/ApiClientProviderContext";
import { useClientContext } from "../contexts/ClientContext";
import { useLocalization } from "../hooks/useLocalization";
import useReportGroups from "./builder/common/hooks/useReportGroups";
import { getEditReportUrl, getUiReportsUrl, getViewReportUrl } from "./builder/common/utilities/editReportUrl";
import ImportantDataLoadingFailed from "./common/ImportantDataLoadingFailed";

const ReportsContainer = () => {
  const { common: locale } = useLocalization();
  const { clientCode } = useClientContext();
  const { reportGroups, actions, ui } = useReportGroups();

  if (ui.error) {
    return (
      <ImportantDataLoadingFailed
        title={locale.report_data_loading_failed_title}
        disclaimer={locale.report_data_loading_failed_text}
      />
    );
  }

  return (
    <BiApiClientProvider<BiApiClient>
      api={{
        ...biClient.uiReports,
        ...sharedBiClient.uiReports,
        getEditReportUrl,
        getViewReportUrl,
        getReportsUrl: getUiReportsUrl,
      }}
    >
      <GroupsContextProvider groups={{ [clientCode]: reportGroups }} ui={ui} actions={actions}>
        <NotificationContextProvider>
          <Outlet />
          <GlobalNotificationSnackbar />
          <GlobalNotificationErrorSnackbar />
        </NotificationContextProvider>
      </GroupsContextProvider>
    </BiApiClientProvider>
  );
};

export default ReportsContainer;

import { useMemo } from "react";
import { useSelector } from "react-redux";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { BuildPivotReportRequest } from "../../../../../api/biApi.types";
import { selectCacheSessionId, selectCurrentReport, selectSessionId } from "../../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../../store/devToolsSlice";
import {
  selectConditions,
  selectRows,
  selectColumns,
  selectValues,
  selectPivotSettingsState,
  selectSorts,
} from "../../../../../store/store";

export default function useRequestConfigBuilder() {
  const conditionsAreaValues = useSelector(selectConditions);
  const rowsAreaValues = useSelector(selectRows);
  const columnsAreaValues = useSelector(selectColumns);
  const valuesAreaValues = useSelector(selectValues);
  const sortAreaValues = useSelector(selectSorts);
  const settingsAreaValues = useSelector(selectPivotSettingsState);

  const report = useSelector(selectCurrentReport);

  const isQueryLoggingActive = useSelector(selectLogQueries);
  const sessionId = useSelector(selectSessionId);
  const cacheSessionId = useSelector(selectCacheSessionId);

  const requestConfig = useMemo((): BuildPivotReportRequest => {
    const request: BuildPivotReportRequest = {
      conditions: conditionsAreaValues.map((c) => ({ ...c.config, invalid: c.invalid })),
      rows: rowsAreaValues.map((v) => v.config),
      columns: columnsAreaValues.map((v) => v.config),
      values: valuesAreaValues.map((v) => v.config),
      sort: sortAreaValues.map((s) => s.config),
      settings: cloneDeep(settingsAreaValues),
      reportId: report?.reportId ?? "",
      sessionId,
      cacheSessionId,
      useQueryLogging: isQueryLoggingActive === true,
      withDrilldown: true,
    };

    return request;
  }, [
    conditionsAreaValues,
    rowsAreaValues,
    columnsAreaValues,
    valuesAreaValues,
    sortAreaValues,
    settingsAreaValues,
    report?.reportId,
    sessionId,
    cacheSessionId,
    isQueryLoggingActive,
  ]);

  return { requestConfig };
}

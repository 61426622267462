import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { SeriesSettings, ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

interface Props {
  settings: ChartStyleSettings;
  serie: SeriesSettings;
}

export default function ShowLabelItem({ settings, serie }: Props) {
  const showLabel = React.useMemo(() => isShowSerieLabel(serie.name, settings), [settings, serie]);
  const dispatch = useAppDispatch();
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Show Labels</Typography>
      <HorizontalFill />
      <Switch
        checked={showLabel}
        onChange={(_, value) =>
          dispatch(fieldsStateActions.updateSeriesSettings({ name: serie.name, changes: { showLabel: value } }))
        }
      />
    </Stack>
  );
}

export function isShowSerieLabel(name: string | undefined, settings: ChartStyleSettings) {
  return settings.series?.find((s) => s.name === name)?.showLabel !== false;
}

import { Box, Divider, Grid2, Tab, Tabs } from "@mui/material";
import { useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import SortFieldsContainer from "../../common/sorting/SortFieldsContainer";
import { TableComponentProps } from "../../Types";
import ColumnsDropFieldsContainer from "./columns/ColumnsDropFieldsContainer";
import ConditionsDropFieldsContainer from "./conditions/ConditionsDropFieldsContainer";
import FormatContainer from "./format/FormatContainer";
import RowsDropFieldsContainer from "./rows/RowsDropFieldsContainer";
import SettingsContainer from "./settings/SettingsContainer";
import ValuesDropFieldsContainer from "./values/ValuesDropFieldsContainer";
import { selectRows, selectColumns, selectSorts } from "../../../../store/store";
import { useSelector } from "react-redux";

export const TableComponent = ({ ReportTypeSelector }: TableComponentProps) => {
  const rowsAreaValues = useSelector(selectRows);
  const columnsAreaValues = useSelector(selectColumns);
  const sortAreaValues = useSelector(selectSorts);

  const [selectedTab, setSelectedTab] = useState(0);

  const availableFields = useMemo(() => {
    const result = rowsAreaValues
      .concat(columnsAreaValues)
      .filter((f) => !sortAreaValues.find((v) => v.meta.name === f.meta.name));
    return result;
  }, [rowsAreaValues, columnsAreaValues, sortAreaValues]);

  const fields = useMemo(() => {
    return rowsAreaValues.concat(columnsAreaValues);
  }, [rowsAreaValues, columnsAreaValues]);

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid2 container px="1.3rem" width={"100%"}>
        {ReportTypeSelector}
        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value: number) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid2>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer disableEqualitySelection={true} />
          <RowsDropFieldsContainer />
          <ColumnsDropFieldsContainer />
          <ValuesDropFieldsContainer fields={fields} />
          <SortFieldsContainer availableFields={availableFields} />
        </ScrollableFlexContainer>
      )}

      {selectedTab === 1 && <SettingsContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Box>
  );
};

export default TableComponent;

import { Dialog, Divider, Grid2, SxProps, Theme } from "@mui/material";
import { JSX, PropsWithChildren, useState } from "react";
import PageToolbar from "./PageToolbar";

interface ExpandableDialogProps {
  title: JSX.Element | undefined;
  separateTitle: boolean;
  dialogSx?: SxProps<Theme>;
  backdropSx?: SxProps<Theme>;
  onClose: () => void;
}

export default function ExpandableDialog({
  title,
  separateTitle,
  dialogSx,
  backdropSx,
  children,
  onClose,
}: PropsWithChildren<ExpandableDialogProps>) {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <Dialog
      open={true}
      maxWidth="xl"
      fullScreen={fullScreen}
      sx={{ mx: 5, ...dialogSx }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0,0,0,0.1)",
            ...backdropSx,
          },
        },
        paper: {
          sx: {
            borderRadius: 0,
            height: "100%",
            maxHeight: "unset",
            width: "100%",
            transition: "max-width .4s ease",
            margin: "unset",
            overflow: "unset",
          },
        },
      }}
    >
      <Grid2 container sx={{ flexDirection: "column", flex: 1, width: "100%" }}>
        <PageToolbar title={title} fullScreen={fullScreen} setFullScreen={setFullScreen} onClose={onClose} />
        {separateTitle && <Divider orientation="horizontal" flexItem />}
        {children}
      </Grid2>
    </Dialog>
  );
}

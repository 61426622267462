import { currentReportActions, selectCurrentReport, selectReportConfiguration } from "../currentReportSlice";
import { selectDimensions, selectMeasures } from "../metaDataSlice";
import { setReportType } from "../fieldState/fieldsStateSlice";
import { setFieldStatesConfiguration } from "../fieldState/fieldsStateSliceHelper";
import { selectReportType } from "../store";
import {
  ChartStyleSettings,
  ConditionConfiguration,
  DimensionDescriptor,
  DimensionField,
  FieldConfiguration,
  PivotGeneralSettings,
  ReportField,
  ReportType,
  SortConfiguration,
  TabularGeneralSettings,
} from "../../../shared/reporting/api/biClient.types";
import { createDefaultConfiguration } from "../../components/builder/utils/isConfigurationValid";
import { updateReport } from "./currentReportThunks";
import { configurationToConditions } from "../../components/builder/utils/configuration/configurations";
import { ConditionField, SortField } from "../../components/builder/Types";
import { tabularFieldsStateThunks } from "./tabularFieldsStateThunks";
import { pivotFieldsStateThunks } from "./pivotFieldsStateThunks";
import { NoDuplicateKeys } from "../../utilities/typesHelper";
import { pivotFieldsActions } from "../fieldState/pivotFieldsSlice";
import { tabularFieldsActions } from "../fieldState/tabularFieldsSlice";
import { chartFieldsActions } from "../fieldState/chartStateSlice";
import { chartFieldsStateThunks } from "./chartFieldsStateThunks";
import { chartRelatedReportTypes } from "../../components/builder/utils/configuration/chartConfiguration";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk";

const initializeFieldsState = createTypedAsyncThunk(
  "fieldsState/initializeFieldsState",
  (_, { dispatch, getState }) => {
    const state = getState();
    const report = selectCurrentReport(state);
    if (!report) {
      throw new Error("No report found");
    }

    dispatch(setReportType(report.reportType));
    const dimensions = selectDimensions(state);
    const measures = selectMeasures(state);
    setFieldStatesConfiguration(dimensions, measures, report, dispatch);
    dispatch(currentReportActions.refreshSession());
  }
);

const resetFieldsState = createTypedAsyncThunk(
  "fieldsState/resetFieldsState",
  (reportType: ReportType | undefined, { dispatch }) => {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(tabularFieldsActions.resetToDefaultAction());
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(pivotFieldsActions.resetToDefaultAction());
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(chartFieldsActions.resetToDefaultAction(reportType));
  }
);

const handleReportTypeChange = createTypedAsyncThunk(
  "fieldsState/handleReportTypeChange",
  (reportType: ReportType, { dispatch, getState }) => {
    const state = getState();
    const currentReport = selectCurrentReport(state);
    const currentConfiguration = selectReportConfiguration(state);
    const dimensions = selectDimensions(state);

    const configuration = createDefaultConfiguration(reportType);
    configuration.conditions = currentConfiguration?.conditions ?? currentReport?.configuration.conditions ?? [];
    dispatch(updateReport({ reportType }));
    dispatch(resetFieldsState(reportType));
    dispatch(setReportType(reportType));

    const result = configurationToConditions(configuration.conditions, dimensions);
    dispatch(setConditions(result));

    dispatch(currentReportActions.refreshSession());
  }
);

const setConditions = createTypedAsyncThunk<void, ConditionField[]>(
  "fields/setConditions",
  (conditions, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.setConditions(conditions));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.setConditions(conditions));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.setConditions(conditions));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const addCondition = createTypedAsyncThunk<void, { field: ConditionField; index: number }>(
  "fields/addCondition",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());

    if (!reportType) {
      throw new Error("Report type not initialized");
    }

    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.addCondition(payload));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.addCondition(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.addCondition(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const removeCondition = createTypedAsyncThunk<void, ConditionField>(
  "fields/removeCondition",
  (field, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.removeCondition(field));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.removeCondition(field));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.removeCondition(field));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const moveCondition = createTypedAsyncThunk<void, { field: ConditionField; newIndex: number }>(
  "fields/moveCondition",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.moveCondition(payload));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.moveCondition(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.moveCondition(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const updateCondition = createTypedAsyncThunk<void, { field: ConditionField; changes: Partial<ConditionField> }>(
  "fields/updateCondition",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.updateCondition(payload));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.updateCondition(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.updateCondition(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const updateConditionConfig = createTypedAsyncThunk<
  void,
  { field: ConditionField; changes: Partial<ConditionConfiguration> }
>("fields/updateConditionConfig", (payload, { dispatch, getState }) => {
  const reportType = selectReportType(getState());
  if (!reportType) {
    throw new Error("Report type not initialized");
  }
  if (reportType === ReportType.Pivot) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(pivotFieldsActions.updateConditionConfig(payload));
  } else if (reportType === ReportType.Tabular) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(tabularFieldsActions.updateConditionConfig(payload));
  } else if (chartRelatedReportTypes.includes(reportType)) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(chartFieldsActions.updateConditionConfig(payload));
  } else {
    throw new Error("Report type not initialized");
  }
});

const setSorting = createTypedAsyncThunk<void, { fields: SortField[]; groupMetaNames: string[] }>(
  "fields/setSorting",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.setSorting(payload));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.setSorting(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.setSorting(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const addSorting = createTypedAsyncThunk<void, DimensionField>("fields/addSorting", (field, { dispatch, getState }) => {
  const reportType = selectReportType(getState());
  if (!reportType) {
    throw new Error("Report type not initialized");
  }
  if (reportType === ReportType.Pivot) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(pivotFieldsActions.addSorting(field));
  } else if (reportType === ReportType.Tabular) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(tabularFieldsActions.addSorting(field));
  } else if (chartRelatedReportTypes.includes(reportType)) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(chartFieldsActions.addSorting(field));
  } else {
    throw new Error("Report type not initialized");
  }
});

const removeSorting = createTypedAsyncThunk<void, SortField>(
  "fields/removeSorting",
  (field, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.removeSorting(field));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.removeSorting(field));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.removeSorting(field));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const removeSortingByMeta = createTypedAsyncThunk<void, DimensionDescriptor>(
  "fields/removeSortingByMeta",
  (descriptor, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.removeSortingByMeta(descriptor));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.removeSortingByMeta(descriptor));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.removeSortingByMeta(descriptor));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const moveSorting = createTypedAsyncThunk<void, { field: SortField; newIndex: number }>(
  "fields/moveSorting",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.moveSorting(payload));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.moveSorting(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.moveSorting(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const updateSorting = createTypedAsyncThunk<void, { field: SortField; changes: Partial<SortField> }>(
  "fields/updateSorting",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.updateSorting(payload));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.updateSorting(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.updateSorting(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const updateSortingConfig = createTypedAsyncThunk<void, { field: SortField; changes: Partial<SortConfiguration> }>(
  "fields/updateSortingConfig",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.updateSortingConfig(payload));
    } else if (reportType === ReportType.Tabular) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.updateSortingConfig(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.updateSortingConfig(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const setSettings = createTypedAsyncThunk<
  void,
  { settings: PivotGeneralSettings | TabularGeneralSettings | ChartStyleSettings }
>("fields/setSettings", (payload, { dispatch, getState }) => {
  const reportType = selectReportType(getState());
  if (!reportType) {
    throw new Error("Report type not initialized");
  }
  if (reportType === ReportType.Pivot) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(pivotFieldsActions.setSettings(payload.settings as PivotGeneralSettings));
  } else if (reportType === ReportType.Tabular) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(tabularFieldsActions.setSettings(payload.settings as TabularGeneralSettings));
  } else if (chartRelatedReportTypes.includes(reportType)) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(chartFieldsActions.setSettings(payload.settings as Partial<ChartStyleSettings>));
  } else {
    throw new Error("Report type not initialized");
  }
});

const updateSettings = createTypedAsyncThunk<
  void,
  { settings: Partial<PivotGeneralSettings> | Partial<TabularGeneralSettings> | Partial<ChartStyleSettings> }
>("fields/updateSettings", (payload, { dispatch, getState }) => {
  const reportType = selectReportType(getState());

  if (!reportType) {
    throw new Error("Report type not initialized");
  }
  if (reportType === ReportType.Pivot) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(pivotFieldsActions.updateSettings(payload.settings as Partial<PivotGeneralSettings>));
  } else if (reportType === ReportType.Tabular) {
    //dispatch(settingsActions.updateSettingsAction(payload.settings as Partial<TabularGeneralSettings>));
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(tabularFieldsActions.updateSettings(payload.settings as Partial<TabularGeneralSettings>));
  } else if (chartRelatedReportTypes.includes(reportType)) {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(chartFieldsActions.updateSettings(payload.settings as Partial<ChartStyleSettings>));
  } else {
    throw new Error("Report type not initialized");
  }
});

const setMeasures = createTypedAsyncThunk<void, ReportField[]>(
  "fields/setMeasures",
  (measures, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.setMeasures(measures));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.setMeasures(measures));
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      measures.forEach((field) => chartFieldsActions.addSeries(field.config.guid));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const addMeasure = createTypedAsyncThunk<void, { field: ReportField; index: number }>(
  "fields/addMeasure",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.addMeasure(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.addMeasure(payload));
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.addSeries(payload.field.config.guid));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const removeMeasure = createTypedAsyncThunk<void, ReportField>(
  "fields/removeMeasure",
  (field, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.removeMeasure(field));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.removeMeasure(field));
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.removeSeries(field.config.guid));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const moveMeasure = createTypedAsyncThunk<void, { field: ReportField; newIndex: number }>(
  "fields/moveMeasure",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.moveMeasure(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.moveMeasure(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const updateMeasure = createTypedAsyncThunk<void, { field: ReportField; changes: Partial<ReportField> }>(
  "fields/updateMeasure",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.updateMeasure(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.updateMeasure(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const updateMeasureConfig = createTypedAsyncThunk<void, { field: ReportField; changes: Partial<FieldConfiguration> }>(
  "fields/updateMeasureConfig",
  (payload, { dispatch, getState }) => {
    const reportType = selectReportType(getState());
    if (!reportType) {
      throw new Error("Report type not initialized");
    }
    if (reportType === ReportType.Pivot) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(pivotFieldsActions.updateMeasureConfig(payload));
    } else if (chartRelatedReportTypes.includes(reportType)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(chartFieldsActions.updateMeasureConfig(payload));
    } else {
      throw new Error("Report type not initialized");
    }
  }
);

const fieldStateThunks = {
  initializeFieldsState,
  handleReportTypeChange,
  setConditions,
  addCondition,
  removeCondition,
  moveCondition,
  updateCondition,
  updateConditionConfig,
  setSorting,
  addSorting,
  removeSorting,
  removeSortingByMeta,
  moveSorting,
  updateSorting,
  updateSortingConfig,
  setSettings,
  updateSettings,
  setMeasures,
  addMeasure,
  removeMeasure,
  moveMeasure,
  updateMeasure,
  updateMeasureConfig,
  resetFieldsState,
};

export const fieldsStateActions = {
  ...fieldStateThunks,
  // Type to ensure no duplicate keys between objects
  ...(tabularFieldsStateThunks as NoDuplicateKeys<typeof tabularFieldsStateThunks, typeof pivotFieldsStateThunks>),
  ...(pivotFieldsStateThunks as NoDuplicateKeys<typeof pivotFieldsStateThunks, typeof tabularFieldsStateThunks>),
  ...(chartFieldsStateThunks as NoDuplicateKeys<typeof chartFieldsStateThunks, typeof pivotFieldsStateThunks>),
};

import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { User } from "../api/identityApi.types";
import { redirectToUnauthorized } from "../../shared/auth/internal/redirects";
import { updateClientCode } from "../../shared/api/clientApiContext";
import { ClientInfoBase } from "../../shared/api/clientTypes";

export function useClientNavigation(
  client: string | undefined,
  user: User | undefined,
  setClientCode: (code: string | undefined) => void,
  clients: ClientInfoBase[] | undefined
) {
  const navigate = useNavigate();

  const setCurrentClientCode = useCallback(
    (newClientCode: string) => {
      setClientCode(newClientCode);
      navigate(`/${newClientCode}`);
    },
    [navigate, setClientCode]
  );

  useEffect(() => {
    if (user === undefined) return;

    let validClientCode = user.permissions.find((p) => p.clientCode === client)?.clientCode;

    if (!validClientCode) {
      const firstClient = user.permissions[0];
      if (firstClient) {
        validClientCode = firstClient.clientCode;
        setCurrentClientCode(firstClient.clientCode);
      } else {
        redirectToUnauthorized();
      }
      return;
    }

    setClientCode(validClientCode);
    updateClientCode(validClientCode || "");
  }, [client, user, clients, setClientCode, setCurrentClientCode]);

  return { setCurrentClientCode };
}

import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  ChartStyleSettings,
  LinearSeriesSettings,
  SerieType,
  SeriesSettings,
} from "../../../../../../shared/reporting/api/biClient.types";
import AreaChartIcon from "../../../../../../shared/reporting/icons/reportTypes/AreaChartIcon";
import BarChartIcon from "../../../../../../shared/reporting/icons/reportTypes/BarChartIcon";
import LineChartIcon from "../../../../../../shared/reporting/icons/reportTypes/LineChartIcon";
import { getDefaultSerieType, isLinearSettings } from "../../contexts/FieldsStateContext.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";

interface Props {
  settings: ChartStyleSettings;
  serie: SeriesSettings;
}

export default function SerieTypeItem({ settings, serie }: Props) {
  const dispatch = useAppDispatch();
  const supported = isLinearSettings(settings);
  const serieType = getSerieType(settings, serie);

  const handleSerieTypeChanged = React.useCallback(
    (value: SerieType) =>
      dispatch(fieldsStateActions.updateSeriesSettings({ name: serie.name, changes: { serieType: value } })),
    [dispatch, serie]
  );

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Serie Type</Typography>
      <HorizontalFill />
      <BarSerie selectedType={serieType} onSelected={handleSerieTypeChanged} />
      <LineSerie selectedType={serieType} onSelected={handleSerieTypeChanged} />
      <AreaSerie selectedType={serieType} onSelected={handleSerieTypeChanged} />
    </Stack>
  );
}

export function getSerieType(settings: ChartStyleSettings, serie: SeriesSettings | undefined): SerieType {
  const linearSerie = serie as LinearSeriesSettings;
  return linearSerie?.serieType || getDefaultSerieType(settings.type);
}

interface SerieProps {
  selectedType: SerieType;
  onSelected: (type: SerieType) => void;
}

function BarSerie({ selectedType, onSelected }: SerieProps) {
  return (
    <IconButton sx={{ borderRadius: "unset" }} onClick={() => onSelected("Bar")}>
      <BarChartIcon
        sx={(theme) => ({
          color: selectedType === "Bar" ? theme.palette.primary.main : theme.palette.secondary.light,
        })}
      />
    </IconButton>
  );
}

function LineSerie({ selectedType, onSelected }: SerieProps) {
  return (
    <IconButton sx={{ borderRadius: "unset" }} onClick={() => onSelected("Line")}>
      <LineChartIcon
        sx={(theme) => ({
          color: selectedType === "Line" ? theme.palette.primary.main : theme.palette.secondary.light,
        })}
      />
    </IconButton>
  );
}

function AreaSerie({ selectedType, onSelected }: SerieProps) {
  return (
    <IconButton sx={{ borderRadius: "unset" }} onClick={() => onSelected("Area")}>
      <AreaChartIcon
        sx={(theme) => ({
          color: selectedType === "Area" ? theme.palette.primary.main : theme.palette.secondary.light,
        })}
      />
    </IconButton>
  );
}

import { logError } from "../../../shared/logging";
import biClient from "../../api/biApi";
import { clientSettingsActions } from "../clientSettingsSlice.ts";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk.ts";

const defaultRejectedValue = "Failed to load client settings";

export const loadClientSettings = createTypedAsyncThunk(
  "clientSettings/loadClientSettings",
  async (_, { dispatch }) => {
    try {
      const response = await biClient.getClientSettings();
      if (response.success && response.data) {
        dispatch(clientSettingsActions.setSettings(response.data));
        return response.data;
      }
      throw new Error(defaultRejectedValue);
    } catch (error) {
      logError(error, "loadClientSettings");
      throw new Error(defaultRejectedValue);
    }
  }
);

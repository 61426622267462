import { MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  ChartStyleSettings,
  LinearSeriesSettings,
  SeriesSettings,
  ValueAxisType,
} from "../../../../../../shared/reporting/api/biClient.types";
import { isLinearSettings } from "../../contexts/FieldsStateContext.types";
import { useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

interface Props {
  settings: ChartStyleSettings;
  serie: SeriesSettings;
}

export default function SerieValueAxis({ settings, serie }: Props) {
  const supported = isLinearSettings(settings);
  const valueAxisType = getValueAxisType(serie);
  const dispatch = useAppDispatch();

  const handleLineTypeChanged = React.useCallback(
    (value: ValueAxisType) =>
      dispatch(fieldsStateActions.updateSeriesSettings({ name: serie.name, changes: { valueAxis: value } })),
    [dispatch, serie]
  );

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Value Axis</Typography>
      <HorizontalFill />
      <Select
        value={valueAxisType}
        sx={{
          width: 200,
          ".MuiSelect-select": {
            py: 0.7,
            fontSize: 13,
          },
        }}
        onChange={(evt) => handleLineTypeChanged(evt.target.value as ValueAxisType)}
      >
        <MenuItem value={"Main"} sx={{ fontSize: 13 }}>
          Main(left)
        </MenuItem>
        <MenuItem value={"Secondary"} sx={{ fontSize: 13 }}>
          Secondary(right)
        </MenuItem>
      </Select>
    </Stack>
  );
}

export function getValueAxisType(serie: SeriesSettings | undefined): ValueAxisType {
  const linearSerie = serie as LinearSeriesSettings;
  return linearSerie?.valueAxis || "Main";
}

import { Grid2 } from "@mui/material";
import StyleGroup from "../StyleGroup";
import HorizontalAlignmentItem from "./HorizontalAlignmentItem";
import Title from "./Title";
import VerticalAlignmentItem from "./VerticalAlignmentItem";

export default function LegendStyles() {
  return (
    <StyleGroup caption="Legend">
      <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
        <HorizontalAlignmentItem />
        <VerticalAlignmentItem />
        <Title />
      </Grid2>
    </StyleGroup>
  );
}

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Menu } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ActionMenuItem from "../../../shared/components/ActionMenuItem";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import FolderManagedIcon from "../../../shared/icons/FolderManagedIcon";
import sharedBiClient from "../../../shared/reporting/api/biClient";
import { ClientInfo, ReportConfiguration, ReportTemplate } from "../../../shared/reporting/api/biClient.types";
import DeleteTemplateDialog from "../../../shared/reporting/components/reportTemplates/dialogs/DeleteTemplateDialog";
import SaveTemplateDialog from "../../../shared/reporting/components/reportTemplates/dialogs/SaveTemplateDialog";
import { useLocalization } from "../../hooks/useLocalization";
import SourceEnvironmentIcon from "../../icons/SourceEnvironmentIcon";
import { getOrganizationReportTemplatesUrl } from "../builder/common/utilities/editReportUrl";
import DataSourceDialog from "./dialogs/DataSourceDialog";

interface Props {
  anchorEl: HTMLElement | null;
  template: ReportTemplate;
  templateConfiguration: ReportConfiguration | undefined;
  companies: ClientInfo[];
  onClose: () => void;
  onEdited: (rt: ReportTemplate, dataSourceChanged?: boolean) => void;
  onDataSourceChanged: (ds: string) => void;
}

export default function TemplateActions({
  anchorEl,
  template,
  companies,
  onClose,
  onEdited,
  templateConfiguration,
  onDataSourceChanged,
}: Props) {
  const { sendNotification } = useNotificationContext();
  const { report_templates: locale } = useLocalization();
  const [openEditDialog, setOpenEditDialog] = useState<boolean>();
  const [openChangeDataSourceDialog, setOpenChangeDataSourceDialog] = useState<boolean>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>();
  const [open, setOpen] = useState(() => !!anchorEl);

  const onDeleteReport = useCallback(() => {
    setOpen(false);
    setOpenDeleteDialog(true);
  }, [setOpenDeleteDialog]);

  const onChangeDataSource = useCallback(() => {
    setOpen(false);
    setOpenChangeDataSourceDialog(true);
  }, [setOpenChangeDataSourceDialog]);

  const onEditTemplate = useCallback(() => {
    setOpen(false);
    setOpenEditDialog(true);
  }, [setOpenEditDialog]);

  useEffect(() => {
    if (openDeleteDialog === false || openChangeDataSourceDialog === false || openEditDialog === false) {
      onClose();
    }
  }, [openDeleteDialog, openChangeDataSourceDialog, openEditDialog, onClose]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => onClose()}
        slotProps={{
          list: { sx: { width: "220px" } },
        }}
      >
        <ActionMenuItem
          key={"editTemplate"}
          icon={<FolderManagedIcon />}
          title="Edit Template information"
          onClick={onEditTemplate}
        />
        <ActionMenuItem
          key={"datasource"}
          icon={<SourceEnvironmentIcon />}
          title="Change Data source"
          onClick={onChangeDataSource}
        />
        <ActionMenuItem
          key={"delete"}
          icon={<DeleteOutlineOutlinedIcon />}
          title="Delete"
          onClick={onDeleteReport}
          sx={(theme) => ({
            color: theme.palette.error.main,
            svg: {
              color: theme.palette.error.main,
            },
          })}
        />
      </Menu>
      {openChangeDataSourceDialog && template && (
        <DataSourceDialog
          template={template}
          companies={companies}
          onClose={() => setOpenChangeDataSourceDialog(false)}
          onSaved={(ds) => {
            onDataSourceChanged(ds);
            setOpenChangeDataSourceDialog(false);
          }}
        />
      )}
      {openDeleteDialog && template && (
        <DeleteTemplateDialog
          templates={[template]}
          doDelete={(templates) =>
            sharedBiClient.deleteReportTemplates({
              templates: templates.map((t) => ({ templateId: t.reportId, organization: t.organization })),
            })
          }
          onDeleted={() => {
            sendNotification(locale.deleted);
            window.open(getOrganizationReportTemplatesUrl(template.organization), "_self", "noopener noreferrer");
          }}
          onClose={() => setOpenDeleteDialog(false)}
        />
      )}
      {openEditDialog && template && (
        <SaveTemplateDialog
          title="Edit Template information"
          template={template}
          templateConfiguration={templateConfiguration}
          onClose={() => setOpenEditDialog(false)}
          onSaved={(rt, dataSourceChanged) => {
            onEdited(rt, dataSourceChanged);
            setOpenEditDialog(false);
            sendNotification(locale.saved);
          }}
          companies={companies}
        />
      )}
    </>
  );
}

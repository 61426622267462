import { Divider, Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../../../hooks/useLocalization";
import CheckboxField from "../../../../common/CheckboxField";
import SwitchField from "../../../../common/SwitchField";
import { useSelector } from "react-redux";
import { selectPivotSettingsState, useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

export default function Settings() {
  const settingsAreaValues = useSelector(selectPivotSettingsState);
  const dispatch = useAppDispatch();
  const { pivot: locale } = useLocalization();
  const hideBlankRows = settingsAreaValues.hideBlankRows === true;
  const hideBlankColumns = settingsAreaValues.hideBlankColumns === true;

  return (
    <Stack pt={1} spacing={1.5} width={"100%"}>
      <Stack spacing={1.5} pb={1.5}>
        <Typography variant="subtitle1">General</Typography>
        <Stack>
          <SwitchField
            label="Hide Blank Rows"
            checked={hideBlankRows}
            onChange={(hideBlankRows) => {
              const resetHideZeroRows = hideBlankRows === false ? false : settingsAreaValues.hideZeroRows;
              dispatch(
                fieldsStateActions.updateSettings({ settings: { hideBlankRows, hideZeroRows: resetHideZeroRows } })
              );
            }}
            tooltipText={locale.hide_blank_rows_explanation}
            containerSx={{ py: 0.75 }}
          />
          {hideBlankRows && (
            <CheckboxField
              checked={settingsAreaValues.hideZeroRows === true}
              onChange={(value) => dispatch(fieldsStateActions.updateSettings({ settings: { hideZeroRows: value } }))}
              label="Treat Zeros as Empty"
              formControlLabelSx={{ ml: 1.25 }}
            />
          )}
          <SwitchField
            label="Hide Blank Columns"
            checked={hideBlankColumns}
            onChange={(hideBlankColumns) => {
              const resetHideZeroColumns = hideBlankColumns === false ? false : settingsAreaValues.hideZeroColumns;
              dispatch(
                fieldsStateActions.updateSettings({
                  settings: { hideBlankColumns, hideZeroColumns: resetHideZeroColumns },
                })
              );
            }}
            tooltipText={locale.hide_blank_columns_explanation}
            containerSx={{ py: 0.75 }}
          />
          {hideBlankColumns && (
            <CheckboxField
              checked={settingsAreaValues.hideZeroColumns === true}
              onChange={(value) =>
                dispatch(fieldsStateActions.updateSettings({ settings: { hideZeroColumns: value } }))
              }
              label="Treat Zeros as Empty"
              formControlLabelSx={{ ml: 1.25 }}
            />
          )}
        </Stack>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Typography variant="subtitle1">Totals</Typography>
        <SwitchField
          label="Show Rows Grand Total"
          checked={settingsAreaValues.rowsGrandTotal === true}
          onChange={(newValue) =>
            dispatch(fieldsStateActions.updateSettings({ settings: { rowsGrandTotal: newValue } }))
          }
        />
      </Stack>
    </Stack>
  );
}

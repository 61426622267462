import { Box, List, Typography } from "@mui/material";
import { useCallback } from "react";
import { DimensionField, Ordering } from "../../../../../shared/reporting/api/biClient.types";
import { SortField } from "../../Types";
import SelectSortFieldInput from "./SelectSortFieldInput";
import SortFieldItem from "./SortFieldItem";
import { selectSorts, useAppDispatch } from "../../../../store/store";
import { useSelector } from "react-redux";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks";

interface Props {
  availableFields: DimensionField[];
}

export const SortFieldsContainer = ({ availableFields }: Props) => {
  const sortsAreaValues = useSelector(selectSorts);

  const dispatch = useAppDispatch();

  const onChangeSorting = useCallback(
    (field: SortField, ordering: Ordering | undefined) => {
      dispatch(fieldsStateActions.updateSortingConfig({ field, changes: { ordering } }));
    },
    [dispatch]
  );

  const onRemoveItem = useCallback(
    (field: SortField) => {
      dispatch(fieldsStateActions.removeSorting(field));
    },
    [dispatch]
  );

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: ".5rem",
      })}
    >
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Sort
      </Typography>
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F9F9F9",
          minHeight: "90px",
          border: "1px solid #E0E0E0",
          borderRadius: "2px",
          p: ".5rem",
          gap: ".5rem",
        })}
      >
        {sortsAreaValues.length > 0 && (
          <List sx={{ p: 0, display: "flex", flexDirection: "column", gap: "5px" }}>
            {sortsAreaValues.map((item) => {
              return (
                <SortFieldItem
                  key={item.meta.name}
                  field={item}
                  onRemoveItem={() => onRemoveItem(item)}
                  onChangeSorting={(order) => onChangeSorting(item, order)}
                />
              );
            })}
          </List>
        )}
        <SelectSortFieldInput availableFields={availableFields} />
      </Box>
    </Box>
  );
};

export default SortFieldsContainer;

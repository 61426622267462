import { Grid2 } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import { useClientPermissionsContext } from "../../../shared/contexts/ClientPermissionsContext";
import sharedBiClient from "../../../shared/reporting/api/biClient";
import { getAccessibleCompanies } from "../../../shared/reporting/components/reportTemplates/helpers/reportTemplateHelper";
import { useClientContext } from "../../contexts/ClientContext";
import { useLocalization } from "../../hooks/useLocalization";
import { selectCurrentReport } from "../../store/currentReportSlice";
import { getNewReportTemplate } from "../../store/currentReportSlice.helper";
import { metaDataActions } from "../../store/metaDataSlice";
import { setCompanies } from "../../store/reportTemplatesSlice";
import { AppDispatch } from "../../store/store";
import { setReport } from "../../store/thunks/currentReportThunks";
import DrillDownEntry from "../builder/common/drillDown/DrillDownEntry";
import { getEditTemplateUrl, getOrganizationReportTemplatesUrl } from "../builder/common/utilities/editReportUrl";
import TemplateNotFound from "./TemplateNotFound";

export default function TemplateRoot({ children }: React.PropsWithChildren) {
  const { id, organization } = useParams();
  const navigate = useNavigate();
  const { clientCode: dataSource, clientInfo, clients } = useClientContext();
  const { permissionSets } = useClientPermissionsContext();
  const [showTemplateNotFound, setShowTemplateNotFound] = useState(false);
  const { metadata: locale } = useLocalization();
  const dispatch: AppDispatch = useDispatch();
  const template = useSelector(selectCurrentReport);
  const companies = useMemo(
    () => getAccessibleCompanies(clients, permissionSets, clientInfo?.organization || ""),
    [clients, permissionSets, clientInfo?.organization]
  );

  useEffect(() => {
    dispatch(setCompanies(companies));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    if (id === undefined || organization === undefined) {
      return;
    }

    if (dataSource && companies.length > 0 && !companies.find((c) => c.clientCode === dataSource)) {
      const newDataSource = companies.at(0)?.clientCode || "";
      navigate(getEditTemplateUrl(newDataSource, id, organization));
      return;
    }

    if (id === "new") {
      dispatch(setReport(getNewReportTemplate(dataSource, organization)));
    } else {
      sharedBiClient
        .getReportTemplateForOrganization({ templateId: id, organization })
        .then((resp) => {
          if (!resp.success || resp.data === undefined) {
            setShowTemplateNotFound(true);
          } else {
            dispatch(setReport(resp.data));
          }
        })
        .catch(() => setShowTemplateNotFound(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, organization, dataSource, companies]);

  useEffect(() => {
    if (template?.reportType !== undefined) {
      dispatch(metaDataActions.updateReportType(template.reportType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?.reportType]);

  if (id === undefined) {
    return <Navigate to={""} />;
  }

  if (!clientInfo || showTemplateNotFound === true || companies.length === 0) {
    return (
      <TemplateNotFound backUrl={getOrganizationReportTemplatesUrl(clientInfo?.organization || organization || "")} />
    );
  }

  if (template === undefined) {
    return <InlineLoader text={locale.loading_label} />;
  }

  return (
    <Grid2 container sx={{ backgroundColor: "white", width: "100%" }}>
      {children}
      <DrillDownEntry />
    </Grid2>
  );
}

import { ActionReducerMapBuilder, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { AreaItemType, FieldConfiguration, Ordering } from "../../../shared/reporting/api/biClient.types";
import { createPivotDefaultSettings } from "../../components/builder/utils/isConfigurationValid";
import {
  calculateAndAssignSystemLabel,
  moveConditionItem,
  moveItem,
  moveMeasureItem,
  moveSortingItem,
  removeConditionFromLinked,
  removeConditionItem,
  removeMeasureItem,
  removeSortingItem,
  updateConditionConfig as updateCondConfig,
  updateConditionItem,
  updateItemV2,
  updateLinkAndValidate,
  updateMeasureItem,
  updateSortingItem,
} from "../../components/builder/common/utilities/fieldsState";
import { insertItemAt } from "../../utilities/Utilities";
import { ensureDefaultFormatIsSet } from "../../components/builder/utils/fieldsHelper";
import { ConditionField, SortField } from "../../components/builder/Types";
import { PivotFieldsActionTypes, PivotFieldState } from "./types";
import { sortSortingFields } from "../../components/builder/common/utilities/sortFieldStateHelper";

const initialState: PivotFieldState = {
  rows: [],
  columns: [],
  settings: createPivotDefaultSettings(),
  conditions: [],
  values: [],
  sorts: [],
};

const pivotFieldsSlice = createSlice({
  name: "pivotFields",
  initialState: initialState,
  reducers: {
    resetToDefaultAction: () => initialState,
    //CONDITIONS
    setConditions: (state, action: PivotFieldsActionTypes["setConditions"]) => {
      state.conditions = action.payload;
    },
    addCondition: (state, action: PivotFieldsActionTypes["addCondition"]) => {
      const condition: ConditionField = { ...action.payload.field };
      calculateAndAssignSystemLabel(condition, state.conditions);
      state.conditions = insertItemAt(state.conditions, condition, action.payload.index);
    },
    removeCondition: (state, action: PivotFieldsActionTypes["removeCondition"]) => {
      state.conditions = removeConditionItem(action.payload, state.conditions);
      state.values = removeConditionFromLinked(action.payload.config.guid, [...state.values]);
    },
    moveCondition: (state, action: PivotFieldsActionTypes["moveCondition"]) => {
      state.conditions = moveConditionItem(action.payload.field, action.payload.newIndex, state.conditions);
    },
    updateCondition: (state, action: PivotFieldsActionTypes["updateCondition"]) => {
      state.conditions = updateConditionItem(action.payload.field, action.payload.changes, state.conditions);
    },
    updateConditionConfig: (state, action: PivotFieldsActionTypes["updateConditionConfig"]) => {
      const condition = state.conditions.find((c) => c.config.guid === action.payload.field.config.guid);
      if (condition) {
        condition.config = updateCondConfig(condition.config, action.payload.changes);
      }
    },

    //MEASURES (VALUES)
    setMeasures: (state, action: PivotFieldsActionTypes["setMeasures"]) => {
      state.values = action.payload.map(ensureDefaultFormatIsSet);
    },
    addMeasure: (state, action: PivotFieldsActionTypes["addMeasure"]) => {
      state.values = insertItemAt(state.values, ensureDefaultFormatIsSet(action.payload.field), action.payload.index);
    },
    removeMeasure: (state, action: PivotFieldsActionTypes["removeMeasure"]) => {
      state.values = removeMeasureItem(action.payload, state.values);
    },
    moveMeasure: (state, action: PivotFieldsActionTypes["moveMeasure"]) => {
      state.values = moveMeasureItem(action.payload.field, action.payload.newIndex, state.values);
    },
    updateMeasure: (state, action: PivotFieldsActionTypes["updateMeasure"]) => {
      state.values = updateMeasureItem(action.payload.field, action.payload.changes, state.values);
    },
    updateMeasureConfig: (state, action: PivotFieldsActionTypes["updateMeasureConfig"]) => {
      const measure = state.values.find((c) => c.config.guid === action.payload.field.config.guid);
      if (measure) {
        const index = state.values.indexOf(measure);
        measure.config = <FieldConfiguration>{ ...measure.config, ...action.payload.changes };
        state.values[index] = measure;
      }
    },

    //ROWS
    setRows: (state, action: PivotFieldsActionTypes["setRows"]) => {
      state.rows = action.payload;
    },
    addRow: (state, action: PivotFieldsActionTypes["addRow"]) => {
      state.rows = insertItemAt(state.rows, action.payload.field, action.payload.index);
    },
    removeRow: (state, action: PivotFieldsActionTypes["removeRow"]) => {
      const rows = state.rows.filter((row) => row.meta.name !== action.payload.meta.name);
      const lastRow = rows[rows.length - 1];
      if (lastRow !== undefined) {
        lastRow.config.style = undefined;
      }
      state.rows = rows;
    },
    moveRow: (state, action: PivotFieldsActionTypes["moveRow"]) => {
      state.rows = moveItem(action.payload.field, action.payload.newIndex, state.rows);
    },
    updateRow: (state, action: PivotFieldsActionTypes["updateRow"]) => {
      state.rows = updateItemV2(action.payload.field, action.payload.changes, state.rows);
    },
    updateRowConfig: (state, action: PivotFieldsActionTypes["updateRowConfig"]) => {
      const row = state.rows.find((r) => r.meta.name === action.payload.field.meta.name);
      if (row) {
        const index = state.rows.indexOf(row);
        row.config = { ...row.config, ...action.payload.changes };
        state.rows[index] = row;
      }
    },

    //COLUMNS
    setColumns: (state, action: PivotFieldsActionTypes["setColumns"]) => {
      state.columns = action.payload;
    },
    addColumn: (state, action: PivotFieldsActionTypes["addColumn"]) => {
      state.columns = insertItemAt(state.columns, action.payload.field, action.payload.index);
    },
    removeColumn: (state, action: PivotFieldsActionTypes["removeColumn"]) => {
      state.columns = state.columns.filter((column) => column.meta.name !== action.payload.meta.name);
    },
    moveColumn: (state, action: PivotFieldsActionTypes["moveColumn"]) => {
      const { field, newIndex } = action.payload;
      const oldIndex = state.columns.findIndex((col) => col.meta.name === field.meta.name);
      if (oldIndex === -1) {
        return;
      }
      const copy = [...state.columns];
      copy.splice(oldIndex, 1);
      copy.splice(newIndex, 0, field);
      state.columns = copy;
    },
    updateColumn: (state, action: PivotFieldsActionTypes["updateColumn"]) => {
      state.columns = updateItemV2(action.payload.field, action.payload.changes, state.columns);
    },
    updateColumnConfig: (state, action: PivotFieldsActionTypes["updateColumnConfig"]) => {
      const column = state.columns.find((c) => c.config.guid === action.payload.field.config.guid);
      if (column) {
        const index = state.columns.indexOf(column);
        column.config = { ...column.config, ...action.payload.changes };
        state.columns[index] = column;
      }
    },

    //SORTS
    setSorting: (state, action: PivotFieldsActionTypes["setSorting"]) => {
      const { fields, groupMetaNames } = action.payload;
      state.sorts = sortSortingFields(fields, groupMetaNames);
    },
    addSorting: (state, action: PivotFieldsActionTypes["addSorting"]) => {
      const field = action.payload;
      const sortField: SortField = {
        meta: field.meta,
        config: {
          name: field.meta.name,
          ordering: Ordering.Ascending,
          caption: field.config.customLabel || field.meta.caption,
        },
        areaItemType: AreaItemType.SORTS,
      };
      state.sorts = sortSortingFields([...state.sorts, sortField], []);
    },
    removeSorting: (state, action: PivotFieldsActionTypes["removeSorting"]) => {
      const sorts = removeSortingItem(action.payload, state.sorts);
      state.sorts = sorts;
    },
    removeSortingByMeta: (state, action: PivotFieldsActionTypes["removeSortingByMeta"]) => {
      const item = state.sorts.find((s) => s.meta.name === action.payload.name);
      if (item === undefined) return;
      const sorts = removeSortingItem(item, state.sorts);
      state.sorts = sorts;
    },
    moveSorting: (state, action: PivotFieldsActionTypes["moveSorting"]) => {
      state.sorts = moveSortingItem(action.payload.field, action.payload.newIndex, state.sorts);
    },
    updateSorting: (state, action: PivotFieldsActionTypes["updateSorting"]) => {
      state.sorts = updateSortingItem(action.payload.field, action.payload.changes, state.sorts);
    },
    updateSortingConfig: (state, action: PivotFieldsActionTypes["updateSortingConfig"]) => {
      const { field, changes } = action.payload;
      const item = state.sorts.find((s) => s.meta.name === field.meta.name);
      if (item !== undefined) {
        item.config = { ...item.config, ...changes };
      }
    },

    //SETTINGS
    updateSettings: (state, action: PivotFieldsActionTypes["updateSettings"]) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setSettings: (state, action: PivotFieldsActionTypes["setSettings"]) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<PivotFieldState>) => {
    builder.addMatcher(
      isAnyOf(
        pivotFieldsSlice.actions.setConditions,
        pivotFieldsSlice.actions.addCondition,
        pivotFieldsSlice.actions.removeCondition,
        pivotFieldsSlice.actions.moveCondition,
        pivotFieldsSlice.actions.setMeasures,
        pivotFieldsSlice.actions.addMeasure,
        pivotFieldsSlice.actions.removeMeasure,
        pivotFieldsSlice.actions.updateMeasure,
        pivotFieldsSlice.actions.updateMeasureConfig
      ),
      (state) => {
        state.conditions = updateLinkAndValidate(state.conditions, state.values);
      }
    );
  },
});

/**
 * @deprecated Use thunks instead
 */
export const pivotFieldsActions = pivotFieldsSlice.actions;
export const pivotFieldsReducer = pivotFieldsSlice.reducer;

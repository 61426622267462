import { useState, useEffect } from "react";
import { updateClientCode } from "../../shared/api/clientApiContext";
import { ClientInfoBase } from "../../shared/api/clientTypes";
import sharedBiClient from "../../shared/reporting/api/biClient";
import { ClientInfo } from "../../shared/reporting/api/biClient.types";
import { getOrganization } from "../../shared/utilities/clientHelper";

export function useClientManagement(initialClientCode: string | undefined) {
  const [clientCode, setClientCode] = useState<string | undefined>(initialClientCode);
  const [clientInfo, setClientInfo] = useState<ClientInfo>();
  const [clients, setClients] = useState<ClientInfoBase[]>();

  useEffect(() => {
    if (clientCode !== undefined) {
      updateClientCode(clientCode);
    }
  }, [clientCode]);

  useEffect(() => {
    if (!clientCode) {
      return;
    }

    let isMounted = true;

    const fetchClients = async () => {
      const response = await sharedBiClient.getClients();
      if (response.success && response.data && isMounted) {
        const org = getOrganization(clientCode, response.data.clients);
        const info = response.data.clients.find((c) => c.clientCode === clientCode);
        if (info) {
          setClientInfo({ ...info, organization: org || "" });
        }
      }
      setClients(response?.data?.clients || []);
    };

    fetchClients();

    return () => {
      isMounted = false;
    };
  }, [clientCode]);

  return {
    clientCode,
    setClientCode,
    clientInfo,
    clients,
  };
}

import { Box, List, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DimensionField, Ordering } from "../../../../../shared/reporting/api/biClient.types";
import SelectSortFieldInput from "../../common/sorting/SelectSortFieldInput";
import { SortField } from "../../Types";
import SortFieldItem from "./SortFieldItem";
import { selectSorts, useAppDispatch } from "../../../../store/store.ts";
import { useSelector } from "react-redux";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";

interface Props {
  availableFields: DimensionField[];
}

export const SortDropsFieldsContainer = ({ availableFields }: Props) => {
  const sortingAreaValues = useSelector(selectSorts);
  const dispatch = useAppDispatch();

  const [fields, setFields] = useState<SortField[]>([]);
  const fieldsRef = useRef(fields);
  fieldsRef.current = fields;

  useEffect(() => {
    setFields(sortingAreaValues);
  }, [sortingAreaValues, setFields]);

  const onChangeSorting = useCallback(
    (field: SortField, ordering: Ordering) => {
      dispatch(fieldsStateActions.updateSortingConfig({ field, changes: { ordering } }));
    },
    [dispatch]
  );

  const onRemoveItem = useCallback(
    (field: SortField) => {
      dispatch(fieldsStateActions.removeSorting(field));
    },
    [dispatch]
  );

  const onDragMove = useCallback(
    (item: SortField, replaceItem: SortField) => {
      const values = [...fieldsRef.current];
      const fieldIndex = values.findIndex((v) => v.meta.name === item.meta.name);
      const toIndex = values.findIndex((v) => v.meta.name === replaceItem.meta.name);
      if (fieldIndex > -1) {
        values.splice(fieldIndex, 1);
        values.splice(toIndex, 0, item);
        setFields(values);
      }
    },
    [setFields]
  );

  const onEndMoveItem = useCallback(
    (field: SortField) => {
      const newIndex = fieldsRef.current.findIndex((v) => v.meta.name === field.meta.name);
      dispatch(fieldsStateActions.moveSorting({ field, newIndex }));
    },
    [dispatch]
  );

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      })}
    >
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Sort
      </Typography>
      <Stack
        sx={() => ({
          backgroundColor: "#F9F9F9",
          minHeight: "90px",
          border: "1px solid #E0E0E0",
          borderRadius: "2px",
          p: ".5rem",
          gap: ".5rem",
        })}
      >
        {fields.length > 0 && (
          <List sx={{ p: 0, display: "flex", flexDirection: "column", gap: "5px" }}>
            {fields.map((item) => {
              return (
                <SortFieldItem
                  key={item.meta.name}
                  field={item}
                  onRemoveItem={onRemoveItem}
                  onChangeSorting={onChangeSorting}
                  onDragMove={onDragMove}
                  onEndMoveItem={onEndMoveItem}
                />
              );
            })}
          </List>
        )}
        <SelectSortFieldInput availableFields={availableFields} />
      </Stack>
    </Box>
  );
};

export default SortDropsFieldsContainer;

import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../common/fields/format/MeasureFieldsContainer";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper";
import TextFieldContainer from "./TextFieldsContainer";
import DimensionFormattingContainer from "../../common/fields/format/DimensionFormattingContainer";
import { canApplyFormat } from "../../common/fields/format/dimensionFormattingHelper";
import { useSelector } from "react-redux";
import { selectTabularFields, useAppDispatch } from "../../../../store/store.ts";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";

const FormatContainer = () => {
  const dispatch = useAppDispatch();
  const fieldsAreaValues = useSelector(selectTabularFields);

  const supportingFormattingMeasures = useMemo(() => fieldsAreaValues.filter(isMeasure), [fieldsAreaValues]);
  const dimensions = useMemo(() => fieldsAreaValues.filter(isDimensionBased), [fieldsAreaValues]);
  const supportingFormattingDimensions = useMemo(() => dimensions.filter(canApplyFormat), [dimensions]);

  const handleMeasureChange = useCallback(
    (measure: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = fieldsAreaValues.find((f) => f.config.guid === measure.config.guid);
      if (isMeasure(field)) {
        dispatch(fieldsStateActions.updateFieldConfig({ field, changes }));
      }
    },
    [dispatch, fieldsAreaValues]
  );

  const handleDimensionChange = useCallback(
    (dimension: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = fieldsAreaValues.find((f) => f.config.guid === dimension.config.guid);
      if (isDimensionBased(field)) {
        dispatch(fieldsStateActions.updateFieldConfig({ field, changes }));
      }
    },
    [dispatch, fieldsAreaValues]
  );

  return (
    <ScrollableFlexContainer scrollContainerSx={{ px: "1.3rem" }}>
      <Stack gap={1}>
        <TextFieldContainer fields={dimensions} onUpdateConfig={handleDimensionChange} />
        <DimensionFormattingContainer
          dimensions={supportingFormattingDimensions}
          onUpdateConfig={handleDimensionChange}
        />
        <MeasureFieldsContainer measures={supportingFormattingMeasures} onChange={handleMeasureChange} />
      </Stack>
    </ScrollableFlexContainer>
  );
};

export default FormatContainer;

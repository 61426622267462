import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { DimensionField, FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../common/fields/format/MeasureFieldsContainer";
import TextFieldContainer from "./TextFieldsContainer";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper";
import DimensionFormattingContainer from "../../common/fields/format/DimensionFormattingContainer";
import { canApplyFormat } from "../../common/fields/format/dimensionFormattingHelper";
import { useSelector } from "react-redux";
import { selectArguments, selectValues, useAppDispatch } from "../../../../store/store";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks";

export default function FormatContainer() {
  const argumentsArea = useSelector(selectArguments);
  const valuesArea = useSelector(selectValues);
  const dispatch = useAppDispatch();

  const supportingFormattingMeasures = useMemo(() => valuesArea.filter(isMeasure), [valuesArea]);

  const supportingFormattingDimensions = useMemo(
    () => valuesArea.filter(isDimensionBased).filter(canApplyFormat),
    [valuesArea]
  );

  const updateMeasureItemConfig = useCallback(
    (field: ReportField, changes: Partial<FieldConfiguration>) => {
      dispatch(fieldsStateActions.updateMeasureConfig({ field, changes }));
    },
    [dispatch]
  );

  const updateArgumentItemConfig = useCallback(
    (field: DimensionField, changes: Partial<FieldConfiguration>) => {
      dispatch(fieldsStateActions.updateArgumentConfig({ field, changes }));
    },
    [dispatch]
  );

  const handleMeasureChanged = useCallback(
    (measure: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = valuesArea.find((f) => f.config.guid === measure.config.guid);
      if (field !== undefined) {
        updateMeasureItemConfig(field, changes);
      }
    },
    [updateMeasureItemConfig, valuesArea]
  );

  const handleDimensionChange = useCallback(
    (dimension: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = valuesArea.find((f) => f.config.guid === dimension.config.guid);
      if (isDimensionBased(field)) {
        updateMeasureItemConfig(field, changes);
      }
    },
    [updateMeasureItemConfig, valuesArea]
  );

  return (
    <ScrollableFlexContainer>
      <Stack gap={1} mx={"1.3rem"}>
        <TextFieldContainer fields={argumentsArea} onUpdateConfig={updateArgumentItemConfig} />
        <DimensionFormattingContainer
          dimensions={supportingFormattingDimensions}
          onUpdateConfig={handleDimensionChange}
        />
        <MeasureFieldsContainer measures={supportingFormattingMeasures} onChange={handleMeasureChanged} />
      </Stack>
    </ScrollableFlexContainer>
  );
}

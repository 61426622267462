import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  AreaItemType,
  BasicLinearSettings,
  ChartStyleSettings,
  LinearSeriesSettings,
  Ordering,
  ReportField,
  ReportType,
} from "../../../shared/reporting/api/biClient.types";
import { ChartFieldsActionTypes, ChartFieldState, PivotFieldsActionTypes } from "./types";
import {
  calculateAndAssignSystemLabel,
  moveConditionItem,
  moveMeasureItem,
  moveSortingItem,
  removeConditionFromLinked,
  removeConditionItem,
  removeMeasureItem,
  removeSortingItem,
  updateConditionConfig,
  updateConditionItem,
  updateLinkAndValidate,
  updateSortingItem,
} from "../../components/builder/common/utilities/fieldsState";
import { removeSerie, validateStateByReportType } from "../../components/builder/chart/utilities/seriesUtils";
import {
  getDefaultSerieType,
  getLinearSettings,
} from "../../components/builder/chart/contexts/FieldsStateContext.types";
import updateValueAxis from "../../components/builder/chart/utilities/updateValueAxis";
import { ConditionField, SortField } from "../../components/builder/Types";
import { isDimensionBased } from "../../components/builder/utils/fieldsHelper";
import { insertItemAt } from "../../utilities/Utilities";
import { sortSortingFields } from "../../components/builder/common/utilities/sortFieldStateHelper";

const initialState: ChartFieldState = {
  arguments: [],
  sorts: [],
  values: [],
  conditions: [],
  settings: {} as ChartStyleSettings,
};

const getInitialState = (reportType: ReportType) => {
  return {
    ...initialState,
    settings: { type: reportType } as ChartStyleSettings,
  };
};

const chartStateSlice = createSlice({
  name: "chartFields",
  initialState: initialState,
  reducers: {
    resetToDefaultAction: (_, action: ChartFieldsActionTypes["resetToDefaultAction"]) =>
      getInitialState(action.payload ?? ReportType.BarChart),
    setConditions: (state, action: ChartFieldsActionTypes["setConditions"]) => {
      state.conditions = action.payload;
    },
    addCondition: (state, action: ChartFieldsActionTypes["addCondition"]) => {
      const condition: ConditionField = { ...action.payload.field };
      calculateAndAssignSystemLabel(condition, state.conditions);
      state.conditions = insertItemAt(state.conditions, condition, action.payload.index);
    },
    removeCondition: (state, action: ChartFieldsActionTypes["removeCondition"]) => {
      const conditions = removeConditionItem(action.payload, state.conditions);
      removeConditionFromLinked(action.payload.config.guid, state.values);
      state.conditions = conditions;
    },
    moveCondition: (state, action: ChartFieldsActionTypes["moveCondition"]) => {
      state.conditions = moveConditionItem(action.payload.field, action.payload.newIndex, state.conditions);
    },
    updateCondition: (state, action: ChartFieldsActionTypes["updateCondition"]) => {
      state.conditions = updateConditionItem(action.payload.field, action.payload.changes, state.conditions);
    },
    updateConditionConfig: (state, action: ChartFieldsActionTypes["updateConditionConfig"]) => {
      const condition = state.conditions.find((c) => c.config.guid === action.payload.field.config.guid);
      if (condition) {
        condition.config = updateConditionConfig(condition.config, action.payload.changes);
      }
    },
    setArguments: (state, action: ChartFieldsActionTypes["setArguments"]) => {
      state.arguments = action.payload;
    },
    addArgument: (state, action: ChartFieldsActionTypes["addArgument"]) => {
      state.arguments = insertItemAt(state.arguments, action.payload.field, action.payload.index);
    },
    removeArgument: (state, action: ChartFieldsActionTypes["removeArgument"]) => {
      const fieldIndex = state.arguments.findIndex((a) => a.config.name === action.payload.config.name);
      if (fieldIndex > -1) {
        const copy = [...state.arguments];
        copy.splice(fieldIndex, 1);
        state.arguments = copy;
      }
    },
    moveArgument: (state, action: ChartFieldsActionTypes["moveArgument"]) => {
      const fieldIndex = state.arguments.findIndex((a) => a.config.name === action.payload.field.config.name);
      if (fieldIndex > -1) {
        const copy = [...state.arguments];
        copy.splice(fieldIndex, 1);
        copy.splice(action.payload.newIndex, 0, action.payload.field);
        state.arguments = copy;
      }
    },
    updateArgument: (state, action: ChartFieldsActionTypes["updateArgument"]) => {
      const index = state.arguments.findIndex((a) => a.config.name === action.payload.field.config.name);
      const updatedField = { ...action.payload.field, ...action.payload.changes };
      if (index > -1) {
        state.arguments[index] = updatedField;
      }
    },
    updateArgumentConfig: (state, action: ChartFieldsActionTypes["updateArgumentConfig"]) => {
      const argument = state.arguments.find((c) => c.config.name === action.payload.field.config.name);
      if (isDimensionBased(argument)) {
        argument.config = { ...argument.config, ...action.payload.changes };
      }
    },
    //Measures
    setMeasures: (state, action: ChartFieldsActionTypes["setMeasures"]) => {
      state.values = action.payload;
    },
    addMeasure: (state, action: ChartFieldsActionTypes["addMeasure"]) => {
      state.values = insertItemAt(state.values, action.payload.field, action.payload.index);
    },
    removeMeasure: (state, action: ChartFieldsActionTypes["removeMeasure"]) => {
      state.values = removeMeasureItem(action.payload, state.values);
    },
    moveMeasure: (state, action: ChartFieldsActionTypes["moveMeasure"]) => {
      state.values = moveMeasureItem(action.payload.field, action.payload.newIndex, state.values);
    },
    updateMeasure: (state, action: ChartFieldsActionTypes["updateMeasure"]) => {
      const field = state.values.find((v) => v.config.guid === action.payload.field.config.guid);
      if (field !== undefined) {
        const index = state.values.indexOf(field);
        if (index > -1) {
          const updatedField = { ...field, ...action.payload.changes } as ReportField;
          state.values[index] = updatedField;
        }
      }
    },
    updateMeasureConfig: (state, action: ChartFieldsActionTypes["updateMeasureConfig"]) => {
      const measure = state.values.find((c) => c.config.guid === action.payload.field.config.guid);
      if (measure) {
        measure.config = { ...measure.config, ...action.payload.changes };
      }
    },
    setSettings: (state, action: ChartFieldsActionTypes["setSettings"]) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    updateSettings: (state, action: ChartFieldsActionTypes["updateSettings"]) => {
      state.settings = { ...state.settings, ...action.payload };
      state.settings = validateStateByReportType(state);
    },
    updateReportTypeSettings: (state, action: ChartFieldsActionTypes["updateReportTypeSettings"]) => {
      state.settings.type = action.payload;
      state.settings = validateStateByReportType(state);
    },
    updateLegendSettings: (state, action: ChartFieldsActionTypes["updateLegendSettings"]) => {
      state.settings.legend = { ...state.settings.legend, ...action.payload };
    },
    updateAxisSettings: (state, action: ChartFieldsActionTypes["updateAxisSettings"]) => {
      const settings = state.settings as BasicLinearSettings;
      settings.argumentAxis = { ...settings.argumentAxis, ...action.payload };
    },
    updateAxisArgumentSettings: (state, action: ChartFieldsActionTypes["updateAxisArgumentSettings"]) => {
      const settings = state.settings as BasicLinearSettings;
      settings.argumentAxis = { ...settings.argumentAxis, ...action.payload };
    },
    updateAxisValueSettings: (state, action: ChartFieldsActionTypes["updateAxisValueSettings"]) => {
      const settings = state.settings as BasicLinearSettings;
      let item = settings.valueAxis?.find((va) => va.name === action.payload.name);
      if (settings.valueAxis !== undefined && item !== undefined) {
        const index = settings.valueAxis?.findIndex((va) => va.name === item?.name);
        if (index !== undefined && index > -1) {
          item = { ...item, ...action.payload.changes };
          settings.valueAxis[index] = item;
        }
      }
    },
    addSeries: (state, action: ChartFieldsActionTypes["addSeries"]) => {
      const serie = state.settings.series?.find((s) => s.name === action.payload);
      if (state.settings.series === undefined) {
        state.settings.series = [];
      }
      if (serie === undefined) {
        state.settings.series.push({ name: action.payload, serieType: getDefaultSerieType(state.settings.type) });
      }
      const settings = getLinearSettings(state.settings);
      if (settings) {
        settings.valueAxis = updateValueAxis(settings);
      }
    },
    removeSeries: (state, action: ChartFieldsActionTypes["removeSeries"]) => {
      removeSerie(state, action.payload);
      const settings = getLinearSettings(state.settings);
      if (settings) {
        settings.valueAxis = updateValueAxis(settings);
      }
    },
    updateSeriesSettings: (state, action: ChartFieldsActionTypes["updateSeriesSettings"]) => {
      if (state.settings.series !== undefined) {
        const series = state.settings.series;
        const serie = series.find((s) => s.name === action.payload.name);
        if (serie !== undefined) {
          const copy = { ...serie, ...action.payload.changes };
          series[series.indexOf(serie)] = copy;
        }
        const settings = getLinearSettings(state.settings);
        if (settings) {
          settings.valueAxis = updateValueAxis(settings);
        }
      }
    },
    updateSerieValueAxisSettings: (state, action: ChartFieldsActionTypes["updateSerieValueAxisSettings"]) => {
      if (state.settings.series !== undefined) {
        const series = state.settings.series;
        const serie = series.find((s) => s.name === action.payload.name) as LinearSeriesSettings;
        if (serie !== undefined) {
          serie.valueAxis = action.payload.axis;
          const settings = getLinearSettings(state.settings);
          if (settings) {
            settings.valueAxis = updateValueAxis(settings);
          }
        }
      }
    },
    setSorting: (state, action: ChartFieldsActionTypes["setSorting"]) => {
      state.sorts = sortSortingFields([...action.payload.fields], action.payload.groupMetaNames);
    },
    addSorting: (state, action: ChartFieldsActionTypes["addSorting"]) => {
      const { meta, config } = action.payload;
      const conditionField: SortField = {
        meta: meta,
        config: {
          name: meta.name,
          ordering: Ordering.Ascending,
          caption: config.customLabel || meta.caption,
        },
        areaItemType: AreaItemType.SORTS,
      };
      state.sorts = sortSortingFields([...state.sorts, conditionField], []);
    },
    removeSorting: (state, action: ChartFieldsActionTypes["removeSorting"]) => {
      state.sorts = removeSortingItem(action.payload, state.sorts);
    },
    removeSortingByMeta: (state, action: ChartFieldsActionTypes["removeSortingByMeta"]) => {
      const item = state.sorts.find((s) => s.meta.name === action.payload.name);
      if (item === undefined) return;
      state.sorts = removeSortingItem(item, state.sorts);
    },
    moveSorting: (state, action: PivotFieldsActionTypes["moveSorting"]) => {
      state.sorts = moveSortingItem(action.payload.field, action.payload.newIndex, state.sorts);
    },
    updateSorting: (state, action: PivotFieldsActionTypes["updateSorting"]) => {
      state.sorts = updateSortingItem(action.payload.field, action.payload.changes, state.sorts);
    },
    updateSortingConfig: (state, action: PivotFieldsActionTypes["updateSortingConfig"]) => {
      const { field, changes } = action.payload;
      const item = state.sorts.find((s) => s.meta.name === field.meta.name);
      if (item !== undefined) {
        item.config = { ...item.config, ...changes };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        chartStateSlice.actions.setConditions,
        chartStateSlice.actions.moveCondition,
        chartStateSlice.actions.removeCondition,
        chartStateSlice.actions.moveCondition,
        chartStateSlice.actions.removeMeasure,
        chartStateSlice.actions.updateMeasureConfig,
        chartStateSlice.actions.setMeasures
      ),
      (state) => {
        state.conditions = updateLinkAndValidate(state.conditions, state.values);
      }
    );
  },
});

/**
 * @deprecated Use thunks instead
 */
export const chartFieldsActions = chartStateSlice.actions;
export const chartFieldsReducer = chartStateSlice.reducer;

import { Grid2 } from "@mui/material";
import { GridRowGroupingModel, GridSortModel } from "@mui/x-data-grid-premium";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  AreaItemType,
  Ordering,
  ReportField,
  TabularDataCell,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import useResizeObserver from "../../../../hooks/resizeObserver";
import { selectPreviewMode } from "../../../../store/currentReportSlice";
import { drillDownActions } from "../../../../store/drilldownSlice";
import PreviewComponentAlert from "../../../common/PreviewComponentAlert";
import BiErrorCodeComponent from "../../common/bi-error/BiErrorCodeComponent";
import { PreviewDefaultSize } from "../../common/constants/const";
import { getGroupMetaNames } from "../../common/utilities/fieldsState";
import ValidationState from "../../common/ValidationState";
import { SortField } from "../../Types";
import { isAggregationBased, isDimensionBased, isMeasure } from "../../utils/fieldsHelper";
import { PreviewGridStateType } from "../hooks/useGridStateBuilder";
import TabularGrid from "./TabularGrid";
import { getGroupings } from "./utils/getGrouping";
import { getSorting } from "./utils/getSorting";
import { selectSorts, selectTabularFields, selectTabularGrouping, useAppDispatch } from "../../../../store/store.ts";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";

interface Props {
  state: PreviewGridStateType;
}

export default function PreviewComponent({ state }: Props) {
  const dispatch = useAppDispatch();
  const fieldsAreaValues = useSelector(selectTabularFields);
  const sortingAreaValues = useSelector(selectSorts);
  const groupAreaValues = useSelector(selectTabularGrouping);

  const previewMode = useSelector(selectPreviewMode);

  const divRef = React.createRef<HTMLDivElement>();
  const size = useResizeObserver(divRef, undefined, previewMode === "maximize" ? undefined : PreviewDefaultSize);

  const fieldsRef = React.useRef(fieldsAreaValues);
  fieldsRef.current = fieldsAreaValues;
  const sortsRef = React.useRef(sortingAreaValues);
  sortsRef.current = sortingAreaValues;
  const groupingRef = React.useRef(groupAreaValues);
  groupingRef.current = groupAreaValues;

  const hasConfigurationProvided = React.useMemo(() => fieldsAreaValues.length > 0, [fieldsAreaValues]);
  const sortingModel = React.useMemo(
    () => getSorting(sortingAreaValues, fieldsAreaValues),
    [sortingAreaValues, fieldsAreaValues]
  );

  const handleDrillDown = React.useCallback(
    (rowData: Record<string, TabularDataCell> | undefined, columnName: string) => {
      if (!rowData) return;
      const cell = rowData[columnName];
      if (cell?.drillDownId === undefined) return;
      const field = fieldsRef.current.find((f) => f.config.guid === columnName);
      if (!isMeasure(field)) return;

      dispatch(
        drillDownActions.add({ id: generateGuid(), measure: field, cell, onlyLedger: false, chartOfAccounts: true })
      );
    },
    [dispatch]
  );

  const onColumnsSortingChange = React.useCallback(
    (newSorting: GridSortModel) => {
      const sortFields = rebuildSortingFields(newSorting, fieldsRef.current, sortsRef.current);
      if (sortFields.length === 0) return;
      dispatch(
        fieldsStateActions.setSorting({
          fields: sortFields,
          groupMetaNames: getGroupMetaNames(groupingRef.current, fieldsRef.current),
        })
      );
    },
    [dispatch]
  );

  const handleGroupingModelChange = React.useCallback(
    (model: GridRowGroupingModel) => {
      const { groupIdsToAdd, groupsToDelete } = getGroupings(model, groupAreaValues);

      groupIdsToAdd.forEach((groupId) => {
        dispatch(fieldsStateActions.addGroup({ name: groupId }));
      });

      groupsToDelete.forEach((group) => {
        dispatch(fieldsStateActions.removeGroup(group));
      });
    },
    [dispatch, groupAreaValues]
  );

  const updateFieldsOrder = useCallback(
    (names: string[]) => {
      dispatch(fieldsStateActions.updateFieldsOrder(names));
    },
    [dispatch]
  );

  if (!hasConfigurationProvided) return <></>;

  return (
    <BiErrorCodeComponent errorMessage={state.error}>
      <PreviewComponentAlert
        error={state.error}
        failedMeasures={state.failedMeasures}
        failedGroupTotalMeasures={state.failedGroupTotalMeasures}
      />
      {state.configurationValidity.valid && (
        <Grid2
          ref={divRef}
          container
          sx={{ flex: 1, justifyContent: "center", width: "100%" }}
          onDrop={stopEventPropagation} //This workaround is related to this bug: https://github.com/react-dnd/react-dnd/issues/3491
        >
          <TabularGrid
            rows={state.rows}
            fields={fieldsAreaValues}
            columns={state.columns}
            grandTotals={state.grandTotals}
            groupTotals={state.groupTotals}
            groupingFields={groupAreaValues}
            sortingModel={sortingModel}
            loading={state.loading}
            size={size}
            groupTotalsLoading={state.groupTotalsLoading}
            onGroupingChanged={handleGroupingModelChange}
            onColumnsSortingChanged={onColumnsSortingChange}
            onColumnsOrderChanged={updateFieldsOrder}
            onDrillDown={handleDrillDown}
          />
        </Grid2>
      )}
      <ValidationState state={state.configurationValidity} measures={getAggregationBased(fieldsAreaValues)} />
    </BiErrorCodeComponent>
  );
}

function rebuildSortingFields(newSorting: GridSortModel, fields: ReportField[], currentSorts: SortField[]) {
  const currentSortCopy = cloneDeep(currentSorts);

  newSorting.forEach((sortItem) => {
    const field = fields.find((f) => f.config.guid === sortItem.field);
    if (isDimensionBased(field)) {
      const currentSort = currentSortCopy.find((s) => s.meta.name === field.meta.name);
      const ordering = getOrdering(sortItem.sort);

      if (currentSort) {
        currentSort.config.ordering = ordering;
      } else {
        currentSortCopy.push({
          meta: field.meta,
          config: {
            name: field.meta.name,
            ordering: ordering,
            caption: field.config.customLabel || field.meta.caption,
            isGroupField: false,
          },
          areaItemType: AreaItemType.SORTS,
        });
      }
    }
  });

  return currentSortCopy;
}

function getAggregationBased(fields: ReportField[]): ReportField[] {
  return fields.filter(isAggregationBased);
}

function stopEventPropagation(e: React.SyntheticEvent) {
  e.stopPropagation();
}

function getOrdering(sort: "asc" | "desc" | null | undefined): Ordering {
  return sort === "asc" ? Ordering.Ascending : Ordering.Descending;
}

import objectHash from "object-hash";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PivotColumnResponse } from "../../../../../api/biApi.types";
import { selectRefreshToken } from "../../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../../store/metaDataSlice";
import { DelayObservable } from "../../../../../utilities/Observers";
import { isPivotConfigurationValid } from "../../../utils/isConfigurationValid";
import { getAllFilters } from "../utilities/getAllConditions";
import useDataLoadingViewer from "./useDataLoadingViewer";
import { selectValues, selectRows, selectColumns, selectConditions } from "../../../../../store/store";

export default function useGridStateViewer() {
  const dimensions = useSelector(selectDimensions);

  const [columns, setColumns] = useState<PivotColumnResponse[]>([]);

  const conditionsAreaValues = useSelector(selectConditions);
  const valuesAreaValues = useSelector(selectValues);
  const rowsAreaValues = useSelector(selectRows);
  const columnsAreaValues = useSelector(selectColumns);
  const { calculate, error, calculating, pivot, failedMeasures } = useDataLoadingViewer();
  const refreshToken = useSelector(selectRefreshToken);

  const conditionsFiltersHash = useMemo(
    () => objectHash(getAllFilters(conditionsAreaValues, valuesAreaValues)),
    [conditionsAreaValues, valuesAreaValues]
  );

  useLayoutEffect(() => {
    const subscription = DelayObservable.subscribe(() => calculate());
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configurationValid = useMemo(() => {
    const dimensionFields = rowsAreaValues.concat(columnsAreaValues).concat(rowsAreaValues);
    return isPivotConfigurationValid(conditionsAreaValues, dimensionFields, valuesAreaValues, dimensions);
  }, [columnsAreaValues, conditionsAreaValues, dimensions, rowsAreaValues, valuesAreaValues]);

  useEffect(() => {
    if (!configurationValid.valid) return;
    DelayObservable.next(Math.random().toString());
  }, [conditionsFiltersHash, refreshToken, configurationValid.valid]);

  useEffect(() => {
    if (!pivot) {
      setColumns([]);
    } else {
      const gridColumns = pivot.columns;
      setColumns(gridColumns);
    }
  }, [pivot]);

  return { pivot, columns, error, calculating, configurationValid, failedMeasures };
}

import { Typography } from "@mui/material";
import React from "react";
import SwitchField from "../../../common/SwitchField";
import { isDimensionBased } from "../../utils/fieldsHelper";
import ExpandeGroup from "./ExpandeGroup";
import StyleItem from "./StyleItem";
import { useSelector } from "react-redux";
import { selectTabularFields, selectTabularGrouping, useAppDispatch } from "../../../../store/store.ts";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";

export default function GroupSettings() {
  const fieldsAreaValues = useSelector(selectTabularFields);
  const groupAreaValues = useSelector(selectTabularGrouping);
  const dispatch = useAppDispatch();

  const grouping = React.useMemo(() => groupAreaValues, [groupAreaValues]);
  const dimensionFields = React.useMemo(() => fieldsAreaValues.filter(isDimensionBased), [fieldsAreaValues]);

  return (
    <>
      {grouping.length > 0 && (
        <ExpandeGroup title="Grouped Columns">
          {grouping.map((group, index) => (
            <StyleItem key={group.name} showBorder={grouping.length > 1 && index < grouping.length - 1}>
              <Typography variant="subtitle2" fontWeight={500} sx={{ color: "text.primary" }} pb={1}>
                {dimensionFields.find((f) => f.config.guid === group.name)?.meta.caption}
              </Typography>
              <SwitchField
                label="Show Subtotals"
                checked={!group.hideSummaries}
                onChange={(hideSummaries) =>
                  dispatch(
                    fieldsStateActions.updateGroup({
                      group,
                      changes: { hideSummaries: !hideSummaries },
                    })
                  )
                }
                typographyProps={{ sx: { color: "text.secondary" } }}
              />
            </StyleItem>
          ))}
        </ExpandeGroup>
      )}
    </>
  );
}

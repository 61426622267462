import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useCallback } from "react";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton.tsx";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader.tsx";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis.tsx";
import useFetch from "../../../../../../shared/hooks/useFetch.ts";
import { MeasureDescriptor } from "../../../../../../shared/reporting/api/biClient.types.ts";
import biClient from "../../../../../api/biApi.ts";
import { useLocalization } from "../../../../../hooks/useLocalization.tsx";
import UsedByReports from "./UsedByReports.tsx";

interface Props {
  measure: MeasureDescriptor;
  onClose: () => void;
}

const UsedByReportsDialog = ({ measure, onClose }: Props) => {
  const { custom_measure: locale } = useLocalization();
  const getCustomMeasureReportsUsage = useCallback(
    () => biClient.getUsedByReports(measure.id ?? measure.name),
    [measure.id, measure.name]
  );
  const [reportsUsageResponse, fetchError, { isFetching }] = useFetch(getCustomMeasureReportsUsage);

  return (
    <Dialog open fullWidth slotProps={{ paper: { sx: { maxWidth: 514, minHeight: 205, height: "auto" } } }}>
      <DialogTitle>
        Custom Measure Where-Used List
        <TypographyTooltipEllipsis text={measure.caption} typographyProps={{ color: "secondary" }} />
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />
      {isFetching && !fetchError && (
        <Box px={3} py={2.5}>
          <InlineLoader />
        </Box>
      )}
      {fetchError && !isFetching && (
        <Box px={3} py={2.5}>
          <Alert severity="error" sx={{ width: "100%" }}>
            {locale.fetch_reports_error}
          </Alert>
        </Box>
      )}
      {!fetchError && !isFetching && reportsUsageResponse && (
        <DialogContent>
          <UsedByReports reports={reportsUsageResponse.reports} minRows={3} maxRows={10} />
        </DialogContent>
      )}
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsedByReportsDialog;

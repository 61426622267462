import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChartFieldsActionTypes } from "../fieldState/types";
import {
  DimensionField,
  FieldConfiguration,
  ReportType,
  LegendSettings,
  AxisSettings,
  SeriesSettings,
  ValueAxisType,
  Report,
} from "../../../shared/reporting/api/biClient.types";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk.ts";
import { chartFieldsActions } from "../fieldState/chartStateSlice.ts";
import { SortField } from "../../components/builder/Types.ts";

const getSortField = (field: DimensionField, sorts: SortField[]) => {
  return sorts.find((s) => s.meta.name === field.meta.name);
};

const createChartAction = <T extends keyof ChartFieldsActionTypes>(
  type: T,
  payload: ChartFieldsActionTypes[T]["payload"]
) => ({
  type: `chartFields/${type}`,
  payload,
});

// Arguments
const setArguments = createAsyncThunk<void, DimensionField[]>(
  "chartFields/setArguments",
  (args: DimensionField[], { dispatch }) => {
    dispatch(createChartAction("setArguments", args));
  }
);

const addArgument = createAsyncThunk<void, { field: DimensionField; index: number }>(
  "chartFields/addArgument",
  (payload: { field: DimensionField; index: number }, { dispatch }) => {
    dispatch(createChartAction("addArgument", payload));
  }
);

const removeArgument = createTypedAsyncThunk<void, DimensionField>(
  "chartFields/removeArgument",
  (field: DimensionField, { dispatch, getState }) => {
    dispatch(createChartAction("removeArgument", field));

    const sortingField = getSortField(field, getState().fieldsState.chartFieldsReducer.sorts);
    if (sortingField) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        chartFieldsActions.removeSortingByMeta(sortingField.meta)
      );
    }
  }
);

const moveArgument = createAsyncThunk<void, { field: DimensionField; newIndex: number }>(
  "chartFields/moveArgument",
  (payload: { field: DimensionField; newIndex: number }, { dispatch }) => {
    dispatch(createChartAction("moveArgument", payload));
  }
);

const updateArgument = createAsyncThunk<void, { field: DimensionField; changes: Partial<DimensionField> }>(
  "chartFields/updateArgument",
  (payload: { field: DimensionField; changes: Partial<DimensionField> }, { dispatch }) => {
    dispatch(createChartAction("updateArgument", payload));
  }
);

const updateArgumentConfig = createTypedAsyncThunk<
  void,
  {
    field: DimensionField;
    changes: Partial<FieldConfiguration>;
  }
>(
  "chartFields/updateArgumentConfig",
  (payload: { field: DimensionField; changes: Partial<FieldConfiguration> }, { dispatch, getState }) => {
    dispatch(createChartAction("updateArgumentConfig", payload));

    const sortingField = getSortField(payload.field, getState().fieldsState.chartFieldsReducer.sorts);
    if (sortingField && payload.changes.customLabel !== payload.field.config?.customLabel) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        chartFieldsActions.updateSortingConfig({
          field: sortingField,
          changes: { caption: payload.changes.customLabel },
        })
      );
    }
  }
);

// Settings
const updateReportTypeSettings = createAsyncThunk<void, ReportType>(
  "chartFields/updateReportTypeSettings",
  (reportType: ReportType, { dispatch }) => {
    dispatch(createChartAction("updateReportTypeSettings", reportType));
  }
);

const updateLegendSettings = createAsyncThunk<void, Partial<LegendSettings>>(
  "chartFields/updateLegendSettings",
  (settings: Partial<LegendSettings>, { dispatch }) => {
    dispatch(createChartAction("updateLegendSettings", settings));
  }
);

const updateAxisSettings = createAsyncThunk<void, Partial<AxisSettings>>(
  "chartFields/updateAxisSettings",
  (settings: Partial<AxisSettings>, { dispatch }) => {
    dispatch(createChartAction("updateAxisSettings", settings));
  }
);

const updateAxisArgumentSettings = createAsyncThunk<void, Partial<AxisSettings>>(
  "chartFields/updateAxisArgumentSettings",
  (settings: Partial<AxisSettings>, { dispatch }) => {
    dispatch(createChartAction("updateAxisArgumentSettings", settings));
  }
);

const updateAxisValueSettings = createAsyncThunk<void, { name: string; changes: Partial<AxisSettings> }>(
  "chartFields/updateAxisValueSettings",
  (payload: { name: string; changes: Partial<AxisSettings> }, { dispatch }) => {
    dispatch(createChartAction("updateAxisValueSettings", payload));
  }
);

const addSeries = createAsyncThunk<void, string>("chartFields/addSeries", (name: string, { dispatch }) => {
  dispatch(createChartAction("addSeries", name));
});

const removeSeries = createAsyncThunk<void, string>("chartFields/removeSeries", (name: string, { dispatch }) => {
  dispatch(createChartAction("removeSeries", name));
});

const updateSeriesSettings = createAsyncThunk<void, { name: string; changes: Partial<SeriesSettings> }>(
  "chartFields/updateSeriesSettings",
  (payload: { name: string; changes: Partial<SeriesSettings> }, { dispatch }) => {
    dispatch(createChartAction("updateSeriesSettings", payload));
  }
);

const updateSerieValueAxisSettings = createAsyncThunk<void, { name: string; axis: Partial<ValueAxisType> }>(
  "chartFields/updateSerieValueAxisSettings",
  (payload: { name: string; axis: Partial<ValueAxisType> }, { dispatch }) => {
    dispatch(createChartAction("updateSerieValueAxisSettings", payload));
  }
);

const handleUpdateReportType = createTypedAsyncThunk<void, Report>(
  "chartFields/updateReportType",
  (report: Report, { dispatch }) => {
    if (
      report.reportType === ReportType.PieChart ||
      report.reportType === ReportType.BarChart ||
      report.reportType === ReportType.LineChart ||
      report.reportType === ReportType.AreaChart ||
      report.reportType === ReportType.DoughnutChart
    ) {
      dispatch(updateReportTypeSettings(report.reportType));
    } else {
      dispatch(updateReportTypeSettings(ReportType.BarChart));
    }
  }
);

export const chartFieldsStateThunks = {
  // Arguments
  setArguments,
  addArgument,
  removeArgument,
  moveArgument,
  updateArgument,
  updateArgumentConfig,
  // Settings
  updateReportTypeSettings,
  updateLegendSettings,
  updateAxisSettings,
  updateAxisArgumentSettings,
  updateAxisValueSettings,
  addSeries,
  removeSeries,
  updateSeriesSettings,
  updateSerieValueAxisSettings,
  handleUpdateReportType,
};

import objectHash from "object-hash";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectRefreshToken } from "../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { DelayObservable } from "../../../../utilities/Observers";
import { generalFieldHash } from "../../pivot/preview/hooks/useGridStateBuilder";
import { getAllFilters } from "../../pivot/preview/utilities/getAllConditions";
import getTrackedMeasureProperties from "../../utils/getTrackedMeasureProperties";
import { isPivotConfigurationValid } from "../../utils/isConfigurationValid";
import useDataLoadingBuilder from "./useDataLoadingBuilder";
import { selectConditions, selectArguments, selectValues, selectSorts } from "../../../../store/store";

export type PreviewChartStateType = ReturnType<typeof useChartStateBuilder>;

export default function useChartStateBuilder() {
  const dimensions = useSelector(selectDimensions);
  const conditionsAreaValues = useSelector(selectConditions);
  const argumentsAreaValues = useSelector(selectArguments);
  const valuesAreaValues = useSelector(selectValues);
  const sortingAreaValues = useSelector(selectSorts);

  const { data, calculating, error, calculate, failedMeasures, handleInvalidConfiguration } = useDataLoadingBuilder();
  const refreshToken = useSelector(selectRefreshToken);

  const configurationValidity = useMemo(() => {
    return isPivotConfigurationValid(conditionsAreaValues, argumentsAreaValues, valuesAreaValues, dimensions);
  }, [argumentsAreaValues, conditionsAreaValues, dimensions, valuesAreaValues]);

  const fieldsConfigured = useMemo(
    () => argumentsAreaValues.length > 0 && valuesAreaValues.length > 0,
    [argumentsAreaValues, valuesAreaValues]
  );

  const measuresHash = useMemo(() => objectHash(getTrackedMeasureProperties(valuesAreaValues)), [valuesAreaValues]);

  const conditionsFiltersHash = useMemo(
    () => objectHash(getAllFilters(conditionsAreaValues, valuesAreaValues)),
    [conditionsAreaValues, valuesAreaValues]
  );

  const argumentsAreaHash = useMemo(() => generalFieldHash(argumentsAreaValues), [argumentsAreaValues]);
  const sortingAreaHash = useMemo(() => objectHash(sortingAreaValues), [sortingAreaValues]);

  useLayoutEffect(() => {
    const subscription = DelayObservable.subscribe(calculate);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configurationValidity.valid && fieldsConfigured) {
      DelayObservable.next(refreshToken);
    } else {
      handleInvalidConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conditionsFiltersHash,
    argumentsAreaHash,
    measuresHash,
    refreshToken,
    fieldsConfigured,
    sortingAreaHash,
    configurationValidity.valid,
  ]);

  return { data: data?.result, calculating, error, fieldsConfigured, configurationValidity, failedMeasures };
}

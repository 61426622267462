import PivotContainer from "./PivotContainer";
import PivotViewer from "./PivotViewer";

export default function PivotViewerContainer() {
  return (
    <PivotContainer>
      <PivotViewer />
    </PivotContainer>
  );
}

import ChartContainer from "./ChartContainer";
import ChartViewer from "./ChartViewer";

export default function ChartViewerContainer() {
  return (
    <ChartContainer>
      <ChartViewer />
    </ChartContainer>
  );
}

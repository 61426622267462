import {
  AreaItemType,
  ConditionConfiguration,
  DimensionDescriptor,
  ReportField,
  SortConfiguration,
} from "../../../../../shared/reporting/api/biClient.types";
import { createFieldId } from "../../common/utilities/createFieldId";
import { ConditionField, SortField } from "../../Types";
import { getMeasureCalculateByField } from "../isConfigurationValid";

export const configurationToConditions = (
  configConditions: ConditionConfiguration[],
  dimensions: DimensionDescriptor[]
): ConditionField[] => {
  try {
    const conditions = configConditions
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.filter.dimensionName);
        if (dimension) {
          const copied = { config, meta: dimension, areaItemType: AreaItemType.CONDITIONS } as ConditionField;
          // backward compatibility with saved old reports
          if (copied.config.guid === undefined) {
            copied.config.guid = createFieldId(dimension.name);
          }
          return copied;
        } else {
          //eslint-disable-next-line no-console
          console.log(`Field ${config.filter.dimensionName} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is ConditionField => c !== undefined);
    return conditions;
  } catch {
    return [];
  }
};

export const valueToConfiguration = (value: ReportField) => {
  return {
    ...value.config,
    calculateByField: getMeasureCalculateByField(value),
  };
};

export const configurationToSorts = (configSorts: SortConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const sorts = configSorts
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: SortField = {
            config,
            meta: dimension,
            areaItemType: AreaItemType.SORTS,
          };
          return copied;
        } else {
          //eslint-disable-next-line no-console
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c) => c !== undefined);
    return sorts;
  } catch {
    return [];
  }
};

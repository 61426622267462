import React from "react";
import { ValueAxis, ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";
import TitleItem from "../TitleItem";
import { useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

interface Props {
  settings: ChartStyleSettings;
  axis: ValueAxis;
}

export default function Title({ settings, axis }: Props) {
  const title = React.useMemo(() => valueAxisTitle(settings, axis.name), [settings, axis.name]);
  const dispatch = useAppDispatch();

  return (
    <TitleItem
      title={title}
      placeholder="Set Axis Title..."
      onUpdate={(title) =>
        dispatch(fieldsStateActions.updateAxisValueSettings({ name: axis.name, changes: { title } }))
      }
    />
  );
}

export function valueAxisTitle(settings: ChartStyleSettings, name: string) {
  return getLinearSettings(settings)?.valueAxis?.find((va) => va.name === name)?.title || "";
}

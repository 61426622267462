import React, { useCallback } from "react";
import PreviewComponent from "./preview/PreviewComponent";
import ViewContainer from "../common/ViewContainer";
import ViewerParameters from "../common/ViewerParameters";
import PreviewActions from "../common/PreviewActions";
import { isValueFieldHasParameters } from "../common/utilities/valueFieldUtils";
import useGridStateViewer from "./preview/hooks/useGridStateViewer";
import withExport from "./preview/hocs/withExport";
import { selectConditions, selectValues, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { fieldsStateActions } from "../../../store/thunks/fieldStatesThunks";
import { ReportField, FieldConfiguration } from "../../../../shared/reporting/api/biClient.types";

export default function PivotViewer() {
  const conditionsAreaValues = useSelector(selectConditions);
  const valuesAreaValues = useSelector(selectValues);
  const state = useGridStateViewer();
  const dispatch = useAppDispatch();
  const fields = React.useMemo(
    () => conditionsAreaValues.filter((v) => v.config.parameter === true),
    [conditionsAreaValues]
  );

  const measuresToShow = React.useMemo(() => {
    return valuesAreaValues.filter(isValueFieldHasParameters);
  }, [valuesAreaValues]);

  const showParameters = React.useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  const updateItem = useCallback(
    (field: ReportField, changes: Partial<FieldConfiguration>) => {
      dispatch(fieldsStateActions.updateMeasureConfig({ field, changes }));
    },
    [dispatch]
  );

  return (
    <ViewContainer
      parameters={
        showParameters ? <ViewerParameters measuresWithConditions={measuresToShow} updateMeasure={updateItem} /> : <></>
      }
      preview={<PreviewComponentWithExport state={state} />}
      actionPanel={<PreviewActions />}
    ></ViewContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);

import { Grid2 } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import { useEnhancedBiApiClientProvider } from "../../contexts/ApiClientProviderContext";
import { useClientContext } from "../../contexts/ClientContext";
import useApplyTemplate from "../../hooks/useApplyTemplate";
import useDefineReportData from "../../hooks/useDefineReportData";
import { useLocalization } from "../../hooks/useLocalization";
import { selectCurrentReport } from "../../store/currentReportSlice";
import { AppDispatch } from "../../store/store";
import ApplyingDialog from "../common/dialogs/ApplyingDialog";
import TemplateNotFound from "../template/TemplateNotFound";
import ErrorApplyingTemplateDialog from "../template/dialogs/ErrorApplyingTemplateDialog";
import EditReportPage from "./EditReportPage";
import ReportNotFoundOrAccessForbidden from "./ReportNotFoundOrAccessForbidden";
import DrillDownEntry from "./common/drillDown/DrillDownEntry";
import useLedgerBasis from "./common/hooks/useLedgerBasis";
import { getOrganizationReportTemplatesUrl } from "./common/utilities/editReportUrl";
import { loadCompanyReportTemplates } from "../../store/thunks/reportTemplatesThunks";

const EditRoot = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { metadata: locale, report_templates: templatesLocale, report: reportLocale } = useLocalization();
  const { clientCode, clientInfo } = useClientContext();
  const report = useSelector(selectCurrentReport);
  const { showReportNotFound, showSwitchingReport } = useDefineReportData();
  const { getReportsUrl } = useEnhancedBiApiClientProvider();
  useLedgerBasis();

  const {
    loadingTemplate,
    applyingTemplateName,
    showTemplateFailedToApply,
    showTemplateNotFound,
    handleCloseFailedToApplyDialog,
    handleTryAgain,
  } = useApplyTemplate();

  React.useEffect(() => {
    if (!clientInfo?.organization) return;
    dispatch(loadCompanyReportTemplates());
  }, [clientInfo?.organization, dispatch]);

  if (id === undefined) {
    return <Navigate to={""} />;
  }

  if (showReportNotFound === true) {
    return <ReportNotFoundOrAccessForbidden getReportsUrl={getReportsUrl} />;
  }

  if (showTemplateNotFound === true) {
    return <TemplateNotFound backUrl={getOrganizationReportTemplatesUrl(clientCode)} />;
  }

  if (report === undefined) {
    return <InlineLoader text={locale.loading_label} />;
  }

  return (
    <Grid2 container sx={{ bgcolor: "white", width: "100%" }}>
      <EditReportPage />
      <DrillDownEntry />
      {loadingTemplate && <ApplyingDialog open text={templatesLocale.applying_template} />}
      {showSwitchingReport && <ApplyingDialog open text={reportLocale.loading_report} />}
      {showTemplateFailedToApply && (
        <ErrorApplyingTemplateDialog
          open
          templateName={applyingTemplateName || "The"}
          onClose={handleCloseFailedToApplyDialog}
          onTryAgain={handleTryAgain}
        />
      )}
    </Grid2>
  );
};

export default EditRoot;

import { Slider, Stack } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { getDoughnutSettings } from "../../contexts/FieldsStateContext.types";
import TypographyWithTooltip from "../../../../common/TypographyWithTooltip";
import { ChartStyleSettings, ReportType } from "../../../../../../shared/reporting/api/biClient.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";

interface Props {
  settings: ChartStyleSettings;
}

export default function ThicknessItem({ settings }: Props) {
  const { chart: locale } = useLocalization();
  const dispatch = useAppDispatch();
  const supported = settings.type === ReportType.DoughnutChart;
  const [value, setValue] = React.useState(() => getThickness(settings));

  const handleThicknessChanged = useDebounce(
    (thickness: number) => dispatch(fieldsStateActions.updateSettings({ settings: { thickness } })),
    400
  );

  React.useEffect(() => {
    const invertedValue = 1 - value;
    handleThicknessChanged(invertedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const formatValueLabel = React.useCallback((value: number) => `${(value * 100).toFixed(0)}%`, []);

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center" gap={3}>
      <TypographyWithTooltip
        label="Thickness"
        tooltipText={locale.thickness_property_explanation}
        typographyProps={{ sx: { color: "text.secondary" } }}
      />
      <HorizontalFill />
      <Slider
        value={value}
        valueLabelDisplay="auto"
        min={0.1}
        max={0.9}
        step={0.1}
        sx={{ py: 0, mr: 1, width: 120 }}
        marks={true}
        valueLabelFormat={formatValueLabel}
        onChange={(_, value) => setValue(value as number)}
      />
    </Stack>
  );
}

export function getThickness(settings: ChartStyleSettings): number {
  return getDoughnutSettings(settings)?.thickness || 0.5;
}

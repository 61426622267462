import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import {
  AxisSettings,
  BarSeriesSettings,
  BarSettings,
  BasicLinearSettings,
  BasicLineSeriesSettings,
  BasicSeriesSettings,
  BasicSettings,
  ChartStyleSettings,
  DoughnutSettings,
  LinearSeriesSettings,
  PieSettings,
  ReportType,
  SeriesSettings,
} from "../../../../../shared/reporting/api/biClient.types";
import updateValueAxis from "./updateValueAxis";
import { ChartFieldState } from "../../../../store/fieldState/types";

export function removeSerie(state: ChartFieldState, name: string) {
  const index = state.settings.series?.findIndex((s) => s.name === name);
  if (state.settings.series !== undefined && index !== undefined && index > -1) {
    state.settings.series.splice(index, 1);
  }
}

export function validateStateByReportType(state: ChartFieldState) {
  if (state.settings.type === ReportType.PieChart) {
    state.values = cloneDeep(state.values);
    state.settings = cloneDeep(state.settings);
    const measuresToRemove = state.values.slice(1);
    measuresToRemove.forEach((measure) => removeSerie(state, measure.config.guid));
  }
  return rebuildSettings(state.settings);
}

function rebuildSettings(settings: ChartStyleSettings): ChartStyleSettings {
  switch (settings.type) {
    case ReportType.BarChart:
      return rebuildBarSettings(settings);
    case ReportType.LineChart:
      return rebuildLineSettings(settings);
    case ReportType.AreaChart:
      return rebuildAreaSettings(settings);
    case ReportType.PieChart:
      return rebuildPieSettings(settings);
    case ReportType.DoughnutChart:
      return rebuildDoughnutSettings(settings);
    default:
      return rebuildBarSettings(settings);
  }
}
function rebuildBarSettings(settings: ChartStyleSettings) {
  const asLinearSettings = settings as BasicLinearSettings;
  const newSettings: BarSettings = {
    type: ReportType.BarChart,
    ...buildGeneralCharSettings(settings),
    ...buildArgumentAxis(settings),
    ...buildBasicLinearChart(settings),
    ...buildLegendSettings(settings),
    series: asLinearSettings.series?.map((s) => {
      const serie = s as BarSeriesSettings;
      return {
        ...buildGeneralSerieSettings(s),
        ...buildLinearSerieSettings(s),
        ...buildBasicLineSerieSettings(s),
        barWidth: serie.barWidth,
        serieType: "Bar",
      } as BarSeriesSettings;
    }),
    ...buildValueAxisSettings(settings),
  };
  return newSettings;
}
function rebuildLineSettings(settings: ChartStyleSettings) {
  const asLinearSettings = settings as BasicLinearSettings;
  const newSettings: BasicLinearSettings = {
    type: ReportType.LineChart,
    ...buildGeneralCharSettings(settings),
    ...buildArgumentAxis(settings),
    ...buildBasicLinearChart(settings),
    ...buildLegendSettings(settings),
    series: asLinearSettings.series?.map((s) => {
      return {
        ...buildGeneralSerieSettings(s),
        ...buildLinearSerieSettings(s),
        ...buildBasicLineSerieSettings(s),
        serieType: "Line",
      } as LinearSeriesSettings;
    }),
    ...buildValueAxisSettings(settings),
  };
  return newSettings;
}
function rebuildAreaSettings(settings: ChartStyleSettings) {
  const asLinearSettings = settings as BasicLinearSettings;
  const newSettings: BasicLinearSettings = {
    type: ReportType.AreaChart,
    ...buildGeneralCharSettings(settings),
    ...buildArgumentAxis(settings),
    ...buildBasicLinearChart(settings),
    ...buildLegendSettings(settings),
    series: asLinearSettings.series?.map((s) => {
      return {
        ...buildGeneralSerieSettings(s),
        ...buildLinearSerieSettings(s),
        ...buildBasicLineSerieSettings(s),
        serieType: "Area",
      } as LinearSeriesSettings;
    }),
    ...buildValueAxisSettings(settings),
  };
  return newSettings;
}
function rebuildPieSettings(settings: ChartStyleSettings) {
  const asPieSettings = settings as PieSettings;
  const newSettings: PieSettings = {
    type: ReportType.PieChart,
    ...buildGeneralCharSettings(settings),
    ...buildLegendSettings(settings),
    series: asPieSettings.series?.map((s) => ({
      ...buildGeneralSerieSettings(s),
    })),
  };
  return newSettings;
}
function rebuildDoughnutSettings(settings: ChartStyleSettings) {
  const asDoughnutSettings = settings as DoughnutSettings;
  const newSettings: DoughnutSettings = {
    type: ReportType.DoughnutChart,
    ...buildGeneralCharSettings(settings),
    ...buildLegendSettings(settings),
    thickness: asDoughnutSettings.thickness,
    series: asDoughnutSettings.series?.map((s) => ({
      ...buildGeneralSerieSettings(s),
    })),
  };
  return newSettings;
}

function buildGeneralCharSettings(settings: ChartStyleSettings) {
  const asBasicSettings = settings as BasicSettings;
  return {
    showTooltip: asBasicSettings.showTooltip,
  };
}
function buildArgumentAxis(settings: ChartStyleSettings) {
  const asLinearSettings = settings as BasicLinearSettings;
  return {
    argumentAxis: {
      showLabel: asLinearSettings.argumentAxis?.showLabel,
      title: asLinearSettings.argumentAxis?.title,
    } as AxisSettings,
  };
}
function buildBasicLinearChart(settings: ChartStyleSettings) {
  const asLinearSettings = settings as BasicLinearSettings;
  return {
    gridMode: asLinearSettings.gridMode,
    rotated: asLinearSettings.rotated,
    showTooltip: asLinearSettings.showTooltip,
  };
}
function buildLegendSettings(settings: ChartStyleSettings) {
  const asLinearSettings = settings as BasicLinearSettings;
  return {
    legend: { ...asLinearSettings.legend },
  };
}
function buildGeneralSerieSettings(serie: SeriesSettings) {
  const asSerie = serie as BasicSeriesSettings;
  return {
    name: asSerie.name,
    showConnector: asSerie.showConnector,
    showLabel: asSerie.showLabel,
  };
}
function buildLinearSerieSettings(serie: SeriesSettings) {
  const asSerie = serie as LinearSeriesSettings;
  return {
    serieType: asSerie.serieType,
    valueAxis: asSerie.valueAxis,
  };
}
function buildBasicLineSerieSettings(serie: SeriesSettings) {
  const asSerie = serie as BasicLineSeriesSettings;
  return {
    lineType: asSerie.lineType,
    showPoints: asSerie.showPoints,
  };
}
function buildValueAxisSettings(settings: BasicLinearSettings) {
  return {
    valueAxis: updateValueAxis(settings),
  };
}

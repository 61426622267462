import { useCallback } from "react";
import { ReportType } from "../../../../../shared/reporting/api/biClient.types";
import { useAppDispatch } from "../../../../store/store";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks";

const useReportTypeChange = () => {
  const dispatch = useAppDispatch();

  const onReportTypeChanged = useCallback(
    (reportType: ReportType) => {
      dispatch(fieldsStateActions.handleReportTypeChange(reportType));
    },
    [dispatch]
  );

  return { onReportTypeChanged };
};

export default useReportTypeChange;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientSettings } from "../api/biApi.types";
import { RootState } from "./RootState";
import { loadClientSettings } from "./thunks/clientSettingsThunks";

export interface ClientSettingsState {
  settings?: ClientSettings;
  loaded: boolean;
  loadingFailed: boolean;
}

const initialState: ClientSettingsState = {
  loaded: false,
  loadingFailed: false,
};

const clientSettingsSlice = createSlice({
  name: "clientSettings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<ClientSettings>) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadClientSettings.fulfilled, (state) => {
      state.loaded = true;
      state.loadingFailed = false;
    });
    builder.addCase(loadClientSettings.rejected, (state) => {
      state.loadingFailed = true;
      state.loaded = false;
    });
  },
});

export const clientSettingsActions = clientSettingsSlice.actions;

export const selectClientSettings = (state: RootState) => state.clientSettings?.settings;
export const selectAccountBookSettings = (state: RootState) => state.clientSettings?.settings?.accountingBookSettings;
export const selectDataSetSettings = (state: RootState) => state.clientSettings?.settings?.dataSets ?? [];
export const selectClientSettingsLoaded = (state: RootState) => state.clientSettings?.loaded;
export const selectClientSettingsLoadingFailed = (state: RootState) => state.clientSettings?.loadingFailed;

export default clientSettingsSlice.reducer;

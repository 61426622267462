import { useEffect } from "react";
import { PreviewGridStateType } from "./useGridStateBuilder";
import { useExportStateContext } from "../../../common/contexts/ExportStateContext";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { selectRows } from "../../../../../store/store";
import { useSelector } from "react-redux";

export default function useUpdateExportState(state: PreviewGridStateType) {
  const { pivot: locale } = useLocalization();
  const rowsAreaValues = useSelector(selectRows);
  const exportState = useExportStateContext();

  useEffect(() => {
    if (state.calculating) {
      exportState.actions.update({
        exportAllowed: false,
        warning: locale.data_is_calculating_warning,
      });
    } else if (rowsAreaValues.length === 0) {
      exportState.actions.update({
        exportAllowed: false,
        warning: locale.grid_is_not_configured_warning,
      });
    } else {
      exportState.actions.update({
        exportAllowed: true,
        warning: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.calculating, rowsAreaValues]);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Report,
  ReportAuthorizationInfo,
  ReportConfiguration,
  ReportTemplate,
} from "../../shared/reporting/api/biClient.types";
import cloneDeep from "../../shared/utilities/cloneDeep";
import { generateGuid } from "../../shared/utilities/generateGuid";
import { areConfigurationsEqual } from "../utilities/configurationComparer";
import { CurrentReportState, PreviewMode } from "./CurrentReportState";
import { RootState } from "./RootState";

const initialState: CurrentReportState = {
  refreshToken: "",
  previewMode: "maximize",
  isReportChanged: false,
  editMode: false,
  sessionId: generateGuid(),
  cacheSessionId: generateGuid(),
  transactional: true,
};

const currentReportSlice = createSlice({
  name: "currentReport",
  initialState,
  reducers: {
    refreshSession: (state) => {
      state.cacheSessionId = generateGuid();
    },
    refresh: (state) => {
      state.refreshToken = generateGuid();
      state.cacheSessionId = generateGuid();
    },
    /**
     * @deprecated Don't use this action, use setReport thunks instead
     */
    setReport: (
      state,
      action: PayloadAction<{
        report: Report | ReportTemplate;
        shouldResetConfiguration?: boolean;
      }>
    ) => {
      state.report = cloneDeep(action.payload.report);
      if (action.payload.shouldResetConfiguration) {
        state.configuration = undefined;
        state.isReportChanged = false;
      }
    },
    setAuthorizationInfo: (state, action: PayloadAction<ReportAuthorizationInfo>) => {
      state.authorizationInfo = action.payload;
    },
    /**
     * @deprecated Don't use this action, use updateReport thunk instead
     */
    updateReport: (state, action: PayloadAction<Partial<Report | ReportTemplate>>) => {
      state.report = Object.assign({}, state.report, action.payload);
    },
    updatePreviewMode: (state, action: PayloadAction<PreviewMode>) => {
      state.previewMode = action.payload;
    },
    updateReportConfiguration: (state, action: PayloadAction<ReportConfiguration>) => {
      state.configuration = action.payload;
      state.isReportChanged = !areConfigurationsEqual(state.report?.configuration || {}, state.configuration);
    },
    updateReportMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setAutoShowItemOptionsId: (state, action: PayloadAction<string | undefined>) => {
      state.autoShowItemOptionsId = action.payload;
    },
    setEtag(state, action: PayloadAction<{ etag: string | undefined; cacheValid: boolean | undefined }>) {
      state.etag = action.payload.etag;
      state.cacheValid = action.payload.cacheValid;
    },
    /**
     * @deprecated Don't use this action, use applyTemplate thunks instead
     */
    applyTemplateOnReport: (
      state,
      action: PayloadAction<{
        template: ReportTemplate;
        shouldResetCache?: boolean;
      }>
    ) => {
      if (state.report) {
        state.report.reportType = action.payload.template.reportType;
        state.report.dataSetId = action.payload.template.dataSetId;
        state.report.configuration = cloneDeep(action.payload.template.configuration);
        state.configuration = cloneDeep(action.payload.template.configuration);

        if (action.payload.shouldResetCache) {
          state.cacheSessionId = generateGuid();
          state.resetSessionId = generateGuid();
        }
      }
    },
    updateBuildProgress: (state, action: PayloadAction<number | undefined>) => {
      state.currentBuildProgress = action.payload;
    },
    resetReportConfiguration: (state) => {
      state.configuration = undefined;
      state.isReportChanged = false;
    },
    updateIsReportTransactional: (state, action: PayloadAction<boolean>) => {
      state.transactional = action.payload;
    },
    updateReportDataSetId: (state, action: PayloadAction<string>) => {
      if (state.report) {
        state.report.dataSetId = action.payload;
      }
    },
  },
});

export const currentReportActions = currentReportSlice.actions;

export const selectRefreshToken = (state: RootState) => state.currentReport.refreshToken;
export const selectSessionId = (state: RootState) => state.currentReport.sessionId;
export const selectCacheSessionId = (state: RootState) => state.currentReport.cacheSessionId;
export const selectCurrentReport = (state: RootState) => state.currentReport.report;
export const selectCurrentReportAuthorizationInfo = (state: RootState) => state.currentReport.authorizationInfo;
export const selectPreviewMode = (state: RootState) => state.currentReport.previewMode;
export const selectReportConfiguration = (state: RootState) => state.currentReport.configuration;
export const selectIsReportChanged = (state: RootState) => state.currentReport.isReportChanged;
export const selectReportEditMode = (state: RootState) => state.currentReport.editMode;
export const selectAutoShowItemOptionsId = (state: RootState) => state.currentReport.autoShowItemOptionsId;
export const selectResetId = (state: RootState) => state.currentReport.resetSessionId;
export const selectEtag = (state: RootState) => state.currentReport.etag;
export const selectDrillDownCacheValid = (state: RootState) => state.currentReport.cacheValid;
export const selectCurrentBuildProgress = (state: RootState) => state.currentReport.currentBuildProgress;
export const selectIsReportTransactional = (state: RootState) => state.currentReport.transactional;

export default currentReportSlice.reducer;

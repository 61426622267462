import { Stack, Switch, Typography } from "@mui/material";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  BasicLineSeriesSettings,
  ChartStyleSettings,
  SeriesSettings,
} from "../../../../../../shared/reporting/api/biClient.types";
import { isLineBasedSerie } from "../../contexts/FieldsStateContext.types";
import { useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

interface Props {
  settings: ChartStyleSettings;
  serie: SeriesSettings;
}

export default function ShowPointsItem({ settings, serie }: Props) {
  const supported = isLineBasedSerie(settings, serie);
  const showPoints = getShowPoints(serie);
  const dispatch = useAppDispatch();

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Show Points</Typography>
      <HorizontalFill />
      <Switch
        checked={showPoints}
        onChange={(_, value) =>
          dispatch(fieldsStateActions.updateSeriesSettings({ name: serie.name, changes: { showPoints: value } }))
        }
      />
    </Stack>
  );
}

export function getShowPoints(serie: SeriesSettings | undefined) {
  const linearSerie = serie as BasicLineSeriesSettings;
  return linearSerie?.showPoints === true;
}

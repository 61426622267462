import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { BiAggregation, ItemDataType, ReportField } from "../../../../shared/reporting/api/biClient.types";
import { currentReportActions } from "../../../store/currentReportSlice";
import { selectMeasures } from "../../../store/metaDataSlice";
import { SupportedAggregationsContextProvider } from "../common/fields/contexts/ExportStateContext";
import useCrossFiltering from "../common/hooks/useCrossFiltering";
import useDeferredDictionaryLoading from "../common/hooks/useDeferredDictionaryLoading";
import { updateMeasureFields } from "../utils/MeasureUtils";
import { selectConditions, selectTabularFieldsState, useAppDispatch } from "../../../store/store.ts";
import { createTabularReportConfiguration } from "../utils/configuration/tabularConfiguration.ts";
import { fieldsStateActions } from "../../../store/thunks/fieldStatesThunks.ts";
import { ConditionField } from "../Types.ts";

export default function TabularContainer({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const conditionsAreaValues = useSelector(selectConditions);
  const measures = useSelector(selectMeasures);
  const fieldsState = useSelector(selectTabularFieldsState);
  const fieldsStateRef = useRef(fieldsState);
  fieldsStateRef.current = fieldsState;

  useEffect(() => {
    const configuration = createTabularReportConfiguration(
      fieldsState.conditions,
      fieldsState.fields,
      fieldsState.sorts,
      fieldsState.grouping,
      fieldsState.settings
    );

    dispatch(currentReportActions.updateReportConfiguration(configuration));
  }, [
    fieldsState.conditions,
    fieldsState.fields,
    fieldsState.sorts,
    fieldsState.grouping,
    fieldsState.settings,
    dispatch,
  ]);

  useEffect(
    () =>
      updateMeasureFields(
        {
          fields: fieldsStateRef.current.fields,
          updateMeasureField: (field, changes) => dispatch(fieldsStateActions.updateMeasureField({ field, changes })),
          updateFieldConfig: (field, changes) => dispatch(fieldsStateActions.updateFieldConfig({ field, changes })),
          removeField: (field) => dispatch(fieldsStateActions.removeField(field)),
        },
        measures
      ),
    [measures, dispatch]
  );

  const updateItem = useCallback(
    (field: ConditionField, changes: Partial<ConditionField>) => {
      dispatch(fieldsStateActions.updateCondition({ field, changes }));
    },
    [dispatch]
  );

  useCrossFiltering();
  useDeferredDictionaryLoading({ values: conditionsAreaValues, updateItem });

  return (
    <SupportedAggregationsContextProvider
      aggregations={supportedAggregations}
      getSupportedAggregationTypes={getSupportedAggregationTypes}
    >
      {children}
    </SupportedAggregationsContextProvider>
  );
}

const supportedAggregations = [
  BiAggregation.None,
  BiAggregation.Average,
  BiAggregation.Count,
  BiAggregation.CountDistinct,
  BiAggregation.Max,
  BiAggregation.Min,
  BiAggregation.StringAgg,
  BiAggregation.Sum,
];

const getSupportedAggregationTypes = (field: ReportField) => {
  const items = [BiAggregation.None, BiAggregation.Count, BiAggregation.CountDistinct];

  if (field.meta.type === ItemDataType.Numeric) {
    items.push(BiAggregation.Average, BiAggregation.Min, BiAggregation.Max, BiAggregation.Sum);
  } else if (field.meta.type === ItemDataType.Date) {
    items.push(BiAggregation.Min, BiAggregation.Max);
  } else if (field.meta.type === ItemDataType.General) {
    items.push(BiAggregation.StringAgg);
  }

  return items;
};

import { Report, ReportTemplate } from "../../../shared/reporting/api/biClient.types";
import { currentReportActions } from "../currentReportSlice";
import { metaDataActions } from "../metaDataSlice";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk";
import { fieldsStateActions } from "./fieldStatesThunks";
import { changeDataSet } from "./metaDataThunks";

export const setReport = createTypedAsyncThunk("store/setReport", (report: Report, { dispatch, getState }) => {
  const state = getState();

  const newDataSetId = report.dataSetId || state.metaData.dataSets[0]?.dataSetId;
  const dataSetNeedsUpdate = newDataSetId && newDataSetId !== state.metaData.dataSetId;

  if (dataSetNeedsUpdate) {
    dispatch(changeDataSet(newDataSetId));
  }

  dispatch(
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    currentReportActions.setReport({ report, shouldResetConfiguration: true })
  );

  //sets setFieldStatesConfiguration
  dispatch(fieldsStateActions.initializeFieldsState());
  dispatch(metaDataActions.updateReportType(report.reportType));
});

export const applyTemplate = createTypedAsyncThunk(
  "store/applyTemplate",
  (template: ReportTemplate, { dispatch, getState }) => {
    const state = getState();
    const newDataSetId = template.dataSetId ?? state.metaData.dataSets[0]?.dataSetId;

    const dataSetNeedsUpdate = newDataSetId && newDataSetId !== state.metaData.dataSetId;
    if (dataSetNeedsUpdate) {
      dispatch(changeDataSet(newDataSetId));
    }

    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      currentReportActions.applyTemplateOnReport({ template, shouldResetCache: true })
    );

    dispatch(fieldsStateActions.initializeFieldsState());
    dispatch(metaDataActions.updateReportType(template.reportType));
  }
);

export const updateReport = createTypedAsyncThunk(
  "store/updateReport",
  (changes: Partial<Report | ReportTemplate>, { dispatch }) => {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    dispatch(currentReportActions.updateReport(changes));

    if (changes.reportType) {
      dispatch(metaDataActions.updateReportType(changes.reportType));
    }
  }
);

import { Box, Button, Stack, TextField } from "@mui/material";
import { useState } from "react";

interface Props {
  reportName: string | undefined;
  loading: boolean;
  validationError: string | undefined;
  onApply: (newReportName: string) => void;
  onClose: () => void;
  onReportNameChanged: (newReportName: string) => void;
}

const ChangeReportNameField = ({
  reportName,
  onApply,
  validationError,
  onClose,
  loading,
  onReportNameChanged,
}: Props) => {
  const [newReportName, setNewReportName] = useState(reportName || "");

  const handleApply = () => {
    onApply(newReportName);
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <TextField
        autoFocus
        placeholder="Enter Report Name"
        focused
        sx={(t) => ({
          "& .MuiOutlinedInput-root": { maxHeight: "30px" },
          "& .MuiFormHelperText-root": { mt: 0.25 },
          minWidth: t.spacing(15),
          width: "100%",
        })}
        value={newReportName}
        onChange={(e) => {
          setNewReportName(e.target.value);
          onReportNameChanged(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleApply();
            return;
          }
          if (e.key === "Escape") {
            onClose();
            return;
          }
        }}
        error={!!validationError}
        helperText={validationError}
      />
      <Box display="flex" gap={1} alignItems="center" py={0.5}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={loading} onClick={handleApply} disabled={!!validationError}>
          Apply
        </Button>
      </Box>
    </Stack>
  );
};

export default ChangeReportNameField;

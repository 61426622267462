import { Divider, Grid2, Tab, Tabs } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { selectCurrentReport } from "../../../../store/currentReportSlice";
import ConditionsDropFieldsContainer from "../../pivot/table/conditions/ConditionsDropFieldsContainer";
import ValuesDropFieldsContainer from "../../pivot/table/values/ValuesDropFieldsContainer";
import SortDropsFieldsContainer from "../../tabular/table/SortDropsFieldsContainer";
import { TableComponentProps } from "../../Types";
import StyleContainer from "../style/StyleContainer";
import ArgumentDropFieldsContainer from "./arguments/ArgumentDropFieldsContainer";
import canDropValueItem from "./canDropValue";
import FormatContainer from "./FormatContainer";
import { selectSorts, selectArguments, selectValues } from "../../../../store/store";

export default function TableComponent({ ReportTypeSelector }: TableComponentProps) {
  const report = useSelector(selectCurrentReport);
  const argumentsAreaValues = useSelector(selectArguments);
  const sortingAreaValues = useSelector(selectSorts);
  const valuesAreaValues = useSelector(selectValues);
  const [selectedTab, setSelectedTab] = useState(0);
  const valuesRef = useRef(valuesAreaValues);
  valuesRef.current = valuesAreaValues;

  const reportRef = useRef(report);
  reportRef.current = report;

  const availableSortFields = React.useMemo(() => {
    const result = argumentsAreaValues.filter((f) => !sortingAreaValues.find((v) => v.meta.name === f.meta.name));
    return result;
  }, [argumentsAreaValues, sortingAreaValues]);

  const handleCanDropItem = useCallback(() => canDropValueItem(reportRef.current?.reportType, valuesRef.current), []);

  return (
    <Grid2 container sx={{ flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem", width: "100%" }}>
      <Grid2 container px="1.3rem" width={"100%"}>
        {ReportTypeSelector}
        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value: number) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid2>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer disableEqualitySelection={true} />
          <ArgumentDropFieldsContainer />
          <ValuesDropFieldsContainer fields={argumentsAreaValues} canDropItem={handleCanDropItem} />
          <SortDropsFieldsContainer availableFields={availableSortFields} />
        </ScrollableFlexContainer>
      )}
      {selectedTab === 1 && <StyleContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Grid2>
  );
}

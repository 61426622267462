import { Grid2, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { enUS } from "date-fns/locale";
import { Provider } from "react-redux";
import { Outlet, useParams } from "react-router";
import AuthenticationFailed from "../shared/components/AuthenticationFailed";
import ErrorBoundary from "../shared/components/ErrorBoundary";
import FullScreenLoader from "../shared/components/fullScreenLoader/FullScreenLoader";
import { ClientPermissionsContextProvider } from "../shared/contexts/ClientPermissionsContext";
import { ClientInfo } from "../shared/reporting/api/biClient.types";
import ImportantDataLoadingFailed from "./components/common/ImportantDataLoadingFailed";
import { ClientContextProvider } from "./contexts/ClientContext";
import { UserContextProvider } from "./contexts/UserContext";
import { useLocalization } from "./hooks/useLocalization";
import { store } from "./store/store";
import { AppInitializer } from "./components/AppInitializer";
import { useClientManagement } from "./hooks/useClientManagement";
import { useUserAuthentication } from "./hooks/useUserAuthentication";
import { useClientNavigation } from "./hooks/useClientNavigation";
import IntercomSettingsProvider from "./components/builder/IntercomSettingsProvider";

export default function App() {
  const { client } = useParams();
  const locale = useLocalization();

  const { isAuthenticated, user, loadingUserError, userHasNoPermissions } = useUserAuthentication();
  const { clientCode, setClientCode, clientInfo, clients } = useClientManagement(client);
  const { setCurrentClientCode } = useClientNavigation(client, user, setClientCode, clients);

  if (!isAuthenticated) {
    return <FullScreenLoader title={locale.redirect.title} subtitle={locale.redirect.subtitle} />;
  }

  if (userHasNoPermissions) {
    return <AuthenticationFailed />;
  }

  if (loadingUserError) {
    return (
      <ImportantDataLoadingFailed
        title={locale.common.report_data_loading_failed_title}
        disclaimer={locale.common.report_data_loading_failed_text}
      />
    );
  }

  if (user === undefined || clientCode === undefined || clients === undefined) {
    return <FullScreenLoader title={locale.identity.title} subtitle={locale.identity.subtitle} />;
  }

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
        <Provider store={store}>
          <AppInitializer clientCode={clientCode}>
            <UserContextProvider value={user}>
              <IntercomSettingsProvider />
              <ClientContextProvider
                client={{
                  clientCode,
                  setClientCode: setCurrentClientCode,
                  clientInfo: clientInfo as ClientInfo,
                  clients: clients,
                }}
              >
                <ClientPermissionsContextProvider
                  permissions={user.permissions.find((p) => p.clientCode === clientCode)?.permissions || []}
                  permissionSets={user.permissions}
                >
                  <Stack flex={1}>
                    <Grid2 container flex={1} maxHeight={"100%"} width={"100%"}>
                      <Outlet />
                    </Grid2>
                  </Stack>
                </ClientPermissionsContextProvider>
              </ClientContextProvider>
            </UserContextProvider>
          </AppInitializer>
        </Provider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

import React from "react";
import { FieldConfiguration, ReportField } from "../../../../shared/reporting/api/biClient.types";
import PreviewActions from "../common/PreviewActions";
import { isValueFieldHasParameters } from "../common/utilities/valueFieldUtils";
import ViewContainer from "../common/ViewContainer";
import ViewerParameters from "../common/ViewerParameters";
import withExport from "./hocs/withExport";
import useChartStateViewer from "./hooks/useChartStateViewer";
import PreviewComponent from "./PreviewComponent";
import { useSelector } from "react-redux";
import { selectConditions, selectValues, useAppDispatch } from "../../../store/store";
import { fieldsStateActions } from "../../../store/thunks/fieldStatesThunks";

export default function ChartViewer() {
  const dispatch = useAppDispatch();
  const conditionsAreaValues = useSelector(selectConditions);
  const valuesAreaValues = useSelector(selectValues);
  const state = useChartStateViewer();

  const fields = React.useMemo(
    () => conditionsAreaValues.filter((v) => v.config.parameter === true),
    [conditionsAreaValues]
  );

  const measuresToShow = React.useMemo(() => {
    return valuesAreaValues.filter(isValueFieldHasParameters);
  }, [valuesAreaValues]);

  const showParameters = React.useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  const updateMeasure = React.useCallback(
    (field: ReportField, change: Partial<FieldConfiguration>) => {
      const tableField = valuesAreaValues.find((v) => v.config.guid === field.config.guid);
      if (tableField !== undefined) {
        dispatch(fieldsStateActions.updateMeasureConfig({ field, changes: change }));
      }
    },
    [valuesAreaValues, dispatch]
  );

  return (
    <ViewContainer
      parameters={
        showParameters ? (
          <ViewerParameters measuresWithConditions={measuresToShow} updateMeasure={updateMeasure} />
        ) : (
          <></>
        )
      }
      preview={<PreviewComponentWithExport state={state} />}
      actionPanel={<PreviewActions />}
    ></ViewContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);

import Intercom from "../../../shared/components/Intercom";
import { useSelector } from "react-redux";
import { selectClientSettings } from "../../store/clientSettingsSlice";
import { useUserContext } from "../../contexts/UserContext";

const IntercomSettingsProvider = () => {
  const clientSettings = useSelector(selectClientSettings);
  const user = useUserContext();

  return (
    <>
      {clientSettings?.intercomAuthentication !== undefined && (
        <Intercom fullName={user.name} email={user.email} settings={clientSettings?.intercomAuthentication} />
      )}
    </>
  );
};

export default IntercomSettingsProvider;

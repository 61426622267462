import { useCallback } from "react";
import { DimensionField } from "../../../../../shared/reporting/api/biClient.types";
import SelectFieldInput from "./SelectFieldInput";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";
import { useAppDispatch } from "../../../../store/store.ts";

interface Props {
  availableFields: DimensionField[];
}

export const SelectSortFieldInput = ({ availableFields }: Props) => {
  const dispatch = useAppDispatch();

  const addFieldToSort = useCallback(
    (field: DimensionField) => dispatch(fieldsStateActions.addSorting(field)),
    [dispatch]
  );

  return <SelectFieldInput onAddField={addFieldToSort} availableFields={availableFields} />;
};

export default SelectSortFieldInput;

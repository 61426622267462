import { Series } from "devextreme-react/chart";
import PieChart from "devextreme-react/pie-chart";
import { useCallback } from "react";
import ChartCore, { getBasicLegend, getBasicSerieLabel } from "./ChartCore";
import { ChartProps } from "./ChartProps";
import { useSelector } from "react-redux";
import { selectChartSettingsState } from "../../../store/store";
import { getThickness } from "./style/chart/ThicknessItem";
import formatArgumentLabel from "./utilities/argumentFormat";

export default function Doughnut(props: ChartProps) {
  const settingsAreaValues = useSelector(selectChartSettingsState);
  const updateRef = useCallback(
    (ref: PieChart) => {
      props.exportFunc(ref?.instance.exportTo.bind(ref.instance));
    },
    [props]
  );
  const renderSeries = () => {
    const argument = props.args[0];
    if (argument === undefined) return <></>;
    return props.values.map((value) => {
      return (
        <Series
          key={value.config.guid}
          argumentField={argument.meta.name}
          valueField={value.config.guid}
          name={value.config.guid}
          label={getBasicSerieLabel(value.config.guid, settingsAreaValues, props.data)}
        ></Series>
      );
    });
  };

  return (
    <ChartCore {...props}>
      <PieChart
        ref={updateRef}
        dataSource={props.dataSource}
        type="doughnut"
        legend={{
          ...getBasicLegend(settingsAreaValues),
          customizeText: ({ pointName }: { pointName?: string }) =>
            formatArgumentLabel(props.args, pointName, props.data),
        }}
        innerRadius={getThickness(settingsAreaValues)}
      >
        {renderSeries()}
      </PieChart>
    </ChartCore>
  );
}

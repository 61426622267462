import React, { useCallback } from "react";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";
import TitleItem from "../TitleItem";
import { ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";
interface Props {
  settings: ChartStyleSettings;
}

export default function Title({ settings }: Props) {
  const title = React.useMemo(() => argumentAxisTitle(settings), [settings]);
  const dispatch = useAppDispatch();

  const updateArgumentAxis = useCallback(
    (title: string | undefined) => {
      dispatch(fieldsStateActions.updateAxisArgumentSettings({ title }));
    },
    [dispatch]
  );

  return <TitleItem title={title} placeholder="Set Axis Title..." onUpdate={updateArgumentAxis} />;
}

export function argumentAxisTitle(settings: ChartStyleSettings) {
  return getLinearSettings(settings)?.argumentAxis?.title || "";
}

import { MetaDescriptorBase, MetaItemType } from "../../../shared/reporting/api/biClient.types";
import { MetaDataResponse } from "../../api/biApi.types";
import { DataSetMetaDataState } from "../MetaDataState";
import biClient from "../../api/biApi";
import { metaDataActions } from "../metaDataSlice";
import { logError } from "../../../shared/logging";
import { currentReportActions } from "../currentReportSlice";
import { fieldsStateActions } from "./fieldStatesThunks.ts";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk.ts";

export const changeDataSet = createTypedAsyncThunk("store/changeDataSet", (dataSetId: string, { dispatch }) => {
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  dispatch(metaDataActions.changeDataSet(dataSetId));
  dispatch(fieldsStateActions.resetFieldsState(undefined));
  dispatch(currentReportActions.updateReportDataSetId(dataSetId));
});

export const loadMetaData = createTypedAsyncThunk("store/loadMetaData", async (_, { dispatch }) => {
  try {
    const response = await biClient.loadMeta();
    if (response.success && response.data) {
      const dataSets = refineMetaDataResponse(response.data);
      dispatch(
        metaDataActions.update({
          dataSets,
          loaded: true,
          loadingFailed: false,
        })
      );
    } else {
      dispatch(metaDataActions.update({ loadingFailed: true, loaded: false }));
    }
  } catch (error) {
    logError(error, "loadMetaData");
    dispatch(metaDataActions.update({ loadingFailed: true, loaded: false }));
  }
});

const setItemType = <T extends MetaDescriptorBase>(objs: T[], itemType: MetaItemType) => {
  objs.forEach((obj) => (obj.itemType = itemType));
  return objs;
};

const refineMetaDataResponse = (data: MetaDataResponse) => {
  return data.dataSets.map<DataSetMetaDataState>((dataset) => ({
    dataSetId: dataset.dataSetId,
    dimensionsStructure: dataset.dimensionsStructure,
    measuresStructure: dataset.measuresStructure,
    dimensions: setItemType(dataset.dimensions, MetaItemType.DIMENSION),
    measures: [],
    allMeasures: setItemType(dataset.measures, MetaItemType.MEASURE),
    functions: dataset.functions,
    measureGroups: dataset.measureGroups,
  }));
};

import { setTemplates } from "../reportTemplatesSlice";
import sharedBiClient from "../../../shared/reporting/api/biClient";
import { logError } from "../../../shared/logging";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk";

export const loadCompanyReportTemplates = createTypedAsyncThunk(
  "store/loadCompanyReportTemplates",
  async (_, { dispatch }) => {
    try {
      const response = await sharedBiClient.getCompanyReportTemplates();
      if (response.success && response.data !== undefined) {
        dispatch(setTemplates(response.data));
        return response.data;
      }
      throw new Error("Failed to load company report templates");
    } catch (error) {
      logError(error, "[loadCompanyReportTemplates]");
      throw error;
    }
  }
);

import { Report } from "../../../shared/reporting/api/biClient.types";
import ReportTypeSelector from "./ReportTypeSelector";
import { EditorProps } from "./Types";

interface Props {
  Editor: React.ComponentType<EditorProps>;
  report: Report;
}
const ReportEditor = ({ Editor, report }: Props) => (
  <Editor ReportTypeSelector={<ReportTypeSelector report={report} />} />
);

export default ReportEditor;

import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ValueAxis, ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";

interface Props {
  axis: ValueAxis;
  settings: ChartStyleSettings;
}

export default function ShowLabelItem({ axis, settings }: Props) {
  const showLabel = React.useMemo(() => isShowValueAxisLabel(settings, axis.name), [settings, axis]);
  const dispatch = useAppDispatch();

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Show Label</Typography>
      <HorizontalFill />
      <Switch
        checked={showLabel}
        onChange={(_, value) =>
          dispatch(fieldsStateActions.updateAxisValueSettings({ name: axis.name, changes: { showLabel: value } }))
        }
      />
    </Stack>
  );
}

export function isShowValueAxisLabel(settings: ChartStyleSettings, name: string) {
  return getLinearSettings(settings)?.valueAxis?.find((va) => va.name === name)?.showLabel !== false;
}

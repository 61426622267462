import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  AreaItemType,
  DimensionFieldType,
  FieldConfiguration,
  ItemDataType,
  MeasureField,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types.ts";
import { selectDimensions } from "../../../../store/metaDataSlice.ts";
import DimensionItem from "../../common/fields/DimensionItem.tsx";
import DropFieldContainer from "../../common/fields/DropFieldContainer.tsx";
import MeasureItem from "../../common/fields/MeasureItem.tsx";
import { FieldItemProps } from "../../common/fields/types/areaFiledItem.types.ts";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../common/fields/types/dropField.types.ts";
import { createTabularField } from "../../common/utilities/createFields.ts";
import { extractGuid, extractMeta } from "../../common/utilities/dropFieldContainerHelper.ts";
import { formatMeasureCaption } from "../../pivot/table/values/helper.tsx";
import { ShowFieldOptionsSettings } from "../../Types.ts";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper.ts";
import { formatDimensionInFieldsCaption } from "../../utils/formatDimensionFieldCaptions.tsx";
import { getDimensionFields, isMeasureConfigurationValid } from "../../utils/isConfigurationValid.ts";
import { canDropItem } from "../utilities/fieldsDropContainerHelper.ts";
import AggregationOptionsComponent from "./AggregationOptionsComponent.tsx";
import DimensionFieldOptions from "./DimensionFieldOptions.tsx";
import FieldOptionPopupContainer from "./FieldOptionPopupContainer.tsx";
import MeasureFieldOptions from "./MeasureFieldOptions.tsx";
import OtherOptionsComponent from "./OtherOptionsComponent.tsx";
import { selectConditions, selectTabularFields, useAppDispatch } from "../../../../store/store.ts";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";

const FieldsDropContainer = () => {
  const dispatch = useAppDispatch();
  const fieldsAreaValues = useSelector(selectTabularFields);
  const fieldsAreaRef = React.useRef<ReportField[]>(fieldsAreaValues);
  fieldsAreaRef.current = fieldsAreaValues;
  const conditionsAreaValues = useSelector(selectConditions);

  const storedDimensions = useSelector(selectDimensions);
  const dimensionFields = useMemo(() => getDimensionFields(fieldsAreaValues), [fieldsAreaValues]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [currentFieldId, setCurrentFieldId] = React.useState<string>();
  const currentField = React.useMemo(() => {
    if (!currentFieldId) {
      return undefined;
    }
    return fieldsAreaValues.find((f) => f.config.guid === currentFieldId);
  }, [currentFieldId, fieldsAreaValues]);

  const dimensions = React.useMemo(() => {
    return storedDimensions
      .filter((d) => d.type !== ItemDataType.Numeric)
      .sort((a, b) => a.caption.localeCompare(b.caption));
  }, [storedDimensions]);

  const dimensionsRef = React.useRef(dimensions);
  dimensionsRef.current = dimensions;

  const showFieldOptions = React.useCallback((settings: ShowFieldOptionsSettings<ReportField>) => {
    setAnchorEl(settings.ref);
    setCurrentFieldId(settings.field.config.guid);
  }, []);

  const measureCaptionColor = React.useCallback(
    (value: MeasureField) => {
      const valid = isMeasureConfigurationValid(conditionsAreaValues, dimensionFields, dimensionsRef.current, value);
      return valid.valid ? undefined : "error.main";
    },
    [conditionsAreaValues, dimensionFields]
  );

  const handleMeasureFormatCaption = React.useCallback(
    (measure: MeasureField) => formatMeasureCaption(measure, measureCaptionColor(measure)),
    [measureCaptionColor]
  );

  const removeItem = useCallback(
    (item: ReportField) => {
      dispatch(fieldsStateActions.removeField(item));
    },
    [dispatch]
  );
  const moveItem = useCallback(
    (item: ReportField, newIndex: number) => {
      dispatch(fieldsStateActions.moveField({ field: item, newIndex }));
    },
    [dispatch]
  );
  const addItem = useCallback(
    (item: ReportField, index: number) => {
      dispatch(fieldsStateActions.addField({ field: item, index }));
    },
    [dispatch]
  );
  const updateItemConfig = useCallback(
    (field: ReportField, change: Partial<FieldConfiguration>) => {
      dispatch(fieldsStateActions.updateFieldConfig({ field, changes: change }));
    },
    [dispatch]
  );

  const createItem = React.useCallback(
    (props: FieldItemProps<ReportField>) => {
      if (isMeasure(props.field)) {
        const field = props.field;
        return (
          <MeasureItem
            field={field}
            fields={dimensionsRef.current}
            globalConditions={conditionsAreaValues}
            formatCaption={handleMeasureFormatCaption}
            canBeRemoved
            onRemoveItem={removeItem}
            updateMeasure={updateItemConfig}
            onShowOptions={showFieldOptions}
          />
        );
      } else {
        const field = props.field;
        return (
          <DimensionItem
            field={field}
            fields={dimensionsRef.current}
            globalConditions={conditionsAreaValues}
            canBeRemoved
            onRemoveItem={removeItem}
            formatCaption={formatDimensionInFieldsCaption}
            updateField={updateItemConfig}
            onShowOptions={showFieldOptions}
          />
        );
      }
    },
    [conditionsAreaValues, handleMeasureFormatCaption, showFieldOptions, removeItem, updateItemConfig]
  );

  const handleRemove = React.useCallback(() => {
    setAnchorEl(null);
    if (currentField) {
      removeItem(currentField);
    }
  }, [currentField, removeItem]);

  const closePopup = React.useCallback(() => {
    setCurrentFieldId(undefined);
    setAnchorEl(null);
  }, []);

  const handleUpdateConfiguration = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      if (!currentField) {
        return;
      }
      updateItemConfig(currentField, changes);
      if (causeClosing) {
        setAnchorEl(null);
      }
    },
    [currentField, updateItemConfig]
  );

  const handleDimensionFieldCustomLabelChange = useCallback(
    (name: string | undefined) => {
      if (!isDimensionBased(currentField)) {
        return;
      }
      updateItemConfig(currentField, { customLabel: name });
    },
    [currentField, updateItemConfig]
  );

  const handleCanDropItem = React.useCallback((item: FieldWrapper<DraggableFieldType> | DraggableMetaType) => {
    return canDropItem(item, fieldsAreaRef.current);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Table Fields
      </Typography>
      <DropFieldContainer
        areaFieldType={AreaItemType.FIELDS}
        acceptedDropTypes={[AreaItemType.DIMENSION, AreaItemType.MEASURE, AreaItemType.CONDITIONS]}
        fields={fieldsAreaValues}
        canDropItem={handleCanDropItem}
        renderListItem={createItem}
        getKeyValue={getKeyValue}
        onAddItem={addItem}
        onRemoveItem={removeItem}
        onMoveItem={moveItem}
        createListItem={createTabularField}
      />

      <FieldOptionPopupContainer anchorEl={anchorEl} cancel={closePopup}>
        {currentField?.type === DimensionFieldType.DIMENSION && (
          <DimensionFieldOptions
            field={currentField}
            AggregationOptions={
              <AggregationOptionsComponent field={currentField} saveChanges={handleUpdateConfiguration} />
            }
            cancel={closePopup}
            onRemove={handleRemove}
            onUpdateConfiguration={handleUpdateConfiguration}
            onUpdateCustomLabel={handleDimensionFieldCustomLabelChange}
          />
        )}
        {currentField?.type === DimensionFieldType.MEASURE && (
          <MeasureFieldOptions
            measure={currentField}
            dimensionFields={dimensionFields}
            conditions={conditionsAreaValues}
            otherOptions={<OtherOptionsComponent measure={currentField} saveChanges={handleUpdateConfiguration} />}
            cancel={closePopup}
            saveChanges={handleUpdateConfiguration}
            onRemove={handleRemove}
          />
        )}
      </FieldOptionPopupContainer>
    </Box>
  );
};

export default FieldsDropContainer;

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  const guid = extractGuid(field);
  if (guid) {
    return guid;
  }
  const meta = extractMeta(field);
  if (meta) {
    return meta.name;
  }
  return "";
}

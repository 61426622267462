import objectHash from "object-hash";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectRefreshToken } from "../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { DelayObservable } from "../../../../utilities/Observers";
import { getAllFilters } from "../../pivot/preview/utilities/getAllConditions";
import { isPivotConfigurationValid } from "../../utils/isConfigurationValid";
import useDataLoadingViewer from "./useDataLoadingViewer";
import { selectConditions, selectArguments, selectValues } from "../../../../store/store";

export default function useChartStateViewer() {
  const dimensions = useSelector(selectDimensions);
  const conditionsAreaValues = useSelector(selectConditions);
  const argumentsAreaValues = useSelector(selectArguments);
  const valuesAreaValues = useSelector(selectValues);

  const { data, calculating, error, calculate, failedMeasures } = useDataLoadingViewer();
  const refreshToken = useSelector(selectRefreshToken);

  const configurationValidity = useMemo(() => {
    return isPivotConfigurationValid(conditionsAreaValues, argumentsAreaValues, valuesAreaValues, dimensions);
  }, [argumentsAreaValues, conditionsAreaValues, dimensions, valuesAreaValues]);

  const fieldsConfigured = useMemo(
    () => argumentsAreaValues.length > 0 && valuesAreaValues.length > 0,
    [argumentsAreaValues, valuesAreaValues]
  );

  const conditionsFiltersHash = useMemo(
    () => objectHash(getAllFilters(conditionsAreaValues, valuesAreaValues)),
    [conditionsAreaValues, valuesAreaValues]
  );

  useLayoutEffect(() => {
    const subscription = DelayObservable.subscribe(() => calculate());
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configurationValidity.valid && fieldsConfigured) {
      DelayObservable.next(Math.random().toString());
    }
  }, [conditionsFiltersHash, refreshToken, fieldsConfigured, configurationValidity.valid]);

  return { data: data?.result, calculating, error, fieldsConfigured, configurationValidity, failedMeasures };
}

import { Box, List, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DimensionField, GroupingField } from "../../../../../shared/reporting/api/biClient.types";
import SelectGroupFieldInput from "../../common/sorting/SelectGroupFieldInput";
import GroupFieldItem from "./GroupFieldItem";
import { useSelector } from "react-redux";
import { selectTabularGrouping, useAppDispatch } from "../../../../store/store.ts";
import { fieldsStateActions } from "../../../../store/thunks/fieldStatesThunks.ts";

interface Props {
  availableFields: DimensionField[];
}

export function GroupsDropFieldsContainer({ availableFields }: Props) {
  const groupAreaValues = useSelector(selectTabularGrouping);
  const dispatch = useAppDispatch();

  const [groups, setGroups] = useState<GroupingField[]>([]);
  const fieldsRef = useRef(groups);
  fieldsRef.current = groups;

  useEffect(() => {
    setGroups(groupAreaValues);
  }, [groupAreaValues, setGroups]);

  const onRemoveItem = useCallback(
    (field: GroupingField) => {
      dispatch(fieldsStateActions.removeGroup(field));
    },
    [dispatch]
  );

  const onDragMove = useCallback(
    (item: GroupingField, replaceItem: GroupingField) => {
      const values = [...fieldsRef.current];
      const fieldIndex = values.findIndex((v) => v.name === item.name);
      const toIndex = values.findIndex((v) => v.name === replaceItem.name);
      if (fieldIndex > -1) {
        values.splice(fieldIndex, 1);
        values.splice(toIndex, 0, item);
        setGroups(values);
      }
    },
    [setGroups]
  );

  const onEndMoveItem = useCallback(
    (field: GroupingField) => {
      const newIndex = fieldsRef.current.findIndex((v) => v.name === field.name);
      dispatch(fieldsStateActions.moveGroup({ field, newIndex }));
    },
    [dispatch]
  );

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: ".5rem",
      })}
    >
      <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.text.primary }}>
        Groups
      </Typography>
      <Stack
        sx={() => ({
          backgroundColor: "#F9F9F9",
          minHeight: "90px",
          border: "1px solid #E0E0E0",
          borderRadius: "2px",
          p: ".5rem",
          gap: ".5rem",
        })}
      >
        {groups.length > 0 && (
          <List sx={{ p: 0, display: "flex", flexDirection: "column", gap: "5px" }}>
            {groups.map((item) => {
              return (
                <GroupFieldItem
                  key={item.name}
                  field={item}
                  onRemoveItem={onRemoveItem}
                  onDragMove={onDragMove}
                  onEndMoveItem={onEndMoveItem}
                />
              );
            })}
          </List>
        )}
        <SelectGroupFieldInput availableFields={availableFields} />
      </Stack>
    </Box>
  );
}

export default GroupsDropFieldsContainer;

import { Stack, Switch, Typography } from "@mui/material";
import { useCallback } from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";
import { ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";

interface Props {
  settings: ChartStyleSettings;
}

export default function ShowLabelItem({ settings }: Props) {
  const showLabel = isShowArgumentLabel(settings);
  const dispatch = useAppDispatch();

  const updateArgumentAxis = useCallback(
    (showLabel: boolean) => {
      dispatch(fieldsStateActions.updateAxisArgumentSettings({ showLabel }));
    },
    [dispatch]
  );

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Show Label</Typography>
      <HorizontalFill />
      <Switch checked={showLabel} onChange={(_, value) => updateArgumentAxis(value)} />
    </Stack>
  );
}

export function isShowArgumentLabel(settings: ChartStyleSettings) {
  return getLinearSettings(settings)?.argumentAxis?.showLabel !== false;
}

import {
  AreaItemType,
  DimensionDescriptor,
  DimensionField,
  DimensionFieldType,
  FieldConfiguration,
  FieldConfigurationType,
  GroupingField,
  MeasureDescriptor,
  MeasureField,
  ReportField,
  TabularConfiguration,
  TabularGeneralSettings,
} from "../../../../../shared/reporting/api/biClient.types";
import { isDimensionBasedType } from "../fieldsHelper";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { ConditionField, SortField } from "../../Types";

export const createTabularReportConfiguration = (
  conditions: ConditionField[],
  fields: ReportField[],
  sorts: SortField[],
  grouping: GroupingField[],
  settings: TabularGeneralSettings
) => {
  const reportConfiguration: TabularConfiguration = {
    conditions: conditions.map((c) => c.config),
    fields: fields.map((field) => field.config),
    sort: sorts.map((s) => ({ name: s.config.name, ordering: s.config.ordering, caption: s.config.caption })),
    grouping: [...grouping],
    settings: { ...settings },
  };
  return reportConfiguration;
};

export const configurationToFields = (
  configs: FieldConfiguration[],
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[]
) => {
  try {
    const tableFields = configs
      .map((config) => {
        if (isDimensionBasedType(config.type)) {
          const dimensionMeta = dimensions.find((d) => d.name === config.name);
          if (dimensionMeta) {
            return <DimensionField>{
              type: DimensionFieldType.DIMENSION,
              meta: dimensionMeta,
              config,
              areaItemType: AreaItemType.FIELDS,
            };
          } else {
            //eslint-disable-next-line no-console
            console.log(`Field ${config.name} is not found in the dimensions' list`);
            return undefined;
          }
        } else if (config.type === FieldConfigurationType.Measure || config.type === FieldConfigurationType.Irr) {
          const measure = measures.find((m) => m.name === config.name);
          if (measure) {
            return <MeasureField>{
              type: DimensionFieldType.MEASURE,
              meta: measure,
              areaItemType: AreaItemType.FIELDS,
              config,
            };
          } else {
            //eslint-disable-next-line no-console
            console.log(`Measure ${config.name} is not found in the measures' list`);
            return undefined;
          }
        }
        return undefined;
      })
      .filter((field): field is ReportField => !!field);
    return tableFields;
  } catch {
    return [];
  }
};

export const configurationToGrouping = (grouping: GroupingField[]) => {
  try {
    const groups = cloneDeep(grouping);
    return groups;
  } catch {
    return [];
  }
};

export const configurationToSettings = (settings: TabularGeneralSettings) => {
  return cloneDeep(settings);
};

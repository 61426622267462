import objectHash from "object-hash";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DimensionField, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { PivotColumnResponse } from "../../../../../api/biApi.types";
import { selectRefreshToken } from "../../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../../store/metaDataSlice";
import { DelayObservable } from "../../../../../utilities/Observers";
import getTrackedMeasureProperties from "../../../utils/getTrackedMeasureProperties";
import { isPivotConfigurationValid } from "../../../utils/isConfigurationValid";
import { getAllFilters } from "../utilities/getAllConditions";
import useDataLoadingBuilder from "./useDataLoadingBuilder";
import {
  selectColumns,
  selectConditions,
  selectRows,
  selectValues,
  selectPivotSettingsState,
  selectSorts,
} from "../../../../../store/store";

export type PreviewGridStateType = ReturnType<typeof useGridStateBuilder>;

export default function useGridStateBuilder() {
  const dimensions = useSelector(selectDimensions);
  const [columns, setColumns] = useState<PivotColumnResponse[]>([]);

  const conditionsAreaValues = useSelector(selectConditions);
  const valuesAreaValues = useSelector(selectValues);
  const rowsAreaValues = useSelector(selectRows);
  const columnsAreaValues = useSelector(selectColumns);
  const sortAreaValues = useSelector(selectSorts);
  const settingsAreaValues = useSelector(selectPivotSettingsState);
  const { calculate, error, calculating, pivot, failedMeasures, handleInvalidConfiguration } = useDataLoadingBuilder();
  const refreshToken = useSelector(selectRefreshToken);

  const measuresHash = useMemo(() => objectHash(getTrackedMeasureProperties(valuesAreaValues)), [valuesAreaValues]);

  const conditionsFiltersHash = useMemo(
    () => objectHash(getAllFilters(conditionsAreaValues, valuesAreaValues)),
    [conditionsAreaValues, valuesAreaValues]
  );

  const rowsAreaHash = useMemo(() => rowFieldHash(rowsAreaValues), [rowsAreaValues]);
  const columnsAreaHash = useMemo(() => generalFieldHash(columnsAreaValues), [columnsAreaValues]);
  const configurationValid = useMemo(() => {
    const fields = rowsAreaValues.concat(columnsAreaValues).concat(rowsAreaValues);
    return isPivotConfigurationValid(conditionsAreaValues, fields, valuesAreaValues, dimensions);
  }, [columnsAreaValues, conditionsAreaValues, dimensions, rowsAreaValues, valuesAreaValues]);

  useLayoutEffect(() => {
    const subscription = DelayObservable.subscribe(calculate);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (configurationValid.valid) {
      DelayObservable.next(refreshToken);
    } else {
      handleInvalidConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conditionsFiltersHash,
    rowsAreaHash,
    columnsAreaHash,
    measuresHash,
    refreshToken,
    settingsAreaValues,
    sortAreaValues,
    configurationValid.valid,
  ]);

  useEffect(() => {
    if (!pivot) {
      setColumns([]);
    } else {
      const gridColumns = pivot.columns;
      setColumns(gridColumns);
    }
  }, [pivot]);

  return { pivot, columns, error, calculating, configurationValid, failedMeasures };
}

function rowFieldHash(fields: DimensionField[]) {
  return objectHash(
    fields.map((v) => ({
      n: v.meta.name,
      g: v.config.grouping,
      df: v.config.displayValues,
      showTotals: v.config.style?.showTotals,
      showLogoIcon: v.config.showLogoIcon,
      customLabel: v.config.customLabel,
      hideBlankValues: v.config.hideBlankValues,
      hideZeroValues: v.config.hideZeroValues,
    }))
  );
}

export function generalFieldHash(fields: ReportField[]) {
  return objectHash(
    fields.map((v) => ({
      n: v.meta.name,
      g: v.config.grouping,
      df: v.config.displayValues,
      showTotals: v.config.style?.showTotals,
      customLabel: v.config.customLabel,
      hideBlankValues: v.config.hideBlankValues,
      hideZeroValues: v.config.hideZeroValues,
    }))
  );
}

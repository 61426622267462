import { Grid2, Typography } from "@mui/material";
import React from "react";
import { ChartStyleSettings, ReportField, SeriesSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { getMeasureCaption } from "../../../pivot/table/values/helper";
import BarWidthItem from "./BarWidthItem";
import SerieLineTypeItem from "./SerieLineTypeItem";
import SerieTypeItem from "./SerieTypeItem";
import SerieValueAxis from "./SerieValueAxis";
import ShowLabelConnectorItem from "./ShowLabelConnectorItem";
import ShowLabelItem from "./ShowLabelItem";
import ShowPointsItem from "./ShowPointsItem";

interface Props {
  settings: ChartStyleSettings;
  values: ReportField[];
  serie: SeriesSettings;
}

export default function SerieItem({ settings, values, serie }: Props) {
  const caption = React.useMemo(() => {
    const measure = values.find((v) => v.config.guid === serie.name);
    return getMeasureCaption(measure);
  }, [serie.name, values]);

  return (
    <Grid2 container sx={{ flexDirection: "column", gap: 1, width: "100%" }}>
      <Typography variant="subtitle2">{caption}</Typography>
      <SerieTypeItem settings={settings} serie={serie} />
      <BarWidthItem settings={settings} serie={serie} />
      <SerieLineTypeItem settings={settings} serie={serie} />
      <SerieValueAxis settings={settings} serie={serie} />
      <ShowPointsItem settings={settings} serie={serie} />
      <ShowLabelItem settings={settings} serie={serie} />
      <ShowLabelConnectorItem settings={settings} serie={serie} />
    </Grid2>
  );
}

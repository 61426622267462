import { Stack, Switch, Typography } from "@mui/material";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";

interface Props {
  settings: ChartStyleSettings;
}

export default function ToolTipItem({ settings }: Props) {
  const showTooltip = isShowToolTip(settings);
  const dispatch = useAppDispatch();
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Show Tooltip</Typography>
      <HorizontalFill />
      <Switch
        checked={showTooltip}
        onChange={(_, value) => dispatch(fieldsStateActions.updateSettings({ settings: { showTooltip: value } }))}
      />
    </Stack>
  );
}

export function isShowToolTip(settings: ChartStyleSettings) {
  return settings?.showTooltip === true;
}

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { Box, Grid2, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import SwitchField from "../../../../common/SwitchField";
import { isDimensionBased } from "../../../utils/fieldsHelper";
import { useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
interface Props {
  field: ReportField;
}

export default function TextField({ field }: Props) {
  const dispatch = useAppDispatch();

  const onChangeShowTotals = React.useCallback(
    (showTotals: boolean) => {
      if (isDimensionBased(field)) {
        const style = !field.config.style ? {} : cloneDeep(field.config.style);
        style.showTotals = showTotals;
        dispatch(fieldsStateActions.updateRowConfig({ field, changes: { style } }));
      }
    },
    [field, dispatch]
  );

  const onUpdateFontStyle = React.useCallback(
    (fontStyle: string[]) => {
      if (isDimensionBased(field)) {
        const style = !field.config.style ? {} : cloneDeep(field.config.style);
        style.fontStyle = fontStyle;
        dispatch(fieldsStateActions.updateRowConfig({ field, changes: { style } }));
      }
    },
    [field, dispatch]
  );

  return (
    <Grid2
      container
      sx={{
        flexDirection: "column",
        borderBottom: "1px dashed #E0E0E0",
        width: "100%",
      }}
    >
      <Typography variant="subtitle2" fontWeight={500} sx={{ color: "text.primary" }}>
        {field.config.customLabel || field.meta.caption}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          py: ".7rem",
          gap: 1,
        }}
      >
        <Grid2
          container
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Grid2 container sx={{ alignItems: "center", width: "100%" }}>
            <Typography sx={{ color: "text.secondary" }}>Text</Typography>
            <HorizontalFill />
            <ToggleButtonGroup
              value={field.config.style?.fontStyle}
              sx={{
                ".MuiButtonBase-root": {
                  border: "none",
                  py: 0,
                  px: 0.5,
                },
              }}
              onChange={(_, value: string[]) => onUpdateFontStyle(value)}
            >
              <ToggleButton value={"bold"}>
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton value={"italic"}>
                <FormatItalicIcon />
              </ToggleButton>
              <ToggleButton value={"underlined"}>
                <FormatUnderlinedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid2>
        </Grid2>
        <SwitchField
          label="Show Totals"
          checked={field.config.style?.showTotals === true}
          onChange={onChangeShowTotals}
          typographyProps={{ sx: { color: "text.secondary" } }}
        />
      </Box>
    </Grid2>
  );
}

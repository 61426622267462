import { deepClone } from "@mui/x-data-grid/utils/utils";
import {
  AreaItemType,
  BiAggregation,
  DimensionDescriptor,
  DimensionField,
  DimensionFieldType,
  FieldConfiguration,
  FormatType,
  MeasureDateRange,
  MeasureDescriptor,
  PivotConfiguration,
  PivotGeneralSettings,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types";
import { valueToConfiguration } from "./configurations";
import { ConditionField, SortField } from "../../Types";

export const createPivotReportConfiguration = (
  conditions: ConditionField[],
  rows: DimensionField[],
  columns: DimensionField[],
  values: ReportField[],
  sorts: SortField[],
  settings: PivotGeneralSettings
) => {
  const reportConfiguration: PivotConfiguration = {
    conditions: conditions.map((c) => c.config),
    rows: rows.map((r) => r.config),
    columns: columns.map((c) => c.config),
    values: values.map(valueToConfiguration),
    sort: sorts.map((s) => s.config),
    settings: { ...settings },
  };
  return reportConfiguration;
};

export const configurationToRows = (configRows: FieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const rows = configRows
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: DimensionField = {
            type: DimensionFieldType.DIMENSION,
            config,
            meta: dimension,
            areaItemType: AreaItemType.ROWS,
          };
          return copied;
        } else {
          //eslint-disable-next-line no-console
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is DimensionField => !!c);
    return rows;
  } catch {
    return [];
  }
};

export const configurationToColumns = (configColumns: FieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const columns = configColumns
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: DimensionField = {
            type: DimensionFieldType.DIMENSION,
            config,
            meta: dimension,
            areaItemType: AreaItemType.COLUMNS,
          };
          return copied;
        } else {
          //eslint-disable-next-line no-console
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is DimensionField => !!c);
    return columns;
  } catch {
    return [];
  }
};

export const configurationToValues = (
  configValues: FieldConfiguration[],
  measures: MeasureDescriptor[],
  dimensions: DimensionDescriptor[]
) => {
  try {
    const values = configValues
      .map((config) => {
        const measure = measures.find((d) => d.name === config.name);
        if (measure) {
          const copied: ReportField = {
            type: DimensionFieldType.MEASURE,
            config: {
              ...config,
              format:
                config.format ||
                (measure?.defaultFormat?.formatType === FormatType.Numeric ? measure?.defaultFormat : undefined),
              dateRange: config.dateRange || MeasureDateRange.EndingBalance,
            },
            meta: measure,
            areaItemType: AreaItemType.VALUES,
          };
          return copied;
        }

        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: DimensionField = {
            type: DimensionFieldType.DIMENSION,
            config: {
              ...config,
              aggregation: config.aggregation || BiAggregation.Count,
              dateRange: config.dateRange || MeasureDateRange.EndingBalance,
            },
            meta: dimension,
            areaItemType: AreaItemType.VALUES,
          };
          return copied;
        }
        //eslint-disable-next-line no-console
        console.log(`Field ${config.name} is not found in the measures' list`);
        return undefined;
      })
      .filter((c) => c !== undefined);
    return values;
  } catch {
    return [];
  }
};

export const configurationToSettings = (settings: PivotGeneralSettings): PivotGeneralSettings => {
  try {
    const copiedSettings = deepClone(settings) as PivotGeneralSettings;
    return copiedSettings;
  } catch {
    return {
      rowsGrandTotal: false,
      hideBlankRows: false,
      hideZeroRows: false,
      hideBlankColumns: false,
      hideZeroColumns: false,
    };
  }
};

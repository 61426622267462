import { createAsyncThunk } from "@reduxjs/toolkit";
import { DimensionField, FieldConfiguration } from "../../../shared/reporting/api/biClient.types";
import { PivotFieldsActionTypes } from "../fieldState/types";
import { pivotFieldsActions } from "../fieldState/pivotFieldsSlice.ts";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk.ts";
import { SortField } from "../../components/builder/Types.ts";

const getSortField = (field: DimensionField, sorts: SortField[]) => {
  return sorts.find((s) => s.meta.name === field.meta.name);
};

const createPivotAction = <T extends keyof PivotFieldsActionTypes>(
  type: T,
  payload: PivotFieldsActionTypes[T]["payload"]
) => ({
  type: `pivotFields/${type}`,
  payload,
});

const setRows = createAsyncThunk<void, DimensionField[]>(
  "pivotFields/setRows",
  (rows: DimensionField[], { dispatch }) => {
    dispatch(createPivotAction("setRows", rows));
  }
);

const addRow = createAsyncThunk<void, { field: DimensionField; index: number }>(
  "pivotFields/addRow",
  (payload: { field: DimensionField; index: number }, { dispatch }) => {
    dispatch(createPivotAction("addRow", payload));
  }
);

const removeRow = createTypedAsyncThunk<void, DimensionField>(
  "pivotFields/removeRow",
  (field: DimensionField, { dispatch, getState }) => {
    dispatch(createPivotAction("removeRow", field));
    const sortingField = getSortField(field, getState().fieldsState.pivotFieldsReducer.sorts);
    if (sortingField) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        pivotFieldsActions.removeSortingByMeta(sortingField.meta)
      );
    }
  }
);

const moveRow = createAsyncThunk<void, { field: DimensionField; newIndex: number }>(
  "pivotFields/moveRow",
  (payload: { field: DimensionField; newIndex: number }, { dispatch }) => {
    dispatch(createPivotAction("moveRow", payload));
  }
);

const updateRow = createAsyncThunk<void, { field: DimensionField; changes: Partial<DimensionField> }>(
  "pivotFields/updateRow",
  (payload: { field: DimensionField; changes: Partial<DimensionField> }, { dispatch }) => {
    dispatch(createPivotAction("updateRow", payload));
  }
);

const updateRowConfig = createTypedAsyncThunk<void, { field: DimensionField; changes: Partial<FieldConfiguration> }>(
  "pivotFields/updateRowConfig",
  (payload: { field: DimensionField; changes: Partial<FieldConfiguration> }, { dispatch, getState }) => {
    dispatch(createPivotAction("updateRowConfig", payload));
    const sortingField = getSortField(payload.field, getState().fieldsState.pivotFieldsReducer.sorts);
    if (sortingField && payload.changes.customLabel !== payload.field.config?.customLabel) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        pivotFieldsActions.updateSortingConfig({
          field: sortingField,
          changes: { caption: payload.changes.customLabel },
        })
      );
    }
  }
);

// Column thunks
const setColumns = createAsyncThunk<void, DimensionField[]>(
  "pivotFields/setColumns",
  (columns: DimensionField[], { dispatch }) => {
    dispatch(createPivotAction("setColumns", columns));
  }
);

const addColumn = createAsyncThunk<void, { field: DimensionField; index: number }>(
  "pivotFields/addColumn",
  (payload: { field: DimensionField; index: number }, { dispatch }) => {
    dispatch(createPivotAction("addColumn", payload));
  }
);

const removeColumn = createTypedAsyncThunk<void, DimensionField>(
  "pivotFields/removeColumn",
  (field: DimensionField, { dispatch, getState }) => {
    dispatch(createPivotAction("removeColumn", field));
    const sortingField = getSortField(field, getState().fieldsState.pivotFieldsReducer.sorts);
    if (sortingField) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        pivotFieldsActions.removeSortingByMeta(sortingField.meta)
      );
    }
  }
);

const moveColumn = createAsyncThunk<void, { field: DimensionField; newIndex: number }>(
  "pivotFields/moveColumn",
  (payload: { field: DimensionField; newIndex: number }, { dispatch }) => {
    dispatch(createPivotAction("moveColumn", payload));
  }
);

const updateColumn = createAsyncThunk<void, { field: DimensionField; changes: Partial<DimensionField> }>(
  "pivotFields/updateColumn",
  (payload: { field: DimensionField; changes: Partial<DimensionField> }, { dispatch }) => {
    dispatch(createPivotAction("updateColumn", payload));
  }
);

const updateColumnConfig = createTypedAsyncThunk<void, { field: DimensionField; changes: Partial<FieldConfiguration> }>(
  "pivotFields/updateColumnConfig",
  (payload: { field: DimensionField; changes: Partial<FieldConfiguration> }, { dispatch, getState }) => {
    dispatch(createPivotAction("updateColumnConfig", payload));
    const sortingField = getSortField(payload.field, getState().fieldsState.pivotFieldsReducer.sorts);
    if (sortingField && payload.changes.customLabel !== payload.field.config?.customLabel) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        pivotFieldsActions.updateSortingConfig({
          field: sortingField,
          changes: { caption: payload.changes.customLabel },
        })
      );
    }
  }
);

export const pivotFieldsStateThunks = {
  setRows,
  addRow,
  removeRow,
  moveRow,
  updateRow,
  updateRowConfig,
  setColumns,
  addColumn,
  removeColumn,
  moveColumn,
  updateColumn,
  updateColumnConfig,
};

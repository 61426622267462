import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AreaItemType,
  DimensionField,
  FieldConfiguration,
  GroupingField,
  Ordering,
  ReportField,
} from "../../../shared/reporting/api/biClient.types";
import { SortField } from "../../components/builder/Types";
import { TabularFieldsActionTypes } from "../fieldState/types";
import { isAggregatedType, isDimensionBased } from "../../components/builder/utils/fieldsHelper.ts";
import { createTypedAsyncThunk } from "../utils/createTypedAsyncThunk.ts";
import { getGroupMetaNames } from "../../components/builder/common/utilities/fieldsState.ts";
import { tabularFieldsActions } from "../fieldState/tabularFieldsSlice.ts";

const createTabularAction = <T extends keyof TabularFieldsActionTypes>(
  type: T,
  payload?: TabularFieldsActionTypes[T]["payload"]
) => ({
  type: `tabularFields/${type}`,
  payload,
});

const setFields = createAsyncThunk<void, ReportField[]>(
  "tabularFields/setFields",
  (fields: ReportField[], { dispatch }) => {
    dispatch(createTabularAction("setFields", fields));
  }
);

const addField = createAsyncThunk<void, { field: ReportField; index: number }>(
  "tabularFields/addField",
  (payload: { field: ReportField; index: number }, { dispatch }) => {
    dispatch(createTabularAction("addField", payload));
  }
);

const removeField = createAsyncThunk<void, ReportField>(
  "tabularFields/removeField",
  (field: ReportField, { dispatch }) => {
    dispatch(createTabularAction("removeField", field));
  }
);

const moveField = createAsyncThunk<void, { field: ReportField; newIndex: number }>(
  "tabularFields/moveField",
  (payload: { field: ReportField; newIndex: number }, { dispatch }) => {
    dispatch(createTabularAction("moveField", payload));
  }
);

const updateFieldConfig = createTypedAsyncThunk<void, { field: ReportField; changes: Partial<FieldConfiguration> }>(
  "tabularFields/updateFieldConfig",
  (payload: { field: ReportField; changes: Partial<FieldConfiguration> }, { dispatch, getState }) => {
    const { field, changes } = payload;
    dispatch(createTabularAction("updateFieldConfig", payload));
    if (!isDimensionBased(field)) {
      return;
    }
    const isAggregated = isAggregatedType(changes);
    const state = getState();
    const groupingField = state.fieldsState.tabularFieldsReducer.grouping.find((g) => g.name === field.config.guid);
    if (groupingField && isAggregated) {
      dispatch(removeGroup(groupingField));
    }

    const sortingField = state.fieldsState.tabularFieldsReducer.sorts.find((s) => s.meta.name === field.meta.name);
    if (!sortingField) {
      return;
    }
    if (field.config?.customLabel !== changes.customLabel) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        tabularFieldsActions.updateSortingConfig({
          field: sortingField,
          changes: { caption: changes.customLabel },
        })
      );
    }
    if (isAggregated) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.removeSorting(sortingField));
    }
  }
);

const updateMeasureField = createAsyncThunk<void, { field: ReportField; changes: Partial<ReportField> }>(
  "tabularFields/updateMeasureField",
  (payload: { field: ReportField; changes: Partial<ReportField> }, { dispatch }) => {
    dispatch(createTabularAction("updateMeasureField", payload));
  }
);

const updateFieldsOrder = createAsyncThunk<void, string[]>(
  "tabularFields/updateFieldsOrder",
  (order: string[], { dispatch }) => {
    dispatch(createTabularAction("updateFieldsOrder", order));
  }
);

const setGrouping = createAsyncThunk<void, GroupingField[]>(
  "tabularFields/setGrouping",
  (groups: GroupingField[], { dispatch }) => {
    dispatch(createTabularAction("setGrouping", groups));
  }
);

const updateGroup = createAsyncThunk<void, { group: GroupingField; changes: Partial<GroupingField> }>(
  "tabularFields/updateGroup",
  (payload: { group: GroupingField; changes: Partial<GroupingField> }, { dispatch }) => {
    dispatch(createTabularAction("updateGroup", payload));
  }
);

const addGroup = createTypedAsyncThunk<void, GroupingField>(
  "tabularFields/addGroup",
  (group: GroupingField, { dispatch, getState }) => {
    const state = getState();

    const field = state.fieldsState.tabularFieldsReducer.fields.find((f) => f.config.guid === group.name);
    if (field && field.meta && isDimensionBased(field)) {
      const optimisticGroups = [...state.fieldsState.tabularFieldsReducer.grouping, group];
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.removeSortingByMeta(field.meta));
      dispatch(
        addGroupSorting({
          field,
          groupMetaNames: getGroupMetaNames(optimisticGroups, state.fieldsState.tabularFieldsReducer.fields),
        })
      );
    }
    dispatch(createTabularAction("addGroup", group));
  }
);

const removeGroup = createTypedAsyncThunk<void, GroupingField>(
  "tabularFields/removeGroup",
  (group: GroupingField, { dispatch, getState }) => {
    const state = getState();
    const field = state.fieldsState.tabularFieldsReducer.fields.find((f) => f.config.guid === group.name);
    if (field && field.meta && isDimensionBased(field)) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      dispatch(tabularFieldsActions.removeSortingByMeta(field.meta));
    }

    dispatch(createTabularAction("removeGroup", group));
  }
);

const moveGroup = createAsyncThunk<void, { field: GroupingField; newIndex: number }>(
  "tabularFields/moveGroup",
  (payload: { field: GroupingField; newIndex: number }, { dispatch }) => {
    dispatch(createTabularAction("moveGroup", payload));
  }
);

const addGroupSorting = createAsyncThunk<void, { field: DimensionField; groupMetaNames: string[] }>(
  "tabularFields/addGroupSorting",
  (payload: { field: DimensionField; groupMetaNames: string[] }, { dispatch }) => {
    const { field, groupMetaNames } = payload;
    const conditionField: SortField = {
      meta: field.meta,
      config: {
        name: field.meta.name,
        ordering: Ordering.Ascending,
        caption: field.config.customLabel || field.meta.caption,
        isGroupField: true,
      },
      areaItemType: AreaItemType.SORTS,
    };
    dispatch(createTabularAction("addGroupSorting", { field: conditionField, groupMetaNames }));
  }
);

export const tabularFieldsStateThunks = {
  setFields,
  addField,
  removeField,
  moveField,
  updateFieldConfig,
  updateMeasureField,
  updateFieldsOrder,
  setGrouping,
  updateGroup,
  addGroup,
  removeGroup,
  moveGroup,
  addGroupSorting,
};

import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ChartStyleSettings, LegendSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { useSelector } from "react-redux";
import { selectChartSettingsState, useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

export default function VerticalAlignmentItem() {
  const settings = useSelector(selectChartSettingsState);
  const alignment = React.useMemo(() => getVerticalAlignment(settings), [settings]);
  const dispatch = useAppDispatch();

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Vertical Alignment</Typography>
      <HorizontalFill />
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={alignment}
        onChange={(_, value: LegendSettings["verticalAlignment"]) => {
          if (value !== null) dispatch(fieldsStateActions.updateLegendSettings({ verticalAlignment: value }));
        }}
      >
        <ToggleButton value="top" sx={{ py: 0.3, fontWeight: 400 }}>
          Top
        </ToggleButton>
        <ToggleButton value="bottom" sx={{ py: 0.3, fontWeight: 400 }}>
          Bottom
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

export function getVerticalAlignment(settings: ChartStyleSettings) {
  return settings.legend?.verticalAlignment || "top";
}

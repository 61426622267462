import {
  AreaItemType,
  ChartConfiguration,
  ChartStyleSettings,
  DimensionDescriptor,
  DimensionField,
  DimensionFieldType,
  FieldConfiguration,
  ReportField,
  ReportType,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { ConditionField, SortField } from "../../Types";
import { valueToConfiguration } from "./configurations";

export const chartRelatedReportTypes = [
  ReportType.AreaChart,
  ReportType.BarChart,
  ReportType.DoughnutChart,
  ReportType.LineChart,
  ReportType.PieChart,
  ReportType.TreeMapChart,
];

export const createChartReportConfiguration = (
  conditions: ConditionField[],
  args: DimensionField[],
  values: ReportField[],
  sorts: SortField[],
  settings: ChartStyleSettings
) => {
  const reportConfiguration: ChartConfiguration = {
    conditions: conditions.map((c) => c.config),
    arguments: args.map((field) => field.config),
    values: values.map(valueToConfiguration),
    sort: sorts.map((s) => s.config),
    settings: cloneDeep(settings),
  };
  return reportConfiguration;
};

export const configurationToArguments = (configArgs: FieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const args = configArgs
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: DimensionField = {
            type: DimensionFieldType.DIMENSION,
            config,
            meta: dimension,
            areaItemType: AreaItemType.ARGUMENTS,
          };
          return copied;
        } else {
          // eslint-disable-next-line no-console
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is DimensionField => !!c);
    return args;
  } catch {
    return [];
  }
};

import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ChartStyleSettings, LegendSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { useSelector } from "react-redux";
import { selectChartSettingsState, useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

export default function HorizontalAlignmentItem() {
  const settings = useSelector(selectChartSettingsState);
  const alignment = React.useMemo(() => getHorizontalAlignment(settings), [settings]);
  const dispatch = useAppDispatch();

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Horizontal Alignment</Typography>
      <HorizontalFill />
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={alignment}
        onChange={(_, value: LegendSettings["horizontalAlignment"]) => {
          if (value !== null) dispatch(fieldsStateActions.updateLegendSettings({ horizontalAlignment: value }));
        }}
      >
        <ToggleButton value="left" sx={{ py: 0.3, fontWeight: 400 }}>
          Left
        </ToggleButton>
        <ToggleButton value="center" sx={{ py: 0.3, fontWeight: 400 }}>
          Center
        </ToggleButton>
        <ToggleButton value="right" sx={{ py: 0.3, fontWeight: 400 }}>
          Right
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

export function getHorizontalAlignment(settings: ChartStyleSettings) {
  return settings.legend?.horizontalAlignment || "right";
}

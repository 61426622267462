import { Box, Divider, Grid2, Tab, Tabs } from "@mui/material";
import { useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { getAvailableGroupFields } from "../../common/utilities/groupFieldStateHelper";
import { getAvailableSortFields } from "../../common/utilities/sortFieldStateHelper";
import ConditionsDropFieldsContainer from "../../pivot/table/conditions/ConditionsDropFieldsContainer";
import { TableComponentProps } from "../../Types";
import SettingsContainer from "../settings/SettingsContainer";
import FieldsDropContainer from "./FieldsDropContainer";
import FormatContainer from "./FormatContainer";
import GroupsDropFieldsContainer from "./GroupsDropFieldsContainer";
import SortDropsFieldsContainer from "./SortDropsFieldsContainer";
import TableDragLayer from "./TableDragLayer";
import { useSelector } from "react-redux";
import { selectSorts, selectTabularFields, selectTabularGrouping } from "../../../../store/store.ts";

export const TableComponent = ({ ReportTypeSelector }: TableComponentProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const sortingAreaValues = useSelector(selectSorts);
  const fieldsAreaValues = useSelector(selectTabularFields);
  const groupingAreaValues = useSelector(selectTabularGrouping);
  const availableSortFields = useMemo(
    () => getAvailableSortFields(fieldsAreaValues, sortingAreaValues),
    [fieldsAreaValues, sortingAreaValues]
  );

  const availableGroupFields = useMemo(
    () => getAvailableGroupFields(fieldsAreaValues, groupingAreaValues),
    [fieldsAreaValues, groupingAreaValues]
  );

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid2 px="1.3rem">
        {ReportTypeSelector}
        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value: number) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid2>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer disableEqualitySelection={true} />
          <FieldsDropContainer />
          <SortDropsFieldsContainer availableFields={availableSortFields} />
          <GroupsDropFieldsContainer availableFields={availableGroupFields} />
          <TableDragLayer />
        </ScrollableFlexContainer>
      )}
      {selectedTab === 1 && <SettingsContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Box>
  );
};

export default TableComponent;

import objectHash from "object-hash";
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { BiAggregation, ItemDataType, ReportField } from "../../../../shared/reporting/api/biClient.types";
import { currentReportActions } from "../../../store/currentReportSlice";
import { selectMeasures } from "../../../store/metaDataSlice";
import { SupportedAggregationsContextProvider } from "../common/fields/contexts/ExportStateContext";
import useCrossFiltering from "../common/hooks/useCrossFiltering";
import useDeferredDictionaryLoading from "../common/hooks/useDeferredDictionaryLoading";
import { updateCalculateByFieldForCustomMeasures } from "../utils/MeasureUtils";
import { selectConditions, selectPivotFieldsState, useAppDispatch } from "../../../store/store.ts";
import { createPivotReportConfiguration } from "../utils/configuration/pivotConfigurations.ts";
import { fieldsStateActions } from "../../../store/thunks/fieldStatesThunks.ts";
import { ConditionField } from "../Types.ts";

export default function PivotContainer({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const measures = useSelector(selectMeasures);
  const fieldsState = useSelector(selectPivotFieldsState);
  const conditionsAreaValues = useSelector(selectConditions);
  const sortingHash = useMemo(() => objectHash(fieldsState.sorts), [fieldsState.sorts]);
  const fieldsStateRef = useRef(fieldsState);
  fieldsStateRef.current = fieldsState;

  useEffect(
    () =>
      updateCalculateByFieldForCustomMeasures(
        {
          updateMeasure: (field, changes) => dispatch(fieldsStateActions.updateMeasure({ field, changes })),
          updateMeasureConfig: (field, changes) => dispatch(fieldsStateActions.updateMeasureConfig({ field, changes })),
          removeMeasure: (field) => dispatch(fieldsStateActions.removeMeasure(field)),
          values: fieldsStateRef.current.values,
        },
        measures
      ),
    [measures, dispatch]
  );

  useEffect(() => {
    const configuration = createPivotReportConfiguration(
      fieldsState.conditions,
      fieldsState.rows,
      fieldsState.columns,
      fieldsState.values,
      fieldsState.sorts,
      fieldsState.settings
    );
    dispatch(currentReportActions.updateReportConfiguration(configuration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldsState.conditions,
    fieldsState.rows,
    fieldsState.columns,
    fieldsState.values,
    fieldsState.settings,
    sortingHash,
  ]);

  const updateItem = useCallback(
    (field: ConditionField, changes: Partial<ConditionField>) => {
      dispatch(fieldsStateActions.updateCondition({ field, changes }));
    },
    [dispatch]
  );

  useCrossFiltering();
  useDeferredDictionaryLoading({ values: conditionsAreaValues, updateItem });

  return (
    <SupportedAggregationsContextProvider
      aggregations={supportedAggregations}
      getSupportedAggregationTypes={getSupportedAggregationTypes}
    >
      {children}
    </SupportedAggregationsContextProvider>
  );
}

const supportedAggregations = [
  BiAggregation.Average,
  BiAggregation.Count,
  BiAggregation.CountDistinct,
  BiAggregation.Max,
  BiAggregation.Min,
  BiAggregation.Sum,
];

const getSupportedAggregationTypes = (field: ReportField) => {
  const items = [BiAggregation.Count, BiAggregation.CountDistinct];

  if (field.meta.type === ItemDataType.Numeric) {
    items.push(BiAggregation.Average, BiAggregation.Min, BiAggregation.Max, BiAggregation.Sum);
  }

  return items;
};

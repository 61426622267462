import React from "react";
import { useSelector } from "react-redux";
import { selectRefreshToken } from "../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { isTabularConfigurationValid } from "../../utils/isConfigurationValid";
import { getColumns, getConditionsHash, getSorting } from "../utilities/gridStateHelper";
import { gridDataStateReducer, initialGridDataState } from "./gridDataState";
import useDataLoadingViewer from "./useDataLoadingViewer";
import { selectSorts, selectTabularFields, selectConditions } from "../../../../store/store";

export default function useGridStateViewer() {
  const conditionsAreaValues = useSelector(selectConditions);
  const fieldsAreaValues = useSelector(selectTabularFields);
  const sortingAreaValues = useSelector(selectSorts);
  const dimensions = useSelector(selectDimensions);
  const dimensionsRef = React.useRef(dimensions);
  dimensionsRef.current = dimensions;

  const [state, dispatch] = React.useReducer(gridDataStateReducer, initialGridDataState);
  const { loadData, cancelDataLoading, failedMeasures, handleInvalidConfiguration } = useDataLoadingViewer(dispatch);
  const refreshToken = useSelector(selectRefreshToken);

  const loading = state.loading > 0;
  const groupTotalsLoading = state.groupTotalsLoading > 0;

  const columns = React.useMemo(() => getColumns(fieldsAreaValues, state.columns), [fieldsAreaValues, state.columns]);
  const sorting = React.useMemo(
    () => getSorting(sortingAreaValues, fieldsAreaValues),
    [sortingAreaValues, fieldsAreaValues]
  );

  const conditionsHash = React.useMemo(
    () => getConditionsHash(conditionsAreaValues, fieldsAreaValues),
    [conditionsAreaValues, fieldsAreaValues]
  );

  const configurationValidity = React.useMemo(() => {
    return isTabularConfigurationValid(conditionsAreaValues, fieldsAreaValues, dimensionsRef.current);
  }, [conditionsAreaValues, fieldsAreaValues]);

  React.useEffect(() => {
    if (configurationValidity.valid) {
      loadData();
    } else {
      handleInvalidConfiguration();
      cancelDataLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionsHash, refreshToken, configurationValidity.valid]);

  return {
    rows: state.rows,
    columns,
    sorting,
    groupTotals: state.groupTotals,
    grandTotals: state.grandTotals,
    totalCount: state.totalCount,
    loading,
    groupTotalsLoading,
    error: state.error,
    configurationValidity,
    failedMeasures,
    failedGroupTotalMeasures: undefined,
  };
}

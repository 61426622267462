import {
  ChartConfiguration,
  DimensionDescriptor,
  MeasureDescriptor,
  PivotConfiguration,
  Report,
  ReportConfiguration,
  ReportType,
  TabularConfiguration,
} from "../../../shared/reporting/api/biClient.types";
import { AppDispatch } from "../store";
import {
  chartRelatedReportTypes,
  configurationToArguments,
} from "../../components/builder/utils/configuration/chartConfiguration";
import { addMissingGroupSorts, setGroupingFlag } from "../../components/builder/common/utilities/sortFieldStateHelper";
import {
  configurationToConditions,
  configurationToSorts,
} from "../../components/builder/utils/configuration/configurations";
import {
  configurationToFields,
  configurationToGrouping,
  configurationToSettings as tabularConfigurationToSettings,
} from "../../components/builder/utils/configuration/tabularConfiguration";
import { fieldsStateActions } from "../thunks/fieldStatesThunks";
import {
  configurationToRows,
  configurationToColumns,
  configurationToValues,
  configurationToSettings as pivotConfigurationToSettings,
} from "../../components/builder/utils/configuration/pivotConfigurations";

export const setFieldStatesConfiguration = (
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  report: Report,
  dispatch: AppDispatch
) => {
  if (report.reportType === ReportType.Tabular) {
    setTabularConfiguration(dimensions, measures, report, undefined, dispatch);
  } else if (report.reportType === ReportType.Pivot) {
    setPivotReportConfiguration(dimensions, measures, report, undefined, dispatch);
  } else if (chartRelatedReportTypes.includes(report.reportType as ReportType)) {
    setChartReportConfiguration(dimensions, measures, report, undefined, dispatch);
  } else {
    throw new Error("Report type not supported");
  }
};

const setChartReportConfiguration = (
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  report: Report,
  currentReportConfiguration: ReportConfiguration | undefined,
  dispatch: AppDispatch
) => {
  const configuration = (currentReportConfiguration || report.configuration) as ChartConfiguration;
  if (!configuration) {
    return;
  }
  if (configuration.conditions) {
    const result = configurationToConditions(configuration.conditions, dimensions);
    dispatch(fieldsStateActions.setConditions(result));
  }
  if (configuration.arguments) {
    const result = configurationToArguments(configuration.arguments, dimensions);
    dispatch(fieldsStateActions.setArguments(result));
  }
  if (configuration.values) {
    const result = configurationToValues(configuration.values, measures, dimensions);
    dispatch(fieldsStateActions.setMeasures(result));
  }
  if (configuration.sort) {
    const result = configurationToSorts(configuration.sort, dimensions);
    dispatch(fieldsStateActions.setSorting({ fields: result, groupMetaNames: [] }));
  }
  if (configuration.settings) {
    dispatch(fieldsStateActions.setSettings({ settings: configuration.settings }));
  }
};

const setPivotReportConfiguration = (
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  report: Report,
  currentReportConfiguration: ReportConfiguration | undefined,
  dispatch: AppDispatch
) => {
  const configuration = (currentReportConfiguration || report.configuration) as PivotConfiguration;
  if (!configuration) {
    return;
  }
  if (configuration.conditions) {
    const result = configurationToConditions(configuration.conditions, dimensions);
    dispatch(fieldsStateActions.setConditions(result));
  }
  if (configuration.rows) {
    const result = configurationToRows(configuration.rows, dimensions);
    dispatch(fieldsStateActions.setRows(result));
  }
  if (configuration.columns) {
    const result = configurationToColumns(configuration.columns, dimensions);
    dispatch(fieldsStateActions.setColumns(result));
  }
  if (configuration.values) {
    const result = configurationToValues(configuration.values, measures, dimensions);
    dispatch(fieldsStateActions.setMeasures(result));
  }
  if (configuration.sort) {
    const result = configurationToSorts(configuration.sort, dimensions);
    dispatch(fieldsStateActions.setSorting({ fields: result, groupMetaNames: [] }));
  }
  if (configuration.settings) {
    const result = pivotConfigurationToSettings(configuration.settings);
    dispatch(fieldsStateActions.setSettings({ settings: result }));
  }
};

const setTabularConfiguration = (
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  report: Report,
  currentReportConfiguration: ReportConfiguration | undefined,
  dispatch: AppDispatch
) => {
  if (report.reportType !== ReportType.Tabular) {
    return;
  }
  const configuration = (currentReportConfiguration || report.configuration) as TabularConfiguration;
  if (!configuration) {
    return;
  }
  if (configuration.conditions) {
    const result = configurationToConditions(configuration.conditions, dimensions);
    dispatch(fieldsStateActions.setConditions(result));
  }

  if (configuration.fields) {
    const result = configurationToFields(configuration.fields, dimensions, measures);
    dispatch(fieldsStateActions.setFields(result));
  }

  if (configuration.sort) {
    const sorts = addMissingGroupSorts(
      configuration.fields,
      configuration.grouping,
      setGroupingFlag(configuration.fields ?? [], configuration.grouping ?? [], configuration.sort ?? [])
    );
    const result = configurationToSorts(sorts, dimensions);
    const groupMetaNames = configuration.grouping.map(
      (g) => configuration.fields.find((f) => f.guid === g.name)?.name ?? ""
    );
    dispatch(fieldsStateActions.setSorting({ fields: result, groupMetaNames }));
  }

  if (configuration.grouping) {
    const result = configurationToGrouping(configuration.grouping);
    dispatch(fieldsStateActions.setGrouping(result));
  }

  if (configuration.settings) {
    const result = tabularConfigurationToSettings(configuration.settings);
    dispatch(fieldsStateActions.setSettings({ settings: result }));
  }
};

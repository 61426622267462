import { useState, useCallback } from "react";
import { getStatusCodeFromError } from "../../shared/api/axiosHelper";
import { ssoApi } from "../../shared/api/sso";
import { useAuth } from "../../shared/auth/hooks";
import { redirectToLoginSignin } from "../../shared/auth/internal/redirects";
import { loadUser } from "../api/identityApi";
import { User } from "../api/identityApi.types";

export function useUserAuthentication() {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loadingUserError, setLoadingUserError] = useState(false);
  const [userHasNoPermissions, setUserHasNoPermissions] = useState(false);

  const onAuthenticated = useCallback(() => {
    if (user !== undefined) {
      return;
    }

    const load = async () => {
      try {
        const { data } = await loadUser();
        setUser(data);
      } catch (error) {
        if (getStatusCodeFromError(error) === 401) {
          await ssoApi.logout();
          setUserHasNoPermissions(true);
        } else {
          setLoadingUserError(true);
        }
      }
    };

    return load();
  }, [user, setLoadingUserError]);

  const isAuthenticated = useAuth(onAuthenticated, redirectToLoginSignin);

  return {
    isAuthenticated,
    user,
    loadingUserError,
    userHasNoPermissions,
  };
}

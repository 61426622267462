import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Ordering, AreaItemType } from "../../../shared/reporting/api/biClient.types";
import { TabularFieldState, TabularFieldsActionTypes } from "./types";
import { createTabularDefaultSettings } from "../../components/builder/utils/isConfigurationValid";
import { ConditionField, SortField } from "../../components/builder/Types";
import {
  updateLinkAndValidate,
  calculateAndAssignSystemLabel,
  removeConditionItem,
  removeConditionFromLinked,
  moveConditionItem,
  updateConditionItem,
  removeItem,
  moveItem,
  removeGroupItem,
  moveGroupItem,
  removeSortingItem,
  moveSortingItem,
  updateSortingItem,
  getGroupMetaNames,
  updateConditionConfig,
} from "../../components/builder/common/utilities/fieldsState";
import { updateTableField, updateTableFieldsOrder } from "../../components/builder/tabular/utilities/updateTableField";
import {
  getAggregationBasedFields,
  isMeasure,
  isDimensionBased,
  ensureDefaultFormatIsSet,
  isAggregationBased,
} from "../../components/builder/utils/fieldsHelper";
import { insertItemAt } from "../../utilities/Utilities";
import { sortSortingFields } from "../../components/builder/common/utilities/sortFieldStateHelper";
import { tabularFieldsStateThunks } from "../thunks/tabularFieldsStateThunks";

const initialState: TabularFieldState = {
  conditions: [],
  fields: [],
  grouping: [],
  settings: createTabularDefaultSettings(),
  sorts: [],
};

const tabularFieldsSlice = createSlice({
  name: "tabularFields",
  initialState: initialState,
  reducers: {
    resetToDefaultAction: () => initialState,
    // CONDITIONS
    setConditions: (state, action: TabularFieldsActionTypes["setConditions"]) => {
      state.conditions = action.payload;
    },
    addCondition: (state, action: TabularFieldsActionTypes["addCondition"]) => {
      const condition: ConditionField = { ...action.payload.field };
      calculateAndAssignSystemLabel(condition, state.conditions);
      const conditions = insertItemAt(state.conditions, condition, action.payload.index);
      state.conditions = conditions;
    },
    removeCondition: (state, action: TabularFieldsActionTypes["removeCondition"]) => {
      const conditions = removeConditionItem(action.payload, state.conditions);
      removeConditionFromLinked(action.payload.config.guid, getAggregationBasedFields(state.fields));
      state.conditions = conditions;
    },
    moveCondition: (state, action: TabularFieldsActionTypes["moveCondition"]) => {
      state.conditions = moveConditionItem(action.payload.field, action.payload.newIndex, state.conditions);
    },
    updateCondition: (state, action: TabularFieldsActionTypes["updateCondition"]) => {
      state.conditions = updateConditionItem(action.payload.field, action.payload.changes, state.conditions);
    },
    updateConditionConfig: (state, action: TabularFieldsActionTypes["updateConditionConfig"]) => {
      const condition = state.conditions.find((c) => c.config.guid === action.payload.field.config.guid);
      if (condition) {
        condition.config = updateConditionConfig(condition.config, action.payload.changes);
      }
    },
    // FIELDS
    setFields: (state, action: TabularFieldsActionTypes["setFields"]) => {
      state.fields = action.payload;
    },
    addField: (state, action: TabularFieldsActionTypes["addField"]) => {
      state.fields = insertItemAt(state.fields, ensureDefaultFormatIsSet(action.payload.field), action.payload.index);
    },
    removeField: (state, action: TabularFieldsActionTypes["removeField"]) => {
      const item = state.sorts.find((s) => s.meta.name === action.payload.meta.name);
      if (item) {
        state.sorts = removeSortingItem(item, state.sorts);
      }
      if (isDimensionBased(action.payload)) {
        state.grouping = removeGroupItem({ name: action.payload.config.guid }, state.grouping);
      }
      const fields = removeItem(action.payload, state.fields);
      state.fields = fields;
      if (isMeasure(action.payload)) {
        state.conditions = updateLinkAndValidate(state.conditions, getAggregationBasedFields(fields));
      }
    },
    moveField: (state, action: TabularFieldsActionTypes["moveField"]) => {
      state.fields = moveItem(action.payload.field, action.payload.newIndex, state.fields);
    },
    updateFieldConfig: (state, action: TabularFieldsActionTypes["updateFieldConfig"]) => {
      const field = state.fields.find((f) => f.config.guid === action.payload.field.config.guid);
      if (field !== undefined) {
        field.config = { ...field.config, ...action.payload.changes };
        if (isAggregationBased(field)) {
          state.conditions = updateLinkAndValidate(state.conditions, getAggregationBasedFields(state.fields));
        }
      }
    },
    updateMeasureField: (state, action: TabularFieldsActionTypes["updateMeasureField"]) => {
      state.fields = updateTableField(action.payload.field, action.payload.changes, state.fields);
    },
    updateFieldsOrder: (state, action: TabularFieldsActionTypes["updateFieldsOrder"]) => {
      state.fields = updateTableFieldsOrder(state.fields, action.payload);
    },
    // GROUPING
    setGrouping: (state, action: TabularFieldsActionTypes["setGrouping"]) => {
      const validatedGroups = action.payload.filter((group) => {
        const field = state.fields.find((f) => f.config.guid === group.name);
        return field !== undefined;
      });
      state.grouping = validatedGroups;
    },
    updateGroup: (state, action: TabularFieldsActionTypes["updateGroup"]) => {
      const group = state.grouping.find((g) => g.name === action.payload.group.name);
      if (group !== undefined) {
        state.grouping[state.grouping.indexOf(group)] = { ...group, ...action.payload.changes };
      }
    },
    addGroup: (state, action: TabularFieldsActionTypes["addGroup"]) => {
      state.grouping = [...state.grouping, action.payload];
    },
    removeGroup: (state, action: TabularFieldsActionTypes["removeGroup"]) => {
      state.grouping = removeGroupItem(action.payload, state.grouping);
    },
    moveGroup: (state, action: TabularFieldsActionTypes["moveGroup"]) => {
      state.grouping = moveGroupItem(action.payload.field, action.payload.newIndex, state.grouping);
      const groupMetaNames = getGroupMetaNames(state.grouping, state.fields);
      const groupedFields = state.sorts.filter((field) => field.config.isGroupField);
      const regularFields = state.sorts.filter((field) => !field.config.isGroupField);
      groupedFields.sort(
        (a, b) =>
          groupMetaNames.findIndex((g) => g === a.meta.name) - groupMetaNames.findIndex((g) => g === b.meta.name)
      );
      state.sorts = [...groupedFields, ...regularFields];
    },
    // SORTING
    setSorting: (state, action: TabularFieldsActionTypes["setSorting"]) => {
      const { fields, groupMetaNames } = action.payload;
      state.sorts = sortSortingFields(fields, groupMetaNames);
    },
    addSorting: (state, action: TabularFieldsActionTypes["addSorting"]) => {
      const { meta, config } = action.payload;
      const conditionField: SortField = {
        meta: meta,
        config: {
          name: meta.name,
          ordering: Ordering.Ascending,
          caption: config.customLabel || meta.caption,
          isGroupField: false,
        },
        areaItemType: AreaItemType.SORTS,
      };
      state.sorts = sortSortingFields([...state.sorts, conditionField], []);
    },
    addGroupSorting: (state, action: TabularFieldsActionTypes["addGroupSorting"]) => {
      const { field, groupMetaNames } = action.payload;
      state.sorts = sortSortingFields([...state.sorts, field], groupMetaNames);
    },
    removeSorting: (state, action: TabularFieldsActionTypes["removeSorting"]) => {
      state.sorts = removeSortingItem(action.payload, state.sorts);
    },
    removeSortingByMeta: (state, action: TabularFieldsActionTypes["removeSortingByMeta"]) => {
      const item = state.sorts.find((s) => s.meta.name === action.payload.name);
      if (item === undefined) return;
      state.sorts = removeSortingItem(item, state.sorts);
    },
    moveSorting: (state, action: TabularFieldsActionTypes["moveSorting"]) => {
      state.sorts = moveSortingItem(action.payload.field, action.payload.newIndex, state.sorts);
    },
    updateSorting: (state, action: TabularFieldsActionTypes["updateSorting"]) => {
      state.sorts = updateSortingItem(action.payload.field, action.payload.changes, state.sorts);
    },
    updateSortingConfig: (state, action: TabularFieldsActionTypes["updateSortingConfig"]) => {
      const sortField = state.sorts.find((s) => s.meta.name === action.payload.field.meta.name);
      if (sortField) {
        sortField.config = { ...sortField.config, ...action.payload.changes };
      }
    },
    //SETTINGS
    updateSettings: (state, action: TabularFieldsActionTypes["updateSettings"]) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setSettings: (state, action: TabularFieldsActionTypes["setSettings"]) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        tabularFieldsStateThunks.setFields.fulfilled,
        tabularFieldsStateThunks.updateMeasureField.fulfilled,
        tabularFieldsSlice.actions.setConditions,
        tabularFieldsSlice.actions.addCondition,
        tabularFieldsSlice.actions.removeCondition,
        tabularFieldsSlice.actions.moveCondition,
        tabularFieldsSlice.actions.updateMeasureField
      ),
      (state) => {
        state.conditions = updateLinkAndValidate(state.conditions, getAggregationBasedFields(state.fields));
      }
    );
  },
});

/**
 * @deprecated Use thunks instead
 */
export const tabularFieldsActions = tabularFieldsSlice.actions;
export const tabularFieldsReducer = tabularFieldsSlice.reducer;

import React from "react";
import { FieldConfiguration, ReportField } from "../../../../shared/reporting/api/biClient.types";
import PreviewActions from "../common/PreviewActions";
import { isValueFieldHasParameters } from "../common/utilities/valueFieldUtils";
import ViewContainer from "../common/ViewContainer";
import ViewerParameters from "../common/ViewerParameters";
import { isMeasure } from "../utils/fieldsHelper";
import useGridStateViewer from "./hooks/useGridStateViewer";
import withExport from "./preview/hocs/withExport";
import PreviewComponent from "./preview/PreviewComponent";
import { useSelector } from "react-redux";
import { selectTabularFields, selectConditions, useAppDispatch } from "../../../store/store";
import { fieldsStateActions } from "../../../store/thunks/fieldStatesThunks";

export default function TabularViewer() {
  const state = useGridStateViewer();
  const conditionsAreaValues = useSelector(selectConditions);
  const fieldsAreaValues = useSelector(selectTabularFields);
  const dispatch = useAppDispatch();

  const fields = React.useMemo(
    () => conditionsAreaValues.filter((v) => v.config.parameter === true),
    [conditionsAreaValues]
  );

  const measuresToShow = React.useMemo(() => {
    return fieldsAreaValues.filter(isMeasure).filter(isValueFieldHasParameters);
  }, [fieldsAreaValues]);

  const showParameters = React.useMemo(() => {
    return fields.length > 0 || measuresToShow.length > 0;
  }, [fields, measuresToShow]);

  const updateMeasure = React.useCallback(
    (field: ReportField, change: Partial<FieldConfiguration>) => {
      const tableField = fieldsAreaValues.find((v) => v.config.guid === field.config.guid);
      if (tableField !== undefined) {
        dispatch(fieldsStateActions.updateFieldConfig({ field: tableField, changes: change }));
      }
    },
    [fieldsAreaValues, dispatch]
  );

  return (
    <ViewContainer
      parameters={
        showParameters ? (
          <ViewerParameters measuresWithConditions={measuresToShow} updateMeasure={updateMeasure} />
        ) : (
          <></>
        )
      }
      preview={<PreviewComponentWithExport state={state} />}
      actionPanel={<PreviewActions />}
    ></ViewContainer>
  );
}

const PreviewComponentWithExport = withExport(PreviewComponent);

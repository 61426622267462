import { Outlet } from "react-router";
import GlobalNotificationErrorSnackbar from "../../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../../shared/components/GlobalNotificationSnackbar";
import { NotificationContextProvider } from "../../shared/contexts/NotificationContext";
import sharedBiClient from "../../shared/reporting/api/biClient";
import { BiApiClientProvider } from "../../shared/reporting/contexts/BiApiClientProviderContext";
import biClient from "../api/biApi";
import { BiApiClient } from "../contexts/ApiClientProviderContext";
import { getEditReportUrl, getUiReportsUrl, getViewReportUrl } from "./builder/common/utilities/editReportUrl";

export default function TemplatesContainer() {
  return (
    <BiApiClientProvider<BiApiClient>
      api={{
        ...biClient.uiReports,
        ...sharedBiClient.uiReports,
        getEditReportUrl,
        getViewReportUrl,
        getReportsUrl: getUiReportsUrl,
      }}
    >
      <NotificationContextProvider>
        <Outlet />
        <GlobalNotificationSnackbar />
        <GlobalNotificationErrorSnackbar />
      </NotificationContextProvider>
    </BiApiClientProvider>
  );
}

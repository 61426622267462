import { Grid2, Stack } from "@mui/material";
import React from "react";
import ArgumentAxisStyles from "./argumentAxis/ArgumentAxisStyles";
import ChartStyles from "./chart/ChartStyles";
import LegendStyles from "./legend/LegendStyles";
import SeriesStyles from "./serie/SeriesStyles";
import ValueAxisStyles from "./valueAxis/ValueAxisStyles";
import { useSelector } from "react-redux";
import { selectArguments, selectValues } from "../../../../store/store";

export default function StyleContainer() {
  const argumentsAreaValues = useSelector(selectArguments);
  const valuesAreaValues = useSelector(selectValues);

  const configurationValid = React.useMemo(() => {
    return argumentsAreaValues.length > 0 && valuesAreaValues.length > 0;
  }, [argumentsAreaValues, valuesAreaValues]);

  if (!configurationValid) {
    return <></>;
  }

  return (
    <Grid2 container sx={{ mt: -2, flexDirection: "column", flex: 1, position: "relative", width: "100%" }}>
      <Stack sx={{ position: "absolute", height: "100%", width: "100%", overflow: "auto" }}>
        <ChartStyles />
        <SeriesStyles />
        <ArgumentAxisStyles />
        <ValueAxisStyles />
        <LegendStyles />
      </Stack>
    </Grid2>
  );
}

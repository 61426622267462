import { Select, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import CubeIcon from "../../../../../shared/icons/CubeIcon";
import { DataSetInfo } from "../../../../api/biApi.types";
import { selectDataSetSettings } from "../../../../store/clientSettingsSlice";
import { selectCurrentDataSet } from "../../../../store/metaDataSlice";
import { changeDataSet } from "../../../../store/thunks/metaDataThunks";
import { useAppDispatch } from "../../../../store/store";

export default function DataSetSelector() {
  const dispatch = useAppDispatch();
  const dataSets = useSelector(selectDataSetSettings);
  const selectedDataSet = useSelector(selectCurrentDataSet);

  const handleChange = useCallback(
    (id: string) => {
      const ds = dataSets.find((ds) => ds.id === id);
      if (ds?.id) {
        dispatch(changeDataSet(ds.id));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSets]
  );

  if (!selectedDataSet) {
    return null;
  }

  return (
    <Select
      fullWidth
      value={selectedDataSet}
      sx={{ ".MuiSelect-select": { py: 1 } }}
      onChange={(evt) => handleChange(evt.target.value)}
    >
      {dataSets.map((value) => (
        <MenuItemStyled key={value.id} value={value.id}>
          <DataSetItem value={value} />
        </MenuItemStyled>
      ))}
    </Select>
  );
}

function DataSetItem({ value }: { value: DataSetInfo }) {
  return (
    <Stack sx={{ flex: 1, flexDirection: "row", alignItems: "center", gap: 1.5 }}>
      <CubeIcon sx={(theme) => ({ color: theme.palette.action.active })} />
      <Typography variant="body1">{value.name}</Typography>
    </Stack>
  );
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConditionConfiguration, FieldConfiguration, ReportField } from "../../../shared/reporting/api/biClient.types";
import { ConditionField } from "../../components/builder/Types.ts";
import {
  calculateAndAssignSystemLabel,
  moveConditionItem,
  moveMeasureItem,
  removeConditionFromLinked,
  removeConditionItem,
  removeMeasureItem,
  updateConditionConfig,
  updateConditionItem,
  updateLinkAndValidate,
  updateMeasureItem,
} from "../../components/builder/common/utilities/fieldsState.ts";
import { insertItemAt } from "../../utilities/Utilities.tsx";

export type FieldsState = {
  conditions: ConditionField[];
  values: ReportField[];
};

const initialState: FieldsState = { conditions: [], values: [] };

const fieldsStateSlice = createSlice({
  name: "fieldsState",
  initialState,
  reducers: {
    resetToDefaultAction: () => initialState,
    setConditionsAction: (state, action: PayloadAction<ConditionField[]>) => {
      state.conditions = action.payload;
      state.conditions = updateLinkAndValidate(state.conditions, state.values);
    },
    addConditionAction: (state, action: PayloadAction<{ field: ConditionField; index: number }>) => {
      const condition: ConditionField = { ...action.payload.field };
      calculateAndAssignSystemLabel(condition, state.conditions);
      const conditions = insertItemAt(state.conditions, condition, action.payload.index);
      state.conditions = updateLinkAndValidate(conditions, state.values);
    },
    removeConditionAction: (state, action: PayloadAction<ConditionField>) => {
      const conditions = removeConditionItem(action.payload, state.conditions);
      const measures = removeConditionFromLinked(action.payload.config.guid, [...state.values]);
      state.conditions = updateLinkAndValidate(conditions, measures);
      state.values = measures;
    },
    moveConditionAction: (state, action: PayloadAction<{ field: ConditionField; newIndex: number }>) => {
      state.conditions = moveConditionItem(action.payload.field, action.payload.newIndex, state.conditions);
      state.conditions = updateLinkAndValidate(state.conditions, state.values);
    },
    updateConditionAction: (
      state,
      action: PayloadAction<{ field: ConditionField; changes: Partial<ConditionField> }>
    ) => {
      state.conditions = updateConditionItem(action.payload.field, action.payload.changes, state.conditions);
    },
    updateConditionConfigAction: (
      state,
      action: PayloadAction<{ field: ConditionField; changes: Partial<ConditionConfiguration> }>
    ) => {
      const condition = state.conditions.find((c) => c.config.guid === action.payload.field.config.guid);
      if (condition) {
        condition.config = updateConditionConfig(condition.config, action.payload.changes);
      }
    },
    setMeasuresAction: (state, action: PayloadAction<ReportField[]>) => {
      state.values = action.payload;
      state.conditions = updateLinkAndValidate(state.conditions, action.payload);
    },
    addMeasureAction: (state, action: PayloadAction<{ field: ReportField; index: number }>) => {
      state.values = insertItemAt(state.values, action.payload.field, action.payload.index);
    },
    removeMeasureAction: (state, action: PayloadAction<ReportField>) => {
      state.values = removeMeasureItem(action.payload, state.values);
      state.conditions = updateLinkAndValidate(state.conditions, state.values);
    },
    moveMeasureAction: (state, action: PayloadAction<{ field: ReportField; newIndex: number }>) => {
      state.values = moveMeasureItem(action.payload.field, action.payload.newIndex, state.values);
    },
    updateMeasureAction: (state, action: PayloadAction<{ field: ReportField; changes: Partial<ReportField> }>) => {
      state.values = updateMeasureItem(action.payload.field, action.payload.changes, state.values);
      state.conditions = updateLinkAndValidate(state.conditions, state.values);
    },
    updateMeasureConfigAction: (
      state,
      action: PayloadAction<{ field: ReportField; changes: Partial<FieldConfiguration> }>
    ) => {
      const measure = state.values.find((c) => c.config.guid === action.payload.field.config.guid);
      if (measure) {
        const index = state.values.indexOf(measure);
        measure.config = <FieldConfiguration>{ ...measure.config, ...action.payload.changes };
        state.values[index] = measure;
        state.conditions = updateLinkAndValidate(state.conditions, state.values);
      }
    },
  },
});

/**
 * @deprecated Use thunks instead
 */
export const conditionsActions = fieldsStateSlice.actions;
export const conditionsReducer = fieldsStateSlice.reducer;

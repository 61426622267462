import React from "react";
import TitleItem from "../TitleItem";
import { useSelector } from "react-redux";
import { selectChartSettingsState, useAppDispatch } from "../../../../../store/store";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";

export default function Title() {
  const settings = useSelector(selectChartSettingsState);
  const title = React.useMemo(() => settings.legend?.title || "", [settings]);
  const dispatch = useAppDispatch();

  return (
    <TitleItem
      title={title}
      placeholder="Set Legend Title..."
      onUpdate={(title) => dispatch(fieldsStateActions.updateLegendSettings({ title }))}
    />
  );
}

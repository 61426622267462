import { RootState } from "../RootState.ts";
import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch } from "../store.ts";

export function createTypedAsyncThunk<ReturnType, ArgType = void>(
  typePrefix: string,
  payloadCreator: (
    arg: ArgType,
    thunkAPI: { dispatch: AppDispatch; getState: () => RootState }
  ) => Promise<ReturnType> | ReturnType
): AsyncThunk<ReturnType, ArgType, { state: RootState; dispatch: AppDispatch }> {
  return createAsyncThunk<ReturnType, ArgType, { state: RootState; dispatch: AppDispatch }>(
    typePrefix,
    (arg, thunkAPI) => payloadCreator(arg, { ...thunkAPI })
  );
}

import { Box, Divider, Grid2, Tab, Tabs } from "@mui/material";
import { useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { getAvailableSortFields } from "../../common/utilities/sortFieldStateHelper";
import SettingsContainer from "../../tabular/settings/SettingsContainer";
import FormatContainer from "../../tabular/table/FormatContainer";
import TableDragLayer from "../../tabular/table/TableDragLayer";
import { useSelector } from "react-redux";
import { selectSorts, selectTabularFields } from "../../../../store/store.ts";
import ConditionsDropFieldsContainer from "../../pivot/table/conditions/ConditionsDropFieldsContainer.tsx";
import FieldsDropContainer from "../../tabular/table/FieldsDropContainer.tsx";
import SortDropsFieldsContainer from "../../tabular/table/SortDropsFieldsContainer.tsx";

const TableComponent = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const sortingAreaValues = useSelector(selectSorts);
  const fieldsAreaValues = useSelector(selectTabularFields);

  const availableSortFields = useMemo(
    () => getAvailableSortFields(fieldsAreaValues, sortingAreaValues),
    [fieldsAreaValues, sortingAreaValues]
  );

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid2 container px="1.3rem">
        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value: number) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid2>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer disableEqualitySelection={true} />
          <FieldsDropContainer />
          <SortDropsFieldsContainer availableFields={availableSortFields} />
          <TableDragLayer />
        </ScrollableFlexContainer>
      )}
      {selectedTab === 1 && <SettingsContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Box>
  );
};

export default TableComponent;

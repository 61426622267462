import { Outlet } from "react-router";
import GlobalNotificationErrorSnackbar from "../../../../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../../../../shared/components/GlobalNotificationSnackbar";
import { NotificationContextProvider } from "../../../../shared/contexts/NotificationContext";
import sharedBiClient from "../../../../shared/reporting/api/biClient";
import { BiApiClientProvider } from "../../../../shared/reporting/contexts/BiApiClientProviderContext";
import biClient from "../../../api/biApi";
import { BiApiClient } from "../../../contexts/ApiClientProviderContext";
import { getApiReportsUrl, getEditApiReportUrl, getViewApiReportUrl } from "../common/utilities/editReportUrl";

const ReportsContainer = () => {
  return (
    <BiApiClientProvider<BiApiClient>
      api={{
        ...biClient.apiReports,
        ...sharedBiClient.apiReports,
        getEditReportUrl: getEditApiReportUrl,
        getViewReportUrl: getViewApiReportUrl,
        getReportsUrl: getApiReportsUrl,
      }}
    >
      <NotificationContextProvider>
        <Outlet />
        <GlobalNotificationSnackbar />
        <GlobalNotificationErrorSnackbar />
      </NotificationContextProvider>
    </BiApiClientProvider>
  );
};

export default ReportsContainer;

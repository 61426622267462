import { IconButton, Stack, SvgIcon, SvgIconProps, Typography } from "@mui/material";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { getLinearSettings, isLinearSettings } from "../../contexts/FieldsStateContext.types";
import { ChartStyleSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { fieldsStateActions } from "../../../../../store/thunks/fieldStatesThunks";
import { useAppDispatch } from "../../../../../store/store";

interface Props {
  settings: ChartStyleSettings;
}

export default function GridModeItem({ settings }: Props) {
  const supported = isLinearSettings(settings);
  const gridMode = getGridMode(settings);
  const dispatch = useAppDispatch();

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Show Grid</Typography>
      <HorizontalFill />
      <IconButton onClick={() => dispatch(fieldsStateActions.updateSettings({ settings: { gridMode: "none" } }))}>
        <HideGridIcon color={gridMode === "none" ? "primary" : "secondary"} />
      </IconButton>
      <IconButton onClick={() => dispatch(fieldsStateActions.updateSettings({ settings: { gridMode: "horizontal" } }))}>
        <HorizontalGridIcon color={gridMode === "horizontal" ? "primary" : "secondary"} />
      </IconButton>
      <IconButton onClick={() => dispatch(fieldsStateActions.updateSettings({ settings: { gridMode: "all" } }))}>
        <AllGridIcon color={gridMode === "all" ? "primary" : "secondary"} />
      </IconButton>
    </Stack>
  );
}

function HideGridIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M2.5 17.5V15.8333H4.16667V17.5H2.5ZM2.5 14.1667V12.5H4.16667V14.1667H2.5ZM2.5 10.8333V9.16667H4.16667V10.8333H2.5ZM2.5 7.5V5.83333H4.16667V7.5H2.5ZM2.5 4.16667V2.5H4.16667V4.16667H2.5ZM5.83333 17.5V15.8333H7.5V17.5H5.83333ZM5.83333 10.8333V9.16667H7.5V10.8333H5.83333ZM5.83333 4.16667V2.5H7.5V4.16667H5.83333ZM9.16667 17.5V15.8333H10.8333V17.5H9.16667ZM9.16667 14.1667V12.5H10.8333V14.1667H9.16667ZM9.16667 10.8333V9.16667H10.8333V10.8333H9.16667ZM9.16667 7.5V5.83333H10.8333V7.5H9.16667ZM9.16667 4.16667V2.5H10.8333V4.16667H9.16667ZM12.5 17.5V15.8333H14.1667V17.5H12.5ZM12.5 10.8333V9.16667H14.1667V10.8333H12.5ZM12.5 4.16667V2.5H14.1667V4.16667H12.5ZM15.8333 17.5V15.8333H17.5V17.5H15.8333ZM15.8333 14.1667V12.5H17.5V14.1667H15.8333ZM15.8333 10.8333V9.16667H17.5V10.8333H15.8333ZM15.8333 7.5V5.83333H17.5V7.5H15.8333ZM15.8333 4.16667V2.5H17.5V4.16667H15.8333Z" />
    </SvgIcon>
  );
}

function HorizontalGridIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74 3.92667C2.89944 4.08667 3.09722 4.16667 3.33333 4.16667H16.6667C16.9028 4.16667 17.1006 4.08667 17.26 3.92667C17.42 3.76722 17.5 3.56944 17.5 3.33333C17.5 3.09722 17.42 2.89944 17.26 2.74C17.1006 2.58 16.9028 2.5 16.6667 2.5H3.33333C3.09722 2.5 2.89944 2.58 2.74 2.74C2.58 2.89944 2.5 3.09722 2.5 3.33333C2.5 3.56944 2.58 3.76722 2.74 3.92667ZM3.33333 10.8333C3.09722 10.8333 2.89944 10.7533 2.74 10.5933C2.58 10.4339 2.5 10.2361 2.5 10C2.5 9.76389 2.58 9.56611 2.74 9.40667C2.89944 9.24667 3.09722 9.16667 3.33333 9.16667H16.6667C16.9028 9.16667 17.1006 9.24667 17.26 9.40667C17.42 9.56611 17.5 9.76389 17.5 10C17.5 10.2361 17.42 10.4339 17.26 10.5933C17.1006 10.7533 16.9028 10.8333 16.6667 10.8333H3.33333ZM3.33333 17.5C3.09722 17.5 2.89944 17.42 2.74 17.26C2.58 17.1006 2.5 16.9028 2.5 16.6667C2.5 16.4306 2.58 16.2328 2.74 16.0733C2.89944 15.9133 3.09722 15.8333 3.33333 15.8333H16.6667C16.9028 15.8333 17.1006 15.9133 17.26 16.0733C17.42 16.2328 17.5 16.4306 17.5 16.6667C17.5 16.9028 17.42 17.1006 17.26 17.26C17.1006 17.42 16.9028 17.5 16.6667 17.5H3.33333Z"
      />
    </SvgIcon>
  );
}

function AllGridIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20 }} viewBox="0 0 20 20" {...props}>
      <path d="M2.5 15.8333V4.16667C2.5 3.70833 2.66306 3.31583 2.98917 2.98917C3.31583 2.66306 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.6842 2.66306 17.0108 2.98917C17.3369 3.31583 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3369 16.6842 17.0108 17.0108C16.6842 17.3369 16.2917 17.5 15.8333 17.5H4.16667C3.70833 17.5 3.31583 17.3369 2.98917 17.0108C2.66306 16.6842 2.5 16.2917 2.5 15.8333ZM10.8333 10.8333V15.8333H15.8333V10.8333H10.8333ZM10.8333 9.16667H15.8333V4.16667H10.8333V9.16667ZM9.16667 9.16667V4.16667H4.16667V9.16667H9.16667ZM9.16667 10.8333H4.16667V15.8333H9.16667V10.8333Z" />
    </SvgIcon>
  );
}

export function getGridMode(settings: ChartStyleSettings) {
  return getLinearSettings(settings)?.gridMode || "horizontal";
}
